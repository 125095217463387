import React, { ReactNode } from "react";
import { useDispatch } from "react-redux";
import { Dropdown, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { useAuth } from "modules/auth/hooks";

import { settingsActions } from "../../../../../../store/reducers/settingsReducer";
import DeleteIcon from "../../../../../app/assets/icons/DeleteIcon";
import EditIcon from "../../../../../app/assets/icons/EditIcon";
import { LoadingIndicator } from "../../../../../app/components/loading-indicator/LoadingIndicator";
import ModalConfirm from "../../../../../app/components/modal-confirm/ModalConfirm";
import NotUserImage from "../../../../../app/components/not-image/NotUserImage";
import Pagination from "../../../../../app/components/pagination/Pagination";
import TableEmpty from "../../../../../app/components/table-empty/TableEmpty";
import UnreadMessageDots from "../../../../../app/components/unread-message/UnreadMessageDots";
import { Status, StatusNames } from "../../../../../app/utils/models/status";
import EyeIcon from "../../../../../projects/assets/icons/EyeIcon";
import { useDeleteAdmin } from "../../../../service/mutations";
import { useGetEmployees } from "../../../../service/queries";
import { SUPER_ADMIN_ROLE } from "../../../../utils/constants/defaultRoles";
import { AdminModel } from "../../../../utils/models/admin/adminModel";
import { RoleModel } from "../../../../utils/models/roles/roleModel";

import styles from "../settingsAdmins.module.scss";

const SettingsAdminsTable: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setAdminModal } = settingsActions;
  const { data, isLoading } = useGetEmployees();
  const deleteAdmin = useDeleteAdmin();
  const { user } = useAuth();

  // delete admin
  const onDeleteAdmin = (id: number) => deleteAdmin.mutateAsync(id);

  // open modal
  const onOpenModal = (record: AdminModel, isEdited: boolean) => () => {
    dispatch(
      setAdminModal({
        data: record,
        visible: true,
        isEdited
      })
    );
  };

  const menuItems = (record: AdminModel) => {
    const items = [
      {
        key: "1",
        label: (
          <div className="d_f ai_c cg_8">
            <EyeIcon />
            <span>{t("settings.Ko'rish")}</span>
          </div>
        ),
        onClick: onOpenModal(record, false),
        isView: true
      },
      {
        key: "2",
        label: (
          <div className="d_f ai_c cg_8">
            <EditIcon /> <span>{t("settings.Tahrirlash")}</span>
          </div>
        ),
        onClick: onOpenModal(record, true),
        isView: record?.role?.name !== SUPER_ADMIN_ROLE || record?.id === user?.id
      },
      {
        key: "3",
        label: (
          <ModalConfirm onOk={() => onDeleteAdmin(record.id)} title={t("Xodimlar.Xodimni o'chirmoqchimisiz ?")}>
            <div className="d_f ai_c jc_c cg_8">
              <DeleteIcon />
              <span>{t("settings.O'chirish")}</span>
            </div>
          </ModalConfirm>
        ),
        isView: record?.role?.name !== SUPER_ADMIN_ROLE
      }
    ];

    return items?.filter(el => el?.isView);
  };

  const dropdownRender = (originNode: ReactNode) => (
    <div
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {originNode}
    </div>
  );

  // table columns
  const columns: ColumnsType<AdminModel> = [
    {
      title: `${t("Xodimlar.To'liq ism")}`,
      render: (record: AdminModel) => (
        <div className={styles.person}>
          {record?.image ? <img src={record.image} alt="" /> : <NotUserImage name={record?.full_name} width={40} />}
          <p>
            <span className={styles.person__name}>{record.full_name}</span>
            <span className={styles.person__phone}>{record.phone}</span>
          </p>
        </div>
      )
    },
    {
      title: `${t("Xodimlar.Lavozim")}`,
      dataIndex: "role",
      render: (value: RoleModel) => value.name
    },
    {
      title: `${t("settings.Login")}`,
      dataIndex: "login"
    },
    {
      title: `${t("Xodimlar.Holati")}`,
      dataIndex: "status",
      render: (value: Status) => (
        <span
          className={classNames({
            status__active: value === Status.ACTIVE,
            status__passive: value === Status.PASSIVE
          })}
        >
          {StatusNames[value]}
        </span>
      )
    },
    {
      title: "",
      render: (record: AdminModel) => (
        <Dropdown
          trigger={["click"]}
          placement="bottomLeft"
          dropdownRender={dropdownRender}
          menu={{ items: menuItems(record) }}
        >
          <div className="d_f ai_c c_p jc_fe" onClick={e => e.stopPropagation()}>
            <UnreadMessageDots />
          </div>
        </Dropdown>
      )
    }
  ];

  return (
    <div className={styles.settings_admins__content}>
      <Table<AdminModel>
        dataSource={data?.data.data}
        columns={columns}
        rowKey={e => e.id}
        locale={{
          emptyText: <TableEmpty />
        }}
        loading={{
          spinning: isLoading || deleteAdmin.isLoading,
          indicator: LoadingIndicator
        }}
        pagination={false}
        className={styles.settings_admins__table}
        onRow={record => ({
          onClick: onOpenModal(record, false),
          className: "c_p"
        })}
      />
      <Pagination
        paginationProps={{
          total: data?.data?.total
        }}
      />
    </div>
  );
};

export default SettingsAdminsTable;
