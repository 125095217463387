import React from "react";
import { Tooltip } from "antd";

import styles from "./custom-tooltip.module.scss";
import { cx } from "modules/common";

type Props = {
  children?: React.ReactNode;
  content?: React.ReactNode;
  isToolTip?: boolean;
  trigger?: "click" | "contextMenu" | "hover" | "focus";
  tooltip_container_className?: string;
};

const CustomTooltip: React.FC<Props> = ({ children, content, isToolTip, trigger, tooltip_container_className }) => {
  if (!isToolTip) return <div className={cx(tooltip_container_className ?? styles.tooltip_container)}>{children}</div>;

  return (
    <Tooltip
      arrow={false}
      placement="top"
      trigger={[trigger ?? "hover"]}
      rootClassName={styles.tooltip}
      title={<div className={cx(tooltip_container_className ?? styles.tooltip_container)}>{content}</div>}
    >
      {children}
    </Tooltip>
  );
};

export default CustomTooltip;
