import React, { useMemo } from "react";
import { Progress } from "antd";
import { isEmptyArr } from "features/app/utils/helpers/isEmptyArr";
import { useTranslation } from "react-i18next";

import { RU, TooltipShortName } from "modules/common";
import { CompanyWidget, Histogram as HistogramType, useWidget } from "modules/dashboard";

import { AmountFixer } from "components";

import { WidgetCustomTooltip } from "../../tooltip";

import styles from "./histogram.module.scss";

interface Props {
  item: CompanyWidget;
}

const Histogram: React.FC<Props> = ({ item }) => {
  const { setWidgetDrawer } = useWidget().methods;
  const { i18n } = useTranslation();

  const calculateMaxTotalAmount = (data: HistogramType[]) =>
    data.reduce((maxTotalAmount, item) => Math.max(maxTotalAmount, item.total), 0);

  const maxTotalAmount = useMemo(() => calculateMaxTotalAmount(item.data), [item.data]);

  const stopPropagation = (e: React.MouseEvent<SVGRectElement | HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const onOpenViewDrawer = ({
    e,
    company_person_id,
    company_person_name
  }: {
    e: React.MouseEvent<HTMLDivElement, MouseEvent>;
    company_person_id: number;
    company_person_name: string;
  }) => {
    stopPropagation(e);
    setWidgetDrawer({
      id: item.id,
      visible: true,
      key: item.key,
      props: { company_person_id },
      title: (
        <div className="flex gap-1">
          <span className="text-lg font-semibold text-gray-900">{item.name[i18n.language]}</span>
          <span className="text-lg font-semibold text-gray-400">
            (<TooltipShortName title={company_person_name} length={25} />)
          </span>
        </div>
      ),
      widget: item
    });
  };

  const customToolTip = (name: string, amount: number, currency: string) => (
    <div className="flex h-10 items-center justify-center gap-2">
      <div className="flex flex-col gap-1 border-b-0 border-l-4 border-r-0 border-t-0 border-solid border-success-500 pl-2 text-white">
        <span className="text-xs font-medium">{name}</span>
        <span className="text-base font-semibold">
          {amount.toLocaleString(RU)} {currency}
        </span>
      </div>
    </div>
  );

  return (
    <div
      className="flex h-[85%] w-full flex-col gap-4 overflow-y-auto"
      onMouseDown={stopPropagation}
      onTouchStart={stopPropagation as never}
    >
      {!isEmptyArr(item.data) &&
        item?.data?.map((histogram: HistogramType) => (
          <WidgetCustomTooltip
            key={histogram.id}
            content={customToolTip(histogram.name, histogram.total, histogram.currency!.symbol)}
            isToolTip={true}
            children={
              <div
                className="flex w-full cursor-pointer flex-col items-center justify-center gap-1"
                onClick={e =>
                  onOpenViewDrawer({ e, company_person_id: histogram.id, company_person_name: histogram.name })
                }
                key={histogram.id}
              >
                <div className="flex w-full items-center justify-between">
                  <span className="text-sm font-semibold text-gray-700">{histogram.name}</span>
                  <span className="flex items-center justify-center gap-1 text-sm font-semibold text-gray-400">
                    <AmountFixer children={histogram.total} /> {histogram?.currency?.symbol}
                  </span>
                </div>
                <div className="w-full">
                  <Progress
                    showInfo={false}
                    className={styles.progress}
                    percent={(histogram.total / maxTotalAmount) * 100}
                  />
                </div>
              </div>
            }
          />
        ))}
    </div>
  );
};

export default Histogram;
