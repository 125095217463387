import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { partyReducerActions } from "store/reducers/partyReducer";

import { notificationTypesTitleValue } from "../../../../utils/constants/notificationTypes";
import { NotificationModel } from "../../../../utils/models/notificationModel";

import styles from "../../notification.module.scss";

type Props = {
  record: NotificationModel;
  onNavigatePage: (url: string, record: NotificationModel) => void;
};

const OrderPartiallyRecieved = ({ record, onNavigatePage }: Props) => {
  const dispatch = useDispatch();
  const { setPartyView } = partyReducerActions;
  const { t } = useTranslation();

  const onOpenPartyView = () => {
    dispatch(
      setPartyView({
        visible: true,
        type: "supply",
        party_id: record?.order?.id,
        status: "rejected"
      })
    );
  };

  return (
    <div onClick={onOpenPartyView} className={styles.description}>
      <h3>{notificationTypesTitleValue[record.type]}</h3>
      <p>
        {record?.order?.warehouse?.name} sizning <span className={styles.name}>P-{record?.order?.id}</span> raqamli
        {t("notification.partyanigizda kamchilik aniqladi.")}
      </p>
    </div>
  );
};

export default OrderPartiallyRecieved;
