import React, { CSSProperties } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import ActionDots from "features/projects/components/projects-table/action-dots/action-dots";
import { useTranslation } from "react-i18next";
import { rootPaths } from "routes/root/rootPaths";

import { useAmountFixer } from "modules/common";

import { useRoutePermissions } from "../../../../hooks/useRoutePermissions";
import { projectsReducerActions } from "../../../../store/reducers/projectsReducer";
import PlusCircleIcon from "../../../app/assets/icons/PlusCircleIcon";
import { LoadingIndicator } from "../../../app/components/loading-indicator/LoadingIndicator";
import TableEmpty from "../../../app/components/table-empty/TableEmpty";
import { dayjsFormats } from "../../../app/utils/constants/dayjsFormats";
import { RU } from "../../../app/utils/constants/languages";
import { CompletedIcon } from "../../assets/icons/CompletedIcon";
import { PlanningIcon } from "../../assets/icons/PlanningIcon";
import { ProcessIcon } from "../../assets/icons/ProcessIcon";
import { SuspendedIcon } from "../../assets/icons/SuspendedIcon";
import { routeSubmodules } from "../../utils/constants/routeSubmodules";
import { projectStatuses } from "../../utils/enums/projectStatuses";
import { ProjectModel } from "../../utils/models/projectModel";
import EvictionModal from "../eviction-modal/EvictionModal";
import { ProjectProgress } from "../project-progress/ProjectProgress";

import styles from "./projectsTable.module.scss";

interface Props {
  data: ProjectModel[] | undefined;
  isLoading: boolean;
}

const ProjectsTable: React.FC<Props> = ({ data, isLoading }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const location = useLocation();
  const isProjectsPage = location.pathname?.split("/")[1] === "projects";
  const { setProjectModalData } = projectsReducerActions;
  const amountFixer = useAmountFixer;

  const { actions } = useRoutePermissions("Loyiha", routeSubmodules);
  const projectActions = actions("Loyihalar");

  const statuses = {
    [projectStatuses.PROCESS]: (
      <div className={`${styles.status} ${styles[projectStatuses.PROCESS]}`}>
        <ProcessIcon />
        <span>{t("project.Jarayonda")}</span>
      </div>
    ),
    [projectStatuses.PLANNING]: (
      <div className={`${styles.status} ${styles[projectStatuses.PLANNING]}`}>
        <PlanningIcon />
        <span>{t("project.Rejalanmoqda")}</span>
      </div>
    ),
    [projectStatuses.COMPLETED]: (
      <div className={`${styles.status} ${styles[projectStatuses.COMPLETED]}`}>
        <CompletedIcon />
        <span>{t("project.Yopildi")}</span>
      </div>
    ),
    [projectStatuses.SUSPENDED]: (
      <div className={`${styles.status} ${styles[projectStatuses.SUSPENDED]}`}>
        <SuspendedIcon />
        <span>{t("project.To’xtatilgan")}</span>
      </div>
    )
  };

  const dateColor = (due_date?: string, prediction_date?: string): CSSProperties => {
    if (due_date && prediction_date) {
      const diff = dayjs(prediction_date, dayjsFormats.DATE).diff(dayjs(due_date, dayjsFormats.DATE), "day");

      if (diff > 0)
        return {
          color: "#F04438"
        };
      if (diff < 0)
        return {
          color: "#12B76A"
        };
      return {
        color: "#344054"
      };
    }
    return {
      color: "#344054"
    };
  };

  const amountColor = (totalAmount: number, spendAmount: number, predictionAmount: number) => {
    if (totalAmount > spendAmount + predictionAmount) {
      return styles.green;
    }
    if (totalAmount < spendAmount + predictionAmount) {
      return styles.red;
    }
    return "";
  };

  const onClickColumn = (id: number | string) => {
    navigate(`${rootPaths.PROJECTS}/project/${id.toString()}/estimate`, {
      state: { path: location.pathname }
    });
  };
  const columns: ColumnsType<ProjectModel> = [
    {
      title: `${t("project.Loyiha nomi")}`,
      dataIndex: "name",
      width: "18.6rem"
    },
    {
      title: (
        <div className={styles.table__header}>
          <h4>{t("project.Muddat")}</h4>
          <span>
            ( {t("project.Reja")} | {t("project.Fakt")} )
          </span>
        </div>
      ),
      render: (_, record) => (
        <div className={styles.dates}>
          <div className={styles.dates__item}>{record?.due_date ?? "-"}</div>
          <span className={styles.amounts__line} />
          <div className={styles.dates__item} style={dateColor(record.due_date, record.prediction_date)}>
            {record.prediction_date ?? "-"}
          </div>
        </div>
      )
    },
    {
      title: (
        <div className={styles.table__header}>
          <h4>{t("project.Summa")}</h4>
          <span>
            ( {t("project.Reja")} | {t("project.Fakt")} | {t("project.Bashorat")} )
          </span>
        </div>
      ),
      render: (_, record) => (
        <div className={styles.amounts}>
          <Tooltip title={record.total_amount?.toLocaleString(RU)}>
            <div className={styles.amounts__item}>
              <span>{amountFixer(String(record?.total_amount || 0))}</span>
              <span>{record?.currency?.symbol}</span>
            </div>
          </Tooltip>
          <span className={styles.amounts__line} />
          <Tooltip title={record.spend_amount?.toLocaleString(RU)}>
            <div className={styles.amounts__item}>
              <span>{amountFixer(String(record?.spend_amount || 0))}</span>
              <span>{record?.currency?.symbol}</span>
            </div>
          </Tooltip>
          <span className={styles.amounts__line} />
          <Tooltip title={Number(record?.prediction_amount).toLocaleString(RU)}>
            <div
              className={`${styles.amounts__item} ${amountColor(
                record?.total_amount,
                record?.spend_amount,
                record?.prediction_amount
              )}`}
            >
              <span>{amountFixer(String(record?.prediction_amount || 0))}</span>
              <span>{record?.currency?.symbol}</span>
            </div>
          </Tooltip>
        </div>
      ),
      align: "center"
    },
    {
      title: `${t("project.Jarayon")}`,
      render: (_, record) => (
        <div className={styles.progress}>
          <ProjectProgress percent={record?.percent} />
        </div>
      )
    },
    {
      title: `${t("project.Holati")}`,
      render: (_, record) => statuses[record.status!],
      align: "center"
    },
    {
      title: "",
      render: (_, record) => <ActionDots record={record} onClickColumn={onClickColumn} />,
      className: "three_point",
      align: "center"
    }
  ];

  const onOpenAddProject = () => {
    dispatch(
      setProjectModalData({
        visible: true
      })
    );
  };

  return (
    <>
      <Table
        bordered
        dataSource={data}
        columns={columns}
        pagination={false}
        className={`${styles.table} footer_table`}
        rowKey={row => row.id!}
        onRow={record => ({
          onClick: () => onClickColumn(record.id!)
        })}
        loading={{
          spinning: isLoading,
          indicator: LoadingIndicator
        }}
        locale={{
          emptyText: <TableEmpty />
        }}
        rowClassName="c_p"
        footer={
          isProjectsPage
            ? () => (
                <>
                  {projectActions.create && (
                    <div className={styles.add_project} onClick={onOpenAddProject}>
                      <PlusCircleIcon fill /> {t("project.Loyiha")}
                    </div>
                  )}
                </>
              )
            : undefined
        }
      />
      <EvictionModal />
    </>
  );
};

export default ProjectsTable;
