import React, { useMemo } from "react";
import dayjs from "dayjs";
import { colors } from "features/app/utils/constants/colors";
import { getBaseCurrency } from "features/app/utils/helpers/baseCurrency";
import { useTranslation } from "react-i18next";
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from "recharts";
import { CategoricalChartState } from "recharts/types/chart/types";

import { dayjsFormats, isEmptyArr, RU, useAmountFixer } from "modules/common";
import { CompanyWidget, Line as LineType, useWidget } from "modules/dashboard";

import { WidgetCustomTooltip } from "../../tooltip";

interface IProps {
  item: CompanyWidget;
}

const Line: React.FC<IProps> = ({ item }) => {
  const { i18n } = useTranslation();
  const { setWidgetDrawer } = useWidget().methods;

  const currency = getBaseCurrency();

  const comingData = useMemo((): LineType => ({ ...item.data }), [item.data]);

  // change

  const chartData = useMemo(
    () =>
      comingData?.data?.map(line => {
        const paymentTypeCounts: Record<string, number> = {};

        line?.values?.forEach(type => {
          paymentTypeCounts[type.name as string] = type.total;
        });

        return {
          date: line?.date,
          month: dayjs(line?.date, dayjsFormats.MONTH_DATE).format(dayjsFormats.MONTH_NAME),
          ...paymentTypeCounts
        };
      }),
    [comingData]
  );

  const chartKeys = useMemo(() => {
    const uniqueValuesSet = new Set(comingData?.data?.flatMap(line => line.values?.map(value => value.name)));

    return Array.from(uniqueValuesSet);
  }, [comingData]);

  const chartColors = useMemo(() => {
    const uniqueValuesSet = new Set(
      comingData?.data?.flatMap(line => line.values?.map(value => value?.color as string))
    );

    return Array.from(uniqueValuesSet);
  }, [comingData]);

  const onOpenWidgetView = (data: CategoricalChartState) => {
    const { activeLabel } = data;
    const monthName = activeLabel;
    const widgetData = comingData?.data?.find(
      d => dayjs(d.date, dayjsFormats.MONTH_DATE).format(dayjsFormats.MONTH_NAME) === monthName
    );

    if (widgetData) {
      setWidgetDrawer({
        id: item.id,
        key: item.key,
        visible: true,
        title: item.name[i18n.language],
        props: widgetData,
        widget: item
      });
    }
  };

  const customTooltip = ({ active, payload, label }: TooltipProps<any, any>) => {
    if (active && payload && payload.length) {
      return (
        <div className="flex flex-col gap-2">
          {payload.map((data, index) => (
            <div
              key={index}
              style={{ borderColor: data.color }}
              className="flex flex-col gap-1 border-b-0 border-l-4 border-r-0 border-t-0 border-solid pl-2"
            >
              <span className="text-sm font-semibold text-white">
                {data.name}: {data.value.toLocaleString(RU)} {currency?.symbol}
              </span>
            </div>
          ))}
        </div>
      );
    }

    return null;
  };

  const getBarRadius = (index: number): number | [number, number, number, number] => {
    if (chartKeys?.length > 1) {
      if (index === 0) {
        return [0, 0, 12, 12];
      }
      if (index === chartKeys?.length - 1) {
        return [12, 12, 0, 0];
      }
    }
    return 0;
  };

  return (
    <div onClick={e => e.stopPropagation()} onMouseDown={e => e.stopPropagation()} className="h-[98%] w-full">
      <div className="h-full w-full">
        {!isEmptyArr(chartKeys) && (
          <ResponsiveContainer>
            <BarChart
              onClick={e => onOpenWidgetView(e)}
              data={chartData}
              margin={{ top: 16, right: 16, bottom: 10, left: 16 }}
            >
              <CartesianGrid vertical={false} strokeDasharray="5 5" stroke={colors.GRAY_300} />
              <XAxis
                dataKey="month"
                axisLine={{ stroke: colors.GRAY_300 }}
                tickSize={0}
                tickLine={false}
                tickMargin={20}
                fontWeight={500}
                fontSize={14}
              />
              <YAxis
                // eslint-disable-next-line react-hooks/rules-of-hooks
                tickFormatter={value => useAmountFixer(value)}
                tickSize={0}
                tickLine={false}
                axisLine={false}
                tickMargin={20}
                fontWeight={500}
                fontSize={14}
              />
              <Tooltip
                cursor={{ fill: "transparent" }}
                content={props => <WidgetCustomTooltip children={customTooltip(props)} />}
              />
              {chartKeys.map((key, index) => (
                <Bar
                  radius={getBarRadius(index)}
                  key={key}
                  stackId="a"
                  dataKey={key}
                  fill={chartColors[index]}
                  style={{ cursor: "pointer" }}
                  barSize={48}
                />
              ))}
            </BarChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
};

export default Line;
