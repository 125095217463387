import React from "react";
import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useTranslation } from "react-i18next";

import { LoadingIndicator } from "../../../../../app/components/loading-indicator/LoadingIndicator";
import TableEmpty from "../../../../../app/components/table-empty/TableEmpty";
import { ProductDevModel } from "../../../../utils/models/product-dev/productDevModel";

import styles from "./settingsProductsTemplateTable.module.scss";

type Props = {
  data?: ProductDevModel[];
  isLoading: boolean;
};

const SettingsProductsTemplateTable: React.FC<Props> = ({ data, isLoading }) => {
  const { i18n, t } = useTranslation();
  const { language } = i18n;

  const columns: ColumnsType<ProductDevModel> = [
    {
      title: `${t("products.Nomi")}`,
      render: (record: ProductDevModel) => (
        <span>
          <span
            className={styles.symbol}
            style={{
              borderColor: record.resource.color,
              color: record.resource.color
            }}
          >
            {record.resource.symbol[language]}
          </span>
          {record.name[language]}
        </span>
      )
    },
    {
      title: `${t("products.Birligi")}`,
      render: (record: ProductDevModel) => record.unit.symbol[language]
    },
    {
      title: t("catalog.Kodi"),
      render: (record: ProductDevModel) => record.code
    },
    {
      title: t("catalog.Bozor narxi"),
      render: (record: ProductDevModel) => (
        <span className={styles.market_price}>
          {record.market_price} {record.market_price_currency}
        </span>
      )
    },
    {
      title: t("catalog.Bozor narxi sanasi"),
      dataIndex: "market_price_date"
    }
  ];

  return (
    <div className={styles.table}>
      <Table<ProductDevModel>
        columns={columns}
        dataSource={data}
        pagination={false}
        rowKey={row => row.id}
        loading={{
          spinning: isLoading,
          indicator: LoadingIndicator
        }}
        locale={{
          emptyText: <TableEmpty />
        }}
      />
    </div>
  );
};

export default SettingsProductsTemplateTable;
