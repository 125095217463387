import React, { MouseEvent, useEffect, useRef, useState } from "react";
import { Button, Popover } from "antd";
import { ColumnsType } from "antd/es/table";
import CustomAvatar from "features/app/components/custom-avatar/CustomAvatar";
import { useChangeTableConfig } from "features/app/service/mutation";
import { useGetTableConfigs } from "features/app/service/queries";
import { tableConfigKeys } from "features/app/utils/constants/tableConfigKeys";
import { useTranslation } from "react-i18next";

import { Table } from "components";
import useDebounce from "components/use-debounce/use-debounce";

import { useAppDispatch } from "../../../../../../../hooks/redux";
import { paymentReducerActions } from "../../../../../../../store/reducers/paymentReducer";
import { LoadingIndicator } from "../../../../../../app/components/loading-indicator/LoadingIndicator";
import Pagination from "../../../../../../app/components/pagination/Pagination";
import TableEmpty from "../../../../../../app/components/table-empty/TableEmpty";
import { RU } from "../../../../../../app/utils/constants/languages";
import InventoryWorkPaymentView from "../../../../../../payment/components/bottom/inventory-work-payment-view/InventoryWorkPaymentView";
import { PaymentTabKeys } from "../../../../../../payment/utils/constants/paymentTabKeys";
import { PaymentInventoryWorkModel } from "../../../../../../payment/utils/models/paymentInventoryModel";
import { useGetWorkView } from "../../../../../service/queries";

import styles from "./workPaymentTable.module.scss";

type Props = {
  defaultTabKey?: string;
};

const WorkPaymentTable: React.FC<Props> = ({ defaultTabKey }) => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const { i18n, t } = useTranslation();
  const dispatch = useAppDispatch();
  const [visibleWorkPayment, setVisibleWorkPayment] = useState(false);
  const [workPaymentData, setWorkPaymentData] = useState<PaymentInventoryWorkModel>();
  const { setPaymentInventoryWorkModal } = paymentReducerActions;
  const { data: paymentsWork, isLoading } = useGetWorkView(defaultTabKey === PaymentTabKeys.WORK);

  const changeTableConfig = useChangeTableConfig(tableConfigKeys.COUNTERPARTS_PAYMENTS_WORKS, true);
  const { data: tableConfig } = useGetTableConfigs(tableConfigKeys.COUNTERPARTS_PAYMENTS_WORKS);

  const onDetailWork = (record: PaymentInventoryWorkModel) => ({
    onClick: () => {
      setVisibleWorkPayment(true);
      setWorkPaymentData(record);
    }
  });

  const onVisiblePayment = (event: MouseEvent<HTMLDivElement>, record?: PaymentInventoryWorkModel) => {
    event.stopPropagation();
    dispatch(
      setPaymentInventoryWorkModal({
        visible: true,
        amount: Number(record?.amount) * Number(record?.quantity),
        id: record?.id,
        personId: record?.company_person?.id,
        debtAmount: record?.debt_amount,
        defaultCurrency: record?.currency
      })
    );
  };

  const [columns, setColumns] = useState<ColumnsType<PaymentInventoryWorkModel>>([
    {
      title: `${t("Counterparts.Ish nomi va loyiha")}`,
      render: (record: PaymentInventoryWorkModel) => (
        <div className={styles.name}>
          {record?.task?.name.length > 35 ? (
            <Popover title={record?.task?.name} overlayClassName={styles.popover}>
              <p>
                {record?.place}. {record?.task?.name.substring(0, 35)}...
              </p>
            </Popover>
          ) : (
            <p>
              {record?.place}. {record?.task?.name}
            </p>
          )}

          <p>{record?.project?.name}</p>
        </div>
      ),
      width: 450,
      className: "counterparts-detailed-works-name"
    },
    {
      title: `${t("Counterparts.Ish tugallandi")}`,
      dataIndex: "completed_date",
      width: 200,
      className: "counterparts-detailed-works-completed-date"
    },

    {
      title: `${t("Counterparts.Ishni bajardi")}`,
      render: (record: PaymentInventoryWorkModel) => (
        <div className={styles.paymentAction}>{record?.company_person?.name} </div>
      ),
      width: 250,
      className: "counterparts-detailed-works-payment-action"
    },
    {
      title: `${t("Counterparts.Ish hajmi")}`,
      render: (record: PaymentInventoryWorkModel) => (
        <span>
          {record?.quantity.toLocaleString(RU)} {record?.unit?.symbol[i18n.language]}
        </span>
      ),
      width: 250,
      className: "counterparts-detailed-works-quantity"
    },
    {
      title: `${t("Counterparts.Summasi")}`,
      render: (record: PaymentInventoryWorkModel) => (
        <span>
          {record?.total_amount?.toLocaleString(RU)} {record?.currency?.symbol}
        </span>
      ),
      width: 250,
      className: "counterparts-detailed-works-total-amount"
    },

    {
      title: `${t("Counterparts.Xodim")}`,
      render: (record: PaymentInventoryWorkModel) => (
        <div className={styles.creator}>
          <CustomAvatar name={record?.creator?.full_name} image={record?.creator?.image} />
        </div>
      ),
      width: 250,
      className: "counterparts-detailed-works-creator"
    },
    {
      title: `${t("Counterparts.To'lov holati")}`,
      render: (record: PaymentInventoryWorkModel) => (
        <span>
          {record?.payment_percent}%
          {!(record?.debt_amount === 0) && (
            <div className={styles.action} onClick={event => onVisiblePayment(event, record)}>
              <Button type="primary">{t("Counterparts.To’lov qilish")}</Button>
            </div>
          )}
        </span>
      ),
      align: "center",
      width: 300,
      className: "counterparts-detailed-works-payment-percent"
    }
  ]);

  useEffect(() => {
    if (tableConfig!?.width_data!?.length > 0) {
      const newColumns = tableConfig?.width_data?.map(column => ({
        ...columns?.find(el => el.className === column.className),
        width: column.width
      }));

      setColumns(newColumns as never);
    }
  }, [tableConfig]);

  const saveColumns = () => {
    changeTableConfig.mutateAsync({
      key: tableConfigKeys.COUNTERPARTS_PAYMENTS_WORKS,
      width_data: JSON.stringify(columns)
    });
  };

  const debouncedSaveColumns = useDebounce(saveColumns, 800, timeoutRef);

  const onChangeColumns = (newColumns: ColumnsType<PaymentInventoryWorkModel>) => {
    setColumns(newColumns);
    debouncedSaveColumns();
  };

  return (
    <div className={styles.work_payment}>
      <Table<PaymentInventoryWorkModel>
        onChangeColumns={onChangeColumns}
        columns={columns}
        pagination={false}
        dataSource={paymentsWork?.data}
        rowKey={record => record.id}
        onRow={onDetailWork}
        rowClassName={() => styles.row}
        scroll={{ y: "65vh" }}
        loading={{
          spinning: isLoading,
          indicator: LoadingIndicator
        }}
        locale={{
          emptyText: <TableEmpty />
        }}
      />
      <div className={styles.pagination}>
        <Pagination
          paginationProps={{
            total: paymentsWork?.total
          }}
        />
      </div>
      <InventoryWorkPaymentView open={visibleWorkPayment} setOpen={setVisibleWorkPayment} record={workPaymentData} />
    </div>
  );
};

export default WorkPaymentTable;
