import React from "react";
import { Form, Input } from "antd";
import { useTranslation } from "react-i18next";

const { Item } = Form;

export interface Props {
  className: string;
  inputClassName: string;
}

const MfoOker: React.FC<Props> = ({ className, inputClassName }) => {
  const { t } = useTranslation();

  return (
    <div className={className}>
      <Item name="mfo" label={t("builder.MFO")} className={inputClassName}>
        <Input placeholder={t("builder.Kiriting..")} />
      </Item>
      <Item name="oked" label={t("builder.OKER")} className={inputClassName}>
        <Input placeholder={t("builder.Kiriting..")} />
      </Item>
    </div>
  );
};

export default MfoOker;
