import React from "react";
import { Checkbox } from "antd";
import { useTranslation } from "react-i18next";

import { useDownloadFile } from "../../../../app/service/mutation";
import { PaymentModel } from "../../../../app/utils/models/payment/paymentModel";
import CustomColumnFileIcon from "../../../../app/assets/icons/CustomColumnFileIcon";
import { CustomFieldTypesEnum } from "../../../../app/utils/enums/customFieldTypesEnum";
import { CustomFieldValueModel } from "../../../../app/utils/models/customFIeldValueModel";

import styles from "./incomeExpenseView.module.scss";

type Props = {
  record: CustomFieldValueModel;
  payment: Partial<PaymentModel>;
};

const CustomFields: React.FC<Props> = ({ record, payment }) => {
  const { i18n } = useTranslation();
  const downloadFile = useDownloadFile();
  
  const onDownloadFile = (id: number, name: string) => {
    downloadFile.mutateAsync({ id, name });
  };

  const labelCustomFields = payment?.custom_field_values?.filter(
    (item) => item.custom_field?.type === CustomFieldTypesEnum.LABEL
  );

  const type = record?.custom_field?.type;

  if (record) {
    if (type === CustomFieldTypesEnum.SELECT) {
      return record?.custom_field_option?.name[i18n.language];
    }
    if (type === CustomFieldTypesEnum.DATE) {
      return record?.value;
    }
    if (type === CustomFieldTypesEnum.TEXT) {
      return record?.value;
    }
    if (type === CustomFieldTypesEnum.FILE) {
      return (
        <div
          className={styles.file}
          onClick={() =>
            onDownloadFile(record?.file?.id, record?.file?.original_name)
          }
        >
          <CustomColumnFileIcon />
          {record?.file?.original_name}
        </div>
      );
    }
    if (type === CustomFieldTypesEnum.CHECKBOX) {
      return <Checkbox checked />;
    }
    if (type === CustomFieldTypesEnum.LABEL) {
      return (
        <span>
          {labelCustomFields?.map(
            (item, index) =>
              `${item.custom_field_option?.name[i18n.language]}${
                index + 1 === labelCustomFields?.length ? " " : ", "
              }`
          )}
        </span>
      );
    }
  }

  return "-";
};

export default CustomFields;
