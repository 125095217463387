import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { paymentReducerActions } from "store/reducers/paymentReducer";

import { RU } from "../../../../utils/constants/languages";
import { notificationTypesTitleValue } from "../../../../utils/constants/notificationTypes";
import { NotificationModel } from "../../../../utils/models/notificationModel";

import styles from "../../notification.module.scss";

type Props = {
  record: NotificationModel;
  onNavigatePage: (url: string, record: NotificationModel) => void;
};

const PaymentTransferRejected = ({ record, onNavigatePage }: Props) => {
  const dispatch = useDispatch();
  const { setTransferHistoryModal } = paymentReducerActions;
  const { t } = useTranslation();

  const onOpenPaymentView = () => {
    dispatch(
      setTransferHistoryModal({
        id: record?.payment?.id,
        isDeleted: record?.payment?.status === "deleted"
      } as never)
    );
  };

  return (
    <div onClick={onOpenPaymentView} className={styles.description}>
      <h3>{notificationTypesTitleValue[record.type]}</h3>
      <p>
        {record?.payment?.income_cash?.name}{" "}
        <span className={styles.name}>
          {record?.payment?.amount?.toLocaleString(RU)} {record?.payment?.currency?.symbol}
        </span>{" "}
        {t("notification.miqdorda pul mablag’ini qabul qilmadi.")}
      </p>
    </div>
  );
};

export default PaymentTransferRejected;
