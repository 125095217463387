import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Form, Modal } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { isEmptyArr } from "modules/common";

import { useAppSelector } from "../../../../../hooks/redux";
import { useRoutePermissions } from "../../../../../hooks/useRoutePermissions";
import { paymentReducerActions } from "../../../../../store/reducers/paymentReducer";
import ConditionalRender from "../../../../app/components/conditional-render/ConditionalRender";
import { colors } from "../../../../app/utils/constants/colors";
import { dayjsFormats } from "../../../../app/utils/constants/dayjsFormats";
import { RU } from "../../../../app/utils/constants/languages";
import { PaymentTypeEnum } from "../../../../app/utils/constants/paymentTypeEnum";
import { PaymentStatusEnums } from "../../../../app/utils/enums/paymentStatusEnums";
import { cx } from "../../../../app/utils/helpers/cx";
import { localeFormatter } from "../../../../app/utils/helpers/localeFormatter";
import { parseLocaledString } from "../../../../app/utils/helpers/parseLocaledString";
import CreditCardDownloadIcon from "../../../assets/icons/CreditCardDownloadIcon";
import CreditCardUploadIcon from "../../../assets/icons/CreditCardUploadIcon";
import FileQuestionIcon from "../../../assets/icons/FileQuestionIcon";
import { useCreatePayment, useUpdatePayment } from "../../../service/mutation";
import { routeSubmodules } from "../../../utils/constants/routeSubmodules";
import { INCOME_EXPENSE_TYPE } from "../../../utils/enums/incomeExpenseTypeEnums";
import { generateCustomFieldsData } from "../../../utils/helpers/paymentCustomField";
import { PaymentCustomFieldValueModel } from "../../../utils/models/paymentCustomFieldValue";
import { PaymentReqBodyModel } from "../../../utils/models/paymentReqBodyModel";

import FirstStep from "./first/FirstStep";
import SecondStep from "./second/SecondStep";
import ThirthStep from "./thirth/ThirdStep";

import styles from "./incomeOrExpense.module.scss";

type paymentStatus = "active" | "passive";

interface FormModel {
  date?: Date;
  amount: string;
  financial: number;
  project_id: number;
  description?: string;
  status: paymentStatus;
  currency_amount?: string;
  company_person_id?: number;
  income_expense_type: string;
  financial_id: number | string;
  custom_field_values?: PaymentCustomFieldValueModel[];
  file_ids?: number[];
  order_ids: number[];
  custom_description?: string;
}

const { Item, useForm, useWatch } = Form;

const IncomeOrExpenseModal: React.FC = () => {
  const [autoAdvance, setAutoAdvance] = useState<boolean>(false);
  const [form] = useForm();
  const dispatch = useDispatch();
  const createPayment = useCreatePayment();
  const updatePayment = useUpdatePayment();
  const activeStatus = useWatch("status", form) as paymentStatus;
  const { actions } = useRoutePermissions("Kassa", routeSubmodules);
  const routeCompanyPersonActions = actions("Kirim-chiqim");
  const { setIncomeExpenseModal, setPaymentMoreInfo } = paymentReducerActions;

  const {
    id,
    type,
    cash,
    task,
    visible,
    isClone,
    currency,
    defaultStep,
    defaultData,
    payment_type,
    isVisibleMoreInfo,
    companyPersonId
  } = useAppSelector(state => state.paymentReducer.income_expense_modal);
  const [step, setStep] = useState<number>(defaultStep || 0);
  const { t } = useTranslation();

  const prev = () => {
    setAutoAdvance(false);
    setStep(step - 1);
  };

  const next = () => {
    setAutoAdvance(true);
    setStep(step + 1);
  };

  const onOk = (status: paymentStatus) => () => {
    if (status === PaymentStatusEnums.ACTIVE) {
      form.setFieldValue("status", PaymentStatusEnums.ACTIVE);
    } else {
      form.setFieldValue("status", PaymentStatusEnums.PASSIVE);
    }

    form.submit();
  };

  const onAfterOpen = (open: boolean) => {
    if (open) {
      setAutoAdvance(true);
      form.setFieldValue("company_person_id", companyPersonId);

      if (companyPersonId) {
        form.setFieldValue("income_expense_type", INCOME_EXPENSE_TYPE.company_person_id);
      }

      if (id) {
        form.setFieldsValue({
          cash_id: cash?.id,
          status: defaultData?.status,
          amount: localeFormatter(String(defaultData?.amount || "0")),
          currency_amount: localeFormatter(String(defaultData?.currency_amount || "0"))
        });
      }
    } else {
      setAutoAdvance(false);
      form.resetFields();
      dispatch(setPaymentMoreInfo(false));
      setStep(0);
    }
  };

  const onCancel = () => {
    dispatch(
      setIncomeExpenseModal({
        type,
        visible: false,
        isClone: false,
        isVisibleMoreInfo,
        defaultData: undefined
      })
    );
  };

  const title = () => {
    const paymentTypeTitle = {
      [PaymentTypeEnum.INCOME]: t("payment.Kirim"),
      [PaymentTypeEnum.EXPENSE]: t("payment.Chiqim")
    };

    const activePaymentTypeTitle = paymentTypeTitle[type as keyof typeof paymentTypeTitle];

    const description = [
      `${activePaymentTypeTitle}${t("payment.ni qaysi kassadan amalga oshirishni tanlang")}`,
      `${activePaymentTypeTitle} ${t("payment.uchun to’lov turi va valyutasini tanlang")}`,
      `${cash?.name} / ${payment_type?.name}`
    ];

    return (
      <div className={styles.title}>
        <div
          className={cx(
            styles.title__icon,
            { "bg-success-100": type === PaymentTypeEnum.INCOME },
            { "bg-error-100": type === PaymentTypeEnum.EXPENSE }
          )}
        >
          {type === PaymentTypeEnum.INCOME ? (
            <CreditCardUploadIcon isNotArrow stroke={colors.SUCCESS_500} />
          ) : (
            <CreditCardDownloadIcon isNotArrow stroke={colors.ERROR_500} />
          )}
        </div>
        <div className={styles.title__info}>
          <h4>
            {activePaymentTypeTitle}
            {!isClone && defaultData?.id ? t("payment.ni tahrirlash") : ""}
            {isClone ? t("payment.dan nusxa olish") : ""}
          </h4>
          <div className="flex items-center justify-between">
            <span className="text-sm font-normal text-gray-600">{description[step]} </span>
            <span className="text-sm font-medium text-gray-600">
              {step === 2 && !defaultData?.id ? (
                <>
                  {currency?.amount?.toLocaleString(RU)} {currency?.symbol}
                </>
              ) : (
                ""
              )}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const backCondition = (): boolean => {
    if (step > 0) {
      if (!isClone) {
        return !(defaultData?.order_ids && !isEmptyArr(defaultData?.order_ids));
      }

      return true;
    }

    return false;
  };

  const footer = () => (
    <div className={styles.footer}>
      <ConditionalRender
        else={<div />}
        if={step === 2 && routeCompanyPersonActions.createDraft && type === PaymentTypeEnum.EXPENSE}
      >
        <Button
          onClick={onOk(PaymentStatusEnums.PASSIVE)}
          loading={activeStatus === PaymentStatusEnums.PASSIVE && (createPayment.isLoading || updatePayment.isLoading)}
        >
          <FileQuestionIcon /> {t("payment.Qoralama")}
        </Button>
      </ConditionalRender>
      <div className={styles.footer__right}>
        <ConditionalRender if={backCondition()}>
          <Button onClick={prev}>{t("payment.Ortga")}</Button>
        </ConditionalRender>
        <ConditionalRender if={step !== 2 && defaultData?.id}>
          <Button onClick={next}>{t("payment.Oldinga")}</Button>
        </ConditionalRender>
        <ConditionalRender if={step !== 2}>
          <Button onClick={onCancel}>{t("payment.Yopish")}</Button>
        </ConditionalRender>
        <ConditionalRender
          if={step === 2 && (routeCompanyPersonActions.create || routeCompanyPersonActions.makeIncome)}
        >
          <Button
            type="primary"
            onClick={onOk(PaymentStatusEnums.ACTIVE)}
            loading={activeStatus === PaymentStatusEnums.ACTIVE && (createPayment.isLoading || updatePayment.isLoading)}
          >
            {t("payment.Tasdiqlash")}
          </Button>
        </ConditionalRender>
      </div>
    </div>
  );

  const content: Record<number, React.ReactNode> = {
    0: <FirstStep setStep={setStep} autoAdvance={autoAdvance} setAutoAdvance={setAutoAdvance} />,
    1: <SecondStep setStep={setStep} form={form} autoAdvance={autoAdvance} setAutoAdvance={setAutoAdvance} />,
    2: <ThirthStep step={step} form={form} />
  };

  const onFinish = (values: FormModel) => {
    const { custom_description, ...rest } = values;
    const copyValue = { ...rest };
    const status = copyValue?.status;
    const fields = generateCustomFieldsData(values?.custom_field_values) as never;
    const reqData: PaymentReqBodyModel = {
      type,
      status,
      task_id: task?.id,
      cash_id: cash?.id,
      custom_field_values: fields,
      project_id: values.project_id,
      description: values.description,
      payment_type_id: payment_type?.id,
      currency_id: Number(currency?.id),
      amount: parseLocaledString(values.amount),
      order_ids: values?.order_ids,
      currency_amount: parseLocaledString(values.currency_amount ?? "1") ?? 1,
      date: values?.date ? dayjs(values.date).format(dayjsFormats.DATE) : undefined,
      [copyValue?.income_expense_type]: copyValue[copyValue.income_expense_type as keyof typeof copyValue],
      ...(copyValue.financial && { financial_id: copyValue?.financial }),
      file_ids: values.file_ids?.map(Number) || []
    };

    if (id && !isClone) {
      updatePayment
        .mutateAsync({
          id,
          ...reqData
        })
        .then(onCancel);
    } else {
      createPayment.mutateAsync(reqData).then(onCancel);
    }
  };

  useEffect(() => {
    if (defaultStep) {
      setStep(defaultStep);
    }
  }, [defaultStep]);

  return (
    <Modal
      centered
      open={visible}
      title={title()}
      footer={footer()}
      onCancel={onCancel}
      maskClosable={false}
      className={styles.modal}
      afterOpenChange={onAfterOpen}
      width={step === 2 ? "34.5rem" : "64rem"}
    >
      <Form form={form} onFinish={onFinish} layout="vertical">
        {content[step]}
        <Item name="status" className="hidden" initialValue="active" />
      </Form>
    </Modal>
  );
};

export default IncomeOrExpenseModal;
