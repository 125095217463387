import i18next from "i18next";

import { numberToFixed } from "../helpers";

export const useAmountFixer = (amount: string) => {
  const localizedNumbers: Record<string, Record<string, number>> = {
    en: { k: 1e3, mln: 1e6, b: 1e9, trln: 1e12 },
    uz: { ming: 1e3, mln: 1e6, mlrd: 1e9, trln: 1e12 },
    ru: { тыс: 1e3, млн: 1e6, млрд: 1e9, трлн: 1e12 }
  };

  const numbers = localizedNumbers[i18next.language] || localizedNumbers.uz;

  const value = () => {
    let result = amount;

    Object.keys(numbers).forEach(key => {
      const objKey = key as keyof typeof numbers;

      if (Math.abs(Number(amount)) >= numbers[objKey]) {
        result = `${numberToFixed(Number(amount) / numbers[objKey])} ${objKey}` as never;
      }
    });

    return result;
  };

  return value();
};
