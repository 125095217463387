import React, { useMemo } from "react";
import { Spin } from "antd";
import dayjs from "dayjs";
import ConditionalRender from "features/app/components/conditional-render/ConditionalRender";
import PartyModal from "features/supply/components/parts/party-modal/PartyModal";
import { useTranslation } from "react-i18next";

import { dayjsFormats, isEmptyArr, RU } from "modules/common";
import { CompanyWidget, Gantt as GanttType, GanttLine as GanttLineType, useUpdateWidget } from "modules/dashboard";

import { LoadingIndicator } from "components";

import { dailySelectItems } from "../../../../../../features/payment/utils/constants/calendarItems";
import { numberToDateFormat } from "../../../../../../modules/dashboard/helpers/number-to-date-format";

import GanttLine from "./gantt-line";
import ProductSelect from "./product-select";

type Props = {
  id: number;
  initialData?: GanttType;
  filter?: CompanyWidget["filter"];
};

const Gantt: React.FC<Props> = ({ initialData, filter, id }) => {
  const { t } = useTranslation();
  const updateFilter = useUpdateWidget({ isNotSuccessMessage: true });

  const lastProduct = (type: "offer" | "income") => {
    const reverseData = [...(initialData?.data?.slice()?.reverse() || [])];

    return reverseData?.find(item => (type === "income" ? item?.order_id : !item?.order_id));
  };

  const data = useMemo(
    (): GanttLineType => [
      ...((initialData?.data || [])?.map(item => ({
        name: numberToDateFormat(item?.date),
        uv: item?.order_id ? item?.value : null,
        pv: !item?.order_id ? item?.value : null,
        item: { ...item }
      })) || [])
    ],
    [initialData]
  );

  const generateDate = (number?: number) => {
    if (number) {
      const date = numberToDateFormat(number, dayjsFormats.DATE);
      const diff = dayjs(date, dayjsFormats.DATE).diff(dayjs(), "day");

      const defaultDates: Record<number, string> = {
        [-1]: "1 kun oldingi holat bo'yicha",
        0: "Bugungi holat bo'yicha",
        1: "Ertangi holat bo'yicha",
        [-2]: "2 kun oldingi holat bo'yicha"
      };

      const [day, month] = date.split(".");
      const monthName = dailySelectItems.find(item => item.vale === month)?.name?.toLowerCase();

      return defaultDates[diff]
        ? t(`dashboard.${defaultDates[diff]}`)
        : `${day}-${monthName} ${t("dashboard.holati bo'yicha")}`;
    }

    return "";
  };

  return (
    <Spin spinning={updateFilter?.isLoading} indicator={LoadingIndicator}>
      <div className="flex flex-col gap-6">
        <ProductSelect
          id={id}
          filter={filter}
          updateFilter={updateFilter}
          defaultUnit={initialData?.unit}
          defaultProduct={initialData?.product}
        />
        <div className="grid grid-cols-2 gap-4">
          <ConditionalRender if={lastProduct("offer")}>
            <div className="box-border flex justify-between gap-4 rounded-xl bg-primary-50 p-3">
              <div className="flex gap-4">
                <div className="h-full w-2 rounded-xl bg-primary-500" />
                <div className="flex flex-col gap-3">
                  <h4 className="m-0 text-base font-medium text-gray-700">{t("dashboard.Bozor narxi")}</h4>
                  <h2 className="m-0 flex items-center gap-2 text-base font-medium text-gray-700">
                    {lastProduct("offer")?.amount?.toLocaleString(RU)}
                    <span className="text-sm font-semibold text-gray-400">
                      {lastProduct("offer")?.currency?.symbol}
                    </span>
                  </h2>
                </div>
              </div>
              <div className="text-sm font-normal text-gray-400">{generateDate(lastProduct("offer")?.date)}</div>
            </div>
          </ConditionalRender>
          <ConditionalRender if={lastProduct("income")}>
            <div className="box-border flex justify-between gap-4 rounded-xl bg-success-50 p-3">
              <div className="flex gap-4">
                <div className="h-full w-2 rounded-xl bg-success-500" />
                <div className="flex flex-col gap-3">
                  <h4 className="m-0 text-base font-medium text-gray-700">{t("dashboard.Kirim narxi")}</h4>
                  <h2 className="m-0 flex items-center gap-2 text-base font-medium text-gray-700">
                    {lastProduct("income")?.amount?.toLocaleString(RU)}
                    <span className="text-sm font-semibold text-gray-400">
                      {lastProduct("income")?.currency?.symbol}
                    </span>
                  </h2>
                </div>
              </div>
              <div className="text-sm font-normal text-gray-400">{generateDate(lastProduct("income")?.date)}</div>
            </div>
          </ConditionalRender>
        </div>
        <div className="h-[26.5rem] w-full">
          <GanttLine data={data} />
        </div>
        <ConditionalRender if={!isEmptyArr(data)}>
          <div className="ml-6 flex items-center gap-6">
            <div className="flex items-center gap-2">
              <div className="h-5 w-5 rounded-full bg-success-500" />
              <span className="text-sm font-medium text-gray-600">{t("dashboard.Sizning kirimlaringiz")}</span>
            </div>
            <div className="flex items-center gap-2">
              <div className="h-5 w-5 rounded-full bg-primary-500" />
              <span className="text-sm font-medium text-gray-600">{t("dashboard.Takliflar")}</span>
            </div>
          </div>
        </ConditionalRender>
      </div>
      <PartyModal />
    </Spin>
  );
};

export default Gantt;
