export const settingsQueryNames = {
  ADMIN_VIEW: "admin-view",
  ROLE_SELECT: "role-select",
  ROLE_VIEW: "role-view",
  FINANCIAL_VIEW: "financial-view",
  FINANCIAL_SELECT: "financial-select",
  FINANCIAL_CATEGORY: "financial-category",
  ACTIVITY_TYPE: "activity-type",
  CURRENCIES_VIEW: "currencies-view",
  CURRENCIES_LIST: "currencies-list",
  PAYMENT_TYPE_VIEW: "payment-type-view",
  WAREHOUSES_VIEW: "warehouses-wiew",
  PROJECT_TREE: "project-tree",
  ONE_WAREHOUSE: "one-warehouse",
  DETAILED_WAREHOUSE_FOLDER: "detailed-warehouse-folder",
  CATEGORY_VIEW: "category-view",
  SUB_CATEGORIES_VIEW: "category-subcategories-view",
  CATEGORY_PRODUCTS_VIEW: "category-category-view",
  CATEGORY_PRODUCTS_ALL_VIEW: "category-category-view-all",
  SUB_CATEGORY_PRODUCTS_VIEW: "category-category-view",
  CATEGORIES_DEV_VIEW: "categories-dev-view",
  SUB_CATEGORIES_DEV_VIEW: "sub-categories-view",
  CATEGORY_PRODUCTS_DEV_VIEW: "category-products-dev-view",
  SUB_CATEGORY_PRODUCTS_DEV_VIEW: "sub-category-products-dev-view",
  SEARCH_PRODUCTS: "search-products",
  CUSTOM_FIELDS_VIEW: "custom-fields-view",
  CUSTOM_FIELD_VIEW: "custom-field-view",
  TEMPLATES_VIEW: "template-view",
  TEMPLATE_VIEW: "template-view",
  VAT_VIEW: "vat-view",
  CURRENT_USER_VIEW: "current_user_view"
};
