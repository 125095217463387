import { useTranslation } from "react-i18next";

import { notificationTypesTitleValue } from "../../../../utils/constants/notificationTypes";
import { NotificationModel } from "../../../../utils/models/notificationModel";

import styles from "../../notification.module.scss";

type Props = {
  record: NotificationModel;
  onNavigatePage: (url: string, record: NotificationModel) => void;
};

const WarehouseProductCreated = ({ record, onNavigatePage }: Props) => {
  const { t } = useTranslation();

  return (
    <div onClick={() => onNavigatePage("/supply", record)} className={styles.description}>
      <h3>{notificationTypesTitleValue[record.type]}</h3>
      <p>
        {record?.user?.full_name} {t("notification.sizdan")} {record?.warehouse_products?.name}{" "}
        {t("notification.masulotlarga buyurtma berdi. Buyurtma yetkazilish vaqti")}
        {record?.warehouse_products?.delivery_date}.
      </p>
    </div>
  );
};

export default WarehouseProductCreated;
