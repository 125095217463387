import { UserTableDataModel } from "../../../app/utils/models/user/userTableConfigModel";

export const warehouseIncomeExpenseHistoryTableDefaultData: UserTableDataModel = {
  column: [
    {
      id: "created_date",
      name: "created_date",
      title: "Sana",
      checked: true
    },
    {
      id: "source",
      name: "source",
      title: "Manba",
      checked: true
    },
    {
      id: "warehouse",
      name: "warehouse",
      title: "Omborxona",
      checked: true
    },
    {
      id: "project",
      name: "project",
      title: "Loyiha",
      checked: true
    },
    {
      id: "income_expense_amount",
      name: "income_expense_amount",
      title: "Birlik narx",
      checked: false
    },
    {
      id: "income_expense_total_amount",
      name: "income_expense_total_amount",
      title: "Umumiy narx",
      checked: false
    },
    {
      id: "income_expense_total_amount_in_warehouse",
      name: "income_expense_total_amount_in_warehouse",
      title: "Skladdagi summasi",
      checked: false
    },
    {
      id: "income_expense_total_quantity_in_warehouse",
      name: "income_expense_total_quantity_in_warehouse",
      title: "Skladdagi soni",
      checked: false
    },
    {
      id: "income_expense_company_person",
      name: "income_expense_company_person",
      title: "Kontragent",
      checked: false
    },
    {
      id: "income_expense_paid_amount",
      name: "income_expense_paid_amount",
      title: "To'langan summa",
      checked: false
    }
  ],
  size: 10
};
