import { UserTableDataModel } from "../../../app/utils/models/user/userTableConfigModel";

export const warehouseOrderTableDefaultData: UserTableDataModel = {
  column: [
    {
      id: "id",
      name: "id",
      title: "Partiya",
      checked: true
    },
    {
      id: "new_delivery_date",
      name: "new_delivery_date",
      title: "Yetkazilish sanasi",
      checked: true
    },
    {
      id: "recieved_date",
      name: "recieved_date",
      title: "Yopilgan sanasi",
      checked: false
    },
    {
      id: "new_payment_date",
      name: "new_payment_date",
      title: "To’lov sanasi",
      checked: true
    },
    {
      id: "new_ordered_date",
      name: "new_ordered_date",
      title: "Buyurtma  sanasi",
      checked: false
    },
    {
      id: "agent",
      name: "agent",
      title: "Vositachi",
      checked: true
    },
    {
      id: "supply_counterparts",
      name: "supply_counterparts",
      title: "Kontragentlari",
      checked: true
    },
    {
      id: "total_amount",
      name: "total_amount",
      title: "Umumiy narxi",
      checked: true
    },
    {
      id: "total_expense_amount",
      name: "total_expense_amount",
      title: "Xarajat summa",
      checked: false
    },
    {
      id: "percent",
      name: "percent",
      title: "Jarayon",
      checked: true
    },
    {
      id: "status",
      name: "status",
      title: "Holati",
      checked: true
    },
    {
      id: "payment_status",
      name: "payment_status",
      title: "To'lov holati",
      checked: true
    },
    {
      id: "confirmation_payment",
      name: "confirmation_payment",
      title: "Tasdiqlash",
      checked: true
    }
  ],
  size: 10,
  width_data: []
};
