import React from "react";
import { Form, Modal } from "antd";
import { DataNode } from "antd/es/tree";
import { Tree } from "features/app/components/tree/Tree";
import { isEmptyArr } from "features/app/utils/helpers/isEmptyArr";
import { parseTreeValue } from "features/app/utils/helpers/parseTreeValue";
import { IUnitParsedTreeModel } from "features/app/utils/models/unit/unitParsedTreeModel";
import { IUnitFolders, UnitTreeModel } from "features/app/utils/models/unitTreeModel";
import { useCreateCompanyUnitCreate } from "features/settings/service/mutations";
import { useTranslation } from "react-i18next";

import { useGetUnitTree } from "../../../../app/service/queries";

import styles from "./unitSettings.module.scss";

const { useForm } = Form;

export interface IProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface IFormValues {
  unit_ids: string[];
}

const UnitSettingsModal: React.FC<IProps> = ({ visible, setVisible }) => {
  const [form] = useForm();
  const { data: unit } = useGetUnitTree(visible);
  const { i18n, t } = useTranslation();
  // const [search, setSearch] = useState<string>();

  const unitCreateMutation = useCreateCompanyUnitCreate();

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
  };

  const onOk = () => {
    form.submit();
  };

  const onFinish = (fields: IFormValues) => {
    const unitIds: IUnitParsedTreeModel = {
      unit_ids: []
    };

    const req = {
      ...parseTreeValue<IUnitParsedTreeModel>(fields.unit_ids ?? [], unitIds)
    };
    let reqKey: keyof typeof req;

    for (reqKey in req) {
      if (!(reqKey in req) || (Array.isArray(req[reqKey]) && isEmptyArr(req[reqKey] as Array<unknown>))) {
        delete req[reqKey];
      }
    }

    if (isEmptyArr(fields?.unit_ids) && fields?.unit_ids !== undefined) req.unit_ids = [];

    unitCreateMutation.mutateAsync(req).then(onCancel);
  };

  const unitTreeData: (unit: UnitTreeModel | undefined) => DataNode[] = unit =>
    (unit?.unit_folders ?? [])
      .map(folder => ({
        title: folder.name[i18n.language],
        key: JSON.stringify({
          value: folder.id,
          key: "unit_folders"
        }),
        children: (folder.units ?? []).map(unit => ({
          title: `${unit.name[i18n.language]} (${unit?.symbol[i18n.language]})`,
          key: JSON.stringify({
            value: unit.id,
            key: "unit_ids"
          })
        })),
        isFolder: true
      }))
      .concat(
        (unit?.units ?? []).map(unit => ({
          title: `${unit.name[i18n.language]} (${unit?.symbol[i18n.language]})`,
          key: JSON.stringify({
            value: unit.id,
            key: "unit_ids"
          }),
          children: [],
          isFolder: false
        }))
      );

  const transformUnitFolders = (unitFolders: IUnitFolders[]) =>
    unitFolders.map(folder => ({
      ...folder,
      is_checked: folder.units.every(unit => unit.is_checked),
      units: folder.units.filter(unit => unit.is_checked)
    }));

  const getInitialData = (unit: UnitTreeModel) => {
    const transformedUnitFolders = transformUnitFolders(unit?.unit_folders ?? []);

    const newConstructedUnitFolders = transformedUnitFolders.filter(
      folder => folder.is_checked || folder.units.length > 0
    );

    const filteredUnits = (unit?.units ?? []).filter(unit => unit.is_checked);

    return {
      unit_folders: newConstructedUnitFolders,
      units: filteredUnits
    };
  };

  const initialData = unit ? getInitialData(unit) : { unit_folders: [], units: [] };

  return (
    <Modal
      centered
      onOk={onOk}
      open={visible}
      okText={t("payment.Saqlash")}
      cancelText={t("payment.Yopish")}
      onCancel={onCancel}
      title={t("catalog.Birlik sozlanmasi")}
      className={styles.unit_settings_modal}
      okButtonProps={{
        loading: unitCreateMutation.isLoading
      }}
    >
      <Form form={form} onFinish={onFinish}>
        <Tree
          name="unit_ids"
          formInstance={form}
          title={t("catalog.Barcha o'lchovlar")}
          data={unitTreeData(unit)}
          initialData={unitTreeData(initialData)}
        />
      </Form>
    </Modal>
  );
};

export default UnitSettingsModal;
