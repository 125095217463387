import React from "react";
import BuildingIcon from "features/app/assets/icons/BuildingIcon";
import { notificationTypesTitleValue } from "features/app/utils/constants/notificationTypes";
import { NotificationModel } from "features/app/utils/models/notificationModel";
import GitBranchIcon from "features/supply/assets/icons/GitBranchIcon";
import { useTranslation } from "react-i18next";

import { cx } from "modules/common";

import styles from "../../notification.module.scss";

type Props = {
  record: NotificationModel;
  onNavigatePage: (url: string, record: NotificationModel) => void;
};

const ConfirmationWorkCreatedReceived: React.FC<Props> = ({ record, onNavigatePage }) => {
  const { i18n, t } = useTranslation();

  return (
    <div className={styles.description} onClick={() => onNavigatePage("/impost?tab=work", record)}>
      <h3>{notificationTypesTitleValue[record.type]}</h3>
      <p>
        {record?.user?.full_name} "{record?.task_progress?.quantity}{" "}
        {record?.task_progress?.task?.unit?.symbol[i18n.language]} {t("notification.bajarilgan ishni tasdiqladi")}
      </p>
      <div className="flex flex-col gap-2">
        <div className={styles.products}>
          <span className={cx(styles.bold, "flex items-center gap-2")}>
            <BuildingIcon /> {record?.task_progress?.project?.name}
          </span>
        </div>
        <div className={styles.products}>
          <span className={cx(styles.bold, "flex items-center gap-2")}>
            <GitBranchIcon /> {record?.task_progress?.task?.name}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationWorkCreatedReceived;
