import React from "react";
import { Form, Input } from "antd";
import { useTranslation } from "react-i18next";

import { formRules } from "../../../utils/constants/formRules";

const { Item } = Form;

export interface Props {
  className: string;
  inputClassName: string;
}

const CorporateItem: React.FC<Props> = ({ className, inputClassName }) => {
  const { t } = useTranslation();

  return (
    <div className={className}>
      <Item
        name="name"
        label={t("builder.Korxona nomi")}
        rules={formRules()}
        className={inputClassName}
      >
        <Input placeholder={t("builder.Kiriting..")} />
      </Item>
      <Item
        name="owner"
        label={t("builder.Korxona rahbari")}
        rules={formRules()}
        className={inputClassName}
      >
        <Input placeholder={t("builder.Kiriting..")} />
      </Item>
    </div>
  );
};

export default CorporateItem;
