import React from "react";
import { Button, Modal } from "antd";
import { useTranslation } from "react-i18next";

import { TableDataTypeWidgetKey, useWidget } from "modules/dashboard";

import { ProductMarketPrice } from "components";

import { DrawerAction } from "../../../drawer";

import styles from "./table-content-modal.module.scss";

const TableContentModal = () => {
  const {
    widget,
    tableWidgetModalVisible,
    methods: { setTableWidgetContentModal }
  } = useWidget();
  const { t, i18n } = useTranslation();

  const onCancel = () => {
    setTableWidgetContentModal({
      tableWidgetModalVisible: false,
      widget: undefined,
      widgetProps: null
    });
  };

  const content: {
    [k in keyof TableDataTypeWidgetKey]: React.ReactNode;
  } = {
    product_price_analyze: <ProductMarketPrice />
  };

  return (
    <Modal
      centered
      onCancel={onCancel}
      width="max-content"
      open={tableWidgetModalVisible}
      rootClassName={styles.table_content_modal}
      footer={<Button onClick={onCancel}>{t("tableWidgetModal.Yopish")}</Button>}
      title={
        <div className="flex items-center justify-between">
          {widget?.name[i18n.language]}
          <div className="mr-10">
            <DrawerAction isInTheDrawer />
          </div>
        </div>
      }
    >
      {content[widget?.key as keyof TableDataTypeWidgetKey]}
    </Modal>
  );
};

export default TableContentModal;
