import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProjectModel } from "features/projects/utils/models/projectModel";
import { TaskModel } from "features/projects/utils/models/taskModel";

import { CustomFieldValue } from "modules/common";

import { sliceNames } from "../../features/app/utils/constants/sliceNames";
import { DefectStatus } from "../../features/warehouse/utils/constants/DefectStatus";
import { WarehouseProductModel } from "../../features/warehouse/utils/models/WarehouseProductModel";

interface DefectModalReducerDataModel extends WarehouseProductModel {
  id?: number;
  type?: string;
  confirmation_users?: {
    id: number;
    status: DefectStatus;
    user: {
      id: number;
      image: string;
      full_name: string;
    };
  }[];
  defect_date: string;
  description: string;
  custom_field_values: CustomFieldValue[];
  task:TaskModel;
  project:ProjectModel
}

interface DefectModalReducerModel {
  visible: boolean;
  data?: DefectModalReducerDataModel;
}

const initialState: DefectModalReducerModel = {
  visible: false,
  data: undefined
};

const defectModalSlice = createSlice({
  name: sliceNames.DEFECT_MODAL,
  initialState,
  reducers: {
    setDefectModal: (state, action: PayloadAction<DefectModalReducerModel>) => {
      state.visible = action.payload.visible;
      state.data = action.payload.data;
    }
  }
});

export default defectModalSlice.reducer;
export const defectModalActions = defectModalSlice.actions;
