import React from "react";
import { PieTooltipProps, ResponsivePie } from "@nivo/pie";
import { useTranslation } from "react-i18next";

import { colors, RU } from "modules/common";
import { Radar as RadarType } from "modules/dashboard";

import { WidgetCustomTooltip } from "../tooltip";

type Props = {
  data: RadarType[];
};

const label: Record<string, string> = {
  order: "Partiyalar",
  payment: "Kirim-chiqim",
  work: "Ishlar",
  offer: "Takliflar"
};

export const Radar: React.FC<Props> = ({ data }) => {
  const total = data?.reduce((first, second) => first + second.count, 0);
  const { t } = useTranslation();

  const pieChartData = data?.map(item => {
    if (data[0].count > 0 || data[1].count > 0 || data[2].count > 0 || data[3].count > 0) {
      return {
        id: label[item.type],
        label: label[item.type],
        value: item.count
      };
    }
    return {
      id: label[item.type],
      label: label[item.type],
      value: true
    };
  });

  const customTooltip = (label: string, value: number, color: string) => (
    <div className="flex flex-col gap-4">
      <span className="text-sm font-normal text-gray-300">{t(`dashboard.${label}`)}</span>
      <div
        style={{ borderColor: color }}
        className="flex h-4 items-center border-b-0 border-l-4 border-r-0 border-t-0 border-solid pl-2"
      >
        <span style={{ color }} className="flex h-4 items-center justify-center rounded-3xl bg-white p-2">
          {value} {t("dashboard.ta")}
        </span>
      </div>
    </div>
  );

  return (
    <div className="relative flex h-[97%] w-full flex-col items-center justify-center">
      <div className="h-full w-full">
        <ResponsivePie
          fit={true}
          padAngle={1}
          endAngle={90}
          startAngle={-90}
          cornerRadius={12}
          innerRadius={0.75}
          enableArcLabels={false}
          data={pieChartData || []}
          enableArcLinkLabels={false}
          colors={[colors.PRIMARY_200, colors.PRIMARY_400, colors.PRIMARY_600, colors.PRIMARY_800]}
          tooltip={(props: PieTooltipProps<{ id: string; label: string; value: number | boolean }>) => (
            <WidgetCustomTooltip
              children={customTooltip(
                props.datum.label as string,
                typeof props.datum.value === "boolean" ? 0 : props.datum.value,
                props.datum.color
              )}
            />
          )}
        />
      </div>
      <div className="absolute top-[60%] text-[4rem] font-semibold text-gray-900">
        {total?.toLocaleString(RU)} <span className="font-medium text-gray-400">{t("dashboard.ta")}</span>
      </div>
    </div>
  );
};

export default Radar;
