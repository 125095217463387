import React, { useContext } from "react";
import { cx } from "features/app/utils/helpers/cx";
import { useTranslation } from "react-i18next";

import { WidgetContext } from "modules/dashboard/context";
import { Checked } from "modules/dashboard/forms/widget/checked";
import { Widget, WidgetKeys } from "modules/dashboard/schema/schema";

import * as Icons from "../icons";

import styles from "./drawer.module.scss";

type Props = Omit<Widget, "key"> & {
  isCompany?: boolean;
  widgetKey: WidgetKeys;
};

const cardIcons: Record<string, React.ReactNode> = {
  card: <Icons.CountIcon />,
  bar: <Icons.BarIcon />,
  area: <Icons.BarIcon />,
  histogram: <Icons.HistogramIcon />,
  table: <Icons.TableIcon />,
  gantt: <Icons.GantIcon />,
  table_card: <Icons.TableIcon />,
  radar: <Icons.BarIcon />,
  count: <Icons.CountIcon />,
  line: <Icons.GantIcon />,
  column: <Icons.TableIcon />,
  stream_graph: <Icons.HistogramIcon />,
  calendar: <Icons.CountIcon />
};

const WidgetCard: React.FC<Props> = props => {
  const { i18n, t } = useTranslation();
  const { methods } = useContext(WidgetContext);
  const { name, title, isCompany = false, status, chart_type, recommended, widgetKey } = props;
  const isChecked = isCompany ? status === "active" : false;

  const onOpenActionModal = () => {
    if (isCompany) {
      methods.setWidgetModal({
        isUpdateWidget: true,
        visibleWidgetModal: true,
        widget: { ...props, key: widgetKey }
      });
    }
  };

  return (
    <div
      className={cx(styles.card, {
        "cursor-pointer": isCompany
      })}
    >
      <div className="flex flex-col gap-4 px-3 pb-4 pt-3">
        <div className="flex items-center justify-between">
          <div className="flex h-12 w-12 items-center justify-center rounded-full bg-primary-50">
            {cardIcons[chart_type]}
          </div>
          {!recommended && (
            <div className={styles.edit_icon_container} onClick={onOpenActionModal}>
              <Icons.EditIcon />
            </div>
          )}
        </div>
        <div className="flex flex-col gap-1">
          <h4 className="m-0 text-base font-semibold text-gray-700">{name[i18n.language]}</h4>
          <p className="m-0 text-sm font-medium text-gray-500">{title[i18n.language]}</p>
        </div>  
      </div>
      <div className="flex items-center justify-between bg-gray-200 p-3">
        <div className={cx({ [styles.active_tag]: isChecked }, { [styles.passive_tag]: !isChecked })}>
          {isChecked ? t("dashboard.FAOLLASHGAN") : t("dashboard.FAOLLASHTIRISH")}
        </div>
        <Checked key={widgetKey} {...props} status={isCompany ? status : "passive"} />
      </div>
    </div>
  );
};

export default WidgetCard;
