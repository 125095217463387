import React, { useEffect, useState } from "react";
import { Badge, Dropdown, Spin, Tabs } from "antd";
import classNames from "classnames";
import TransferHistoryModal from "features/app/components/transfer-history-modal/TransferHistoryModal";
import IncomeExpenseView from "features/payment/components/bottom/income-expense-view/IncomeExpenseView";
import DefectView from "features/warehouse/components/defect/defect-view/DefectView";
import LaboratoryDrawer from "features/warehouse/components/labaratory/laboratory-drawer/LaboratoryDrawer";
import TransferView from "features/warehouse/components/transfers/transfer-view/TransferView";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";

import { RootState } from "store/index";

import { PartyView } from "pages/index";

import BellIcon from "../../assets/icons/BellIcon";
import CheckAllIcon from "../../assets/icons/CheckAllIcon";
import CloseIcon from "../../assets/icons/CloseIcon";
import { useCreateDeviceToken, useReadAllNotifications } from "../../service/mutation";
import { useGetNotifications } from "../../service/queries";
import { useNotificationSubscribe } from "../../service/websocket";
import { notificationsTabKeys } from "../../utils/constants/notificationsTabKeys";
import Chat from "../chat/Chat";
import { LoadingIndicator } from "../loading-indicator/LoadingIndicator";

import { sendRequest } from "./firebase/firebase";
import NotificationsContent from "./notifications-content/NotificationsContent";

import styles from "./notification.module.scss";

const Notification: React.FC = () => {
  const { t } = useTranslation();
  const [token, setToken] = useState("");
  const [open, setOpen] = useState(false);
  const [tabKey, setTabKey] = useState<notificationsTabKeys>(notificationsTabKeys.UNREAD);
  const { data: notifications, isLoading, isFetching } = useGetNotifications(notificationsTabKeys.UNREAD);

  const { visible: partyVisible } = useAppSelector(state => state.partyReducer.view);
  const { visible: incomeOrExpenseVisible } = useAppSelector(state => state.paymentReducer?.incomeOrExpenseView);
  const { visible: transferVisible } = useAppSelector(state => state.transferReducer.view);
  const { visible: warehouseDefectVisible } = useAppSelector((state: RootState) => state.defectViewReducer);
  const transferHistoryVisible = useAppSelector(state => state.paymentReducer?.transferHistoryModal);
  const [isInventoryOpen, setIsInventoryOpen] = useState(false);

  useNotificationSubscribe();
  const readAllNotifications = useReadAllNotifications();
  const createDeviceToken = useCreateDeviceToken();

  const onChangeTabs = (key: string) => {
    setTabKey(key as notificationsTabKeys);
  };

  const onReadAllNotifications = () => {
    readAllNotifications.mutate();
  };

  const onClose = () => {
    setOpen(false);
  };

  const onOpen = () => {
    setOpen(true);
  };

  const items = [
    {
      key: notificationsTabKeys.UNREAD,
      label: t("notification.O‘qilmagan"),
      children: (
        <NotificationsContent
          setOpen={setOpen}
          type={notificationsTabKeys.UNREAD}
          isInventoryOpen={isInventoryOpen}
          setIsInventoryOpen={setIsInventoryOpen}
        />
      )
    },
    {
      key: notificationsTabKeys.READ,
      label: t("notification.O‘qilgan"),
      children: (
        <NotificationsContent
          setOpen={setOpen}
          type={notificationsTabKeys.READ}
          isInventoryOpen={isInventoryOpen}
          setIsInventoryOpen={setIsInventoryOpen}
        />
      )
    },
    {
      key: notificationsTabKeys.ALL,
      label: t("notification.Barchasi"),
      children: (
        <NotificationsContent
          setOpen={setOpen}
          type={notificationsTabKeys.ALL}
          isInventoryOpen={isInventoryOpen}
          setIsInventoryOpen={setIsInventoryOpen}
        />
      )
    }
  ];

  const customDropdown = () => (
    <Spin spinning={isLoading || readAllNotifications.isLoading || isFetching} indicator={LoadingIndicator}>
      <div className={styles.content}>
        <div className={styles.title}>
          <h3>{t("login.Bildirishnoma")}</h3>
          <div className={styles.actions}>
            {tabKey !== notificationsTabKeys.READ && (
              <div className={styles.actions__icon} onClick={onReadAllNotifications}>
                <CheckAllIcon />
              </div>
            )}
            <div onClick={onClose} className={styles.actions__close}>
              <CloseIcon />
            </div>
          </div>
        </div>
        <Tabs items={items} accessKey={tabKey} onChange={onChangeTabs} />
      </div>
    </Spin>
  );

  useEffect(() => {
    sendRequest(setToken);
    if (token) {
      createDeviceToken.mutate({ device_token: token });
    }
  }, [token]);

  return (
    <>
      <Dropdown
        open={open}
        dropdownRender={customDropdown}
        trigger={["click"]}
        overlayClassName={styles.notifications_dropdown}
        onOpenChange={e =>
          open &&
          (partyVisible ||
            incomeOrExpenseVisible ||
            transferVisible ||
            warehouseDefectVisible ||
            isInventoryOpen ||
            transferHistoryVisible)
            ? setOpen(true)
            : setOpen(e.valueOf())
        }
      >
        <div className={styles.dropdown_button}>
          <div
            onClick={onOpen}
            className={classNames(styles.click_icon, {
              [styles.click_icon__opened]: open
            })}
          >
            <BellIcon />
          </div>
          <Badge count={notifications?.pages[0]?.unviewed_notifications_count as number} overflowCount={9} />
        </div>
      </Dropdown>
      <Chat />
      <PartyView />
      <IncomeExpenseView />
      <TransferView />
      <LaboratoryDrawer />
      <DefectView />
      <TransferHistoryModal />
    </>
  );
};

export default Notification;
