import { useTranslation } from "react-i18next";

import { notificationTypesTitleValue } from "../../../../utils/constants/notificationTypes";
import { NotificationModel } from "../../../../utils/models/notificationModel";

import styles from "../../notification.module.scss";

type Props = {
  record: NotificationModel;
  onNavigatePage: (url: string, record: NotificationModel) => void;
};

const InventoryRequestRecieved = ({ record, onNavigatePage }: Props) => {
  const { t } = useTranslation();

  return (
    <div onClick={() => onNavigatePage("/inventory?tab=requests", record)} className={styles.description}>
      <h3>{notificationTypesTitleValue[record.type]}</h3>
      <p>
        {record?.user?.full_name} {record?.inventory_request?.project?.name} {t("notification.loyihasi uchun")}{" "}
        {record?.inventory_request?.inventory?.code} {t("notification.raqamli")}{" "}
        {record?.inventory_request?.inventory?.name} {record?.inventory_request?.due_date}
        {t("notification.yilgacha tasdiqladi.")}
      </p>
    </div>
  );
};

export default InventoryRequestRecieved;
