import { z } from "zod";

import { projectSchema } from "modules/party/estimate";

import {
  columnsSchema,
  currencySchema,
  customFieldValueSchema,
  idAndNameSchema,
  productSchema,
  unitSchema,
  userSchema
} from "../../common";

// ENUMS
export const statusSchema = z.enum([
  "active",
  "passive",
  "cancel",
  "pending",
  "open",
  "ordered",
  "partially_received",
  "recieved",
  "rejected"
]);

export const typeSchema = z.enum([
  "income",
  "expense",
  "transfer",
  "exchange",
  "monthly",
  "yearly",
  "daily",
  "debtor",
  "creditor",
  "supplier",
  "static",
  "dynamic",
  "none",
  "normal",
  "order",
  "payment",
  "total",
  "work",
  "offer"
]);

export const chartTypeSchema = z.enum([
  "card",
  "range",
  "bar",
  "area",
  "histogram",
  "table",
  "gantt",
  "table_card",
  "radar",
  "count",
  "column",
  "stream_graph",
  "line",
  "calendar",
  "progress_bar"
]);

export const widgetTypeSchema = z.enum([
  "task",
  "order",
  "payment",
  "project",
  "section",
  "inventory",
  "task_product",
  "task_progress",
  "company_person",
  "inventory_defect",
  "warehouse_defect",
  "warehouse_product",
  "warehouse_transfer",
  "confirmation_payment",
  "warehouse_product_offer"
]);

export const widgetKeys = z.enum([
  "cash_status",
  "last_payment",
  "company_person_balance",
  "active_company_person",
  "delivery_effective",
  "delivery_not_effective",
  "confirmation_payment_count",
  "warehouse_defect",
  "project_by_section",
  "project_by_resource",
  "project_activity",
  "project_per_area_price",
  "project_sale_price",
  "expense_payment",
  "top_company_person",
  "product_price_analyze",
  "product_market_price_analyze",
  "expense_payment_by_month",
  "project_by_custom_field",
  "product_price_analyze",
  "product_market_price_analyze",
  "warehouse_amount",
  "vat_balance",
  "project_custom_field_price"
]);

// ENUM VALUES
export const widgetKeysValues = widgetKeys.enum;

export const chartTypeValues = chartTypeSchema.enum;

export const widgetTypeValues = widgetTypeSchema.enum;

export const typeValues = typeSchema.enum;

// GENERAL
export const widgetSettingsSchema = z.object({
  project_ids: z.array(z.number()).optional(),
  user_ids: z.array(z.number()).optional(),
  special_date: z.string().or(z.array(z.string())).optional().nullable(),
  limit: z.number().optional().nullable(),
  payment_type_ids: z.array(z.number()).optional(),
  cash_ids: z.array(z.number()).optional(),
  type: z.string().optional().nullable(),
  currency_ids: z.array(z.number()).optional(),
  min_date: z.string().optional().nullable(),
  max_date: z.string().optional().nullable(),
  project_id: z.number().optional().nullable(),
  task_custom_field_id: z.number().optional().nullable(),
  section_custom_field_id: z.number().optional().nullable(),
  custom_field_id: z.number().optional().nullable(),
  section_ids: z.array(z.number()).optional().nullable(),
  product_id: z.number().optional().nullable(),
  unit_id: z.number().optional().nullable(),
  warehouse_ids: z.array(z.number()).optional().nullable(),
  statuses: z.array(z.string()).optional().nullable(),
  group_by_custom_field_id: z.number().optional().nullable()
});

export const widgetSchema = z.object({
  id: z.number(),
  key: widgetKeys,
  status: statusSchema,
  type: widgetTypeSchema,
  chart_type: chartTypeSchema,
  recommended: z.boolean().optional(),
  name: z.record(z.string(), z.string()),
  title: z.record(z.string(), z.string()),
  users: z.array(userSchema).optional(),
  props: z.string().nullable(),
  filter: widgetSettingsSchema.optional().nullable()
});

export const widgetViewSchema = z.object({
  name: z.string(),
  type: widgetTypeSchema,
  widgets: z.array(widgetSchema)
});

export const companyWidgetSchema = widgetSchema.merge(
  z.object({
    data: z.any()
  })
);

export const oneCompanyWidgetSchema = z.object({
  id: z.number(),
  name: z.record(z.string(), z.string()),
  data: z.array(z.any())
});

export const dateOptionsSchema = z.object({
  label: z.string(),
  value: z.string(),
  props: z.object({
    name: z.string()
  }),
  onClick: z.function().optional()
});

export const staticWidgetSchema = z.object({
  labels: z.array(z.string()),
  isNotDrawer: z.boolean().optional(),
  isNotSpecialDatPicker: z.boolean().optional(),
  isNotEmpty: z.boolean().optional(),
  dateOptions: z.array(dateOptionsSchema).optional(),
  settings: z.object({
    isNotDate: z.boolean().optional(),
    isNotLimit: z.boolean().optional(),
    isNotProject: z.boolean().optional(),
    isRequiredProject: z.boolean().optional(),
    isCompanyPersonType: z.boolean().optional(),
    isNotMultipleProjectSelect: z.boolean().optional(),
    isPartyStatuses: z.boolean().optional(),
    isFloor: z.boolean().optional(),
    form: z.array(
      z.object({
        label: z.string(),
        name: z.string(),
        queryKey: z.string(),
        endPoint: z.string(),
        isNotMultiple: z.boolean().optional(),
        forDisabled: z
          .object({
            fieldKey: z.string(),
            requestKey: z.string()
          })
          .optional()
      })
    ),
    segments: z
      .array(
        z.object({
          title: z.string(),
          value: z.string(),
          select: z.object({
            name: z.string(),
            queryKey: z.string(),
            endPoint: z.string(),
            isNotMultiple: z.boolean().optional()
          })
        })
      )
      .optional()
  }),
  columns: z.array(columnsSchema).optional(),
  isSimplePercent: z.boolean().optional()
});

// WIDGETS
export const cardSchema = z.object({
  difference_percentage: z.number().optional(),
  total: z.number(),
  total_expense: z.number(),
  total_income: z.number(),
  currency: currencySchema.optional(),
  max_date: z.string().nullable(),
  min_date: z.string().nullable(),
  total_min_date: z.number().nullable()
});

export const radarSchema = z.object({
  count: z.number(),
  type: typeSchema
});

export const calendarSchema = z.object({
  date: z.string(),
  count: z.number()
});

export const countSchema = z.object({
  total: z.number(),
  percent: z.number().optional(),
  currency: currencySchema.optional(),
  custom_field_options: z
    .array(
      z.object({
        id: z.number(),
        name: z.record(z.string(), z.string()),
        color: z.string(),
        per_area_percent: z.number().optional(),
        per_area_prediction_price: z.number().optional(),
        per_area_price: z.number().optional()
      })
    )
    .optional()
});

export const barSchema = z.object({
  total: z.number(),
  percent: z.number(),
  currency: currencySchema.optional(),
  values: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
      total: z.number(),
      color: z.string()
    })
  )
});

export const histogramSchema = z.object({
  id: z.number(),
  name: z.string(),
  total: z.number(),
  currency: currencySchema.optional()
});

export const lineValueSchema = z.object({
  id: z.number(),
  name: z.string(),
  total: z.number(),
  color: z.string()
});

export const lineDataSchema = z.object({
  date: z.string(),
  values: z.array(lineValueSchema)
});

export const lineSchema = z.object({
  currency: currencySchema.optional(),
  data: z.array(lineDataSchema)
});

export const columnSchema = z.object({
  currency: currencySchema.optional(),
  percent: z.number(),
  prediction_total: z.number(),
  data: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
      total: z.number(),
      process: z.number().optional(),
      prediction_total: z.number(),
      spend_total: z.number(),
      color: z.string().optional()
    })
  ),
  spend_total: z.number(),
  total: z.number()
});

export const progressBarDataSchema = z.object({
  id: z.number(),
  name: z.string(),
  prediction_amount: z.number(),
  process: z.number(),
  spend_amount: z.number(),
  total_amount: z.number(),
  currency: currencySchema,
  custom_field_options: z
    .array(
      z.object({
        id: z.number(),
        name: z.record(z.string(), z.string()),
        color: z.string(),
        process: z.number().optional(),
        total_amount: z.number().optional(),
        spend_amount: z.number().optional(),
        prediction_amount: z.number().optional()
      })
    )
    .optional()
});

export type Card = z.infer<typeof cardSchema>;
export type Radar = z.infer<typeof radarSchema>;
export type Calendar = z.infer<typeof calendarSchema>;
export type Bar = z.infer<typeof barSchema>;
export type Histogram = z.infer<typeof histogramSchema>;
export type Column = z.infer<typeof columnSchema>;
export type Line = z.infer<typeof lineSchema>;
export type LineData = z.infer<typeof lineDataSchema>;
export type Count = z.infer<typeof countSchema>;
export type DateOptions = z.infer<typeof dateOptionsSchema>;
export type ProgressBarDataType = z.infer<typeof progressBarDataSchema>;

export const paymentCardDataSchema = z.object({
  id: z.number(),
  name: z.string(),
  projects: z.array(idAndNameSchema),
  data: z.object({
    total_amount: z.number(),
    total_income_amount: z.number(),
    total_expense_amount: z.number(),
    difference_percentage: z.number(),
    min_date: z.string().optional().nullable(),
    max_date: z.string().optional().nullable(),
    total_min_date: z.number().nullable().optional()
  })
});

export const confirmationPaymentSchema = z.object({
  id: z.number(),
  amount: z.number(),
  cash: idAndNameSchema,
  company_person: z
    .object({
      id: z.number(),
      person: idAndNameSchema
    })
    .nullable(),
  creator: userSchema,
  date: z.string(),
  description: z.string().nullable(),
  financial: idAndNameSchema,
  payment_type: z.object({
    id: z.number(),
    color: z.string(),
    name: z.string()
  }),
  currency: currencySchema,
  project: idAndNameSchema,
  status: statusSchema,
  type: typeSchema,
  user: userSchema.nullable(),
  unread_message_count: z.number()
});

export const confirmationWorkSchema = z.object({
  id: z.number(),
  confirmation_payment: statusSchema,
  place: z.number(),
  completed_date: z.string(),
  unit: unitSchema,
  amount: z.number(),
  debt_amount: z.number(),
  quantity: z.number(),
  currency: currencySchema,
  inventory: z.object({
    id: z.number(),
    name: z.string(),
    code: z.string()
  }),
  company_person: z.object({
    id: z.number(),
    name: z.string(),
    person: idAndNameSchema
  }),
  creator: userSchema,
  task: idAndNameSchema,
  section: z.object({
    id: z.number(),
    name: z.string(),
    place: z.number(),
    project_id: z.number()
  }),
  project: idAndNameSchema,
  unread_message_count: z.number()
});

export const impostWarehouseProductsSchema = z.object({
  id: z.number(),
  product: z.array(productSchema),
  unit: unitSchema,
  warehouse: idAndNameSchema,
  status: statusSchema,
  quantity: z.number(),
  initial_quantity: z.number(),
  expected_quantity: z.number(),
  amount: z.number(),
  total_amount: z.number(),
  project: idAndNameSchema
});

export const confirmationOrderView = z.object({
  id: z.number(),
  warehouse_names: z.string(),
  confirmation_payment: statusSchema,
  ordered_date: z.string(),
  payment_date: z.string(),
  delivery_date: z.string(),
  debt_amount: z.number(),
  amount: z.number(),
  expected_amount: z.number(),
  status: statusSchema,
  company_person: z.object({
    id: z.number(),
    person: idAndNameSchema
  }),
  currency: currencySchema,
  agent: userSchema,
  creator: userSchema,
  warehouse: idAndNameSchema,
  project: idAndNameSchema,
  warehouse_products: z.array(impostWarehouseProductsSchema),
  unread_message_count: z.number(),
  custom_field_values: z.array(customFieldValueSchema)
});

export const productOfferSchema = z.object({
  id: z.number(),
  amount: z.number(),
  company_person: z.object({
    id: z.number(),
    name: z.string(),
    person: idAndNameSchema
  }),
  currency: currencySchema,
  name: z.string(),
  phone: z.string(),
  status: z.string(),
  description: z.string().optional(),
  warehouse_product_id: z.number(),
  rejected_description: z.string().optional()
});

export const relatedOffersSchema = z.object({
  amount: z.number(),
  created_date: z.string(),
  status: z.string(),
  id: z.number(),
  currency: currencySchema
});

export const confirmationOfferView = z.object({
  id: z.number(),
  delivery_date: z.string().optional(),
  product: productSchema.optional(),
  quantity: z.number().optional(),
  unit: unitSchema.optional(),
  creator: userSchema.optional(),
  amount: z.number().optional(),
  warehouse: idAndNameSchema.optional(),
  project: idAndNameSchema.optional(),
  warehouse_product_offers: z.array(productOfferSchema).optional(),
  section: z
    .object({
      id: z.number(),
      name: z.string(),
      place: z.number(),
      project_id: z.number()
    })
    .optional(),
  task: z
    .object({
      id: z.number(),
      name: z.string(),
      place: z.number()
    })
    .optional(),
  unread_message_count: z.number().optional(),
  custom_field_values: z.array(customFieldValueSchema).optional(),
  status: statusSchema.optional(),
  currency: currencySchema.optional(),
  offers: z.array(relatedOffersSchema).optional()
});

export const cashStatusDetailedSchema = z.object({
  id: z.number(),
  name: z.string(),
  projects: z.array(idAndNameSchema),
  payment_types: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
      color: z.string(),
      currencies: z.array(currencySchema)
    })
  ),
  currencies: z.array(currencySchema)
});

export const cashStatusDetailedTableSchema = z.object({
  id: z.number(),
  type: z.string(),
  date: z.string(),
  amount: z.number(),
  status: statusSchema,
  project: idAndNameSchema,
  payment_type: z.object({
    id: z.number(),
    name: z.string(),
    color: z.string()
  }),
  currency: currencySchema,
  user: z.object({
    full_name: z.string(),
    id: z.number()
  }),
  financial: idAndNameSchema,
  company_person: z.object({
    id: z.number(),
    type: z.string(),
    name: z.string(),
    description: z.string().nullable(),
    start_date: z.string(),
    status: statusSchema,
    person: z.object({
      id: z.number(),
      name: z.string(),
      tin: z.string(),
      phone: z.string(),
      address: z.string(),
      longitude: z.number().nullable(),
      latitude: z.number().nullable(),
      district: z.number().nullable(),
      region: z.number().nullable()
    })
  }),
  balances: z.array(
    z.object({
      id: z.number(),
      start_amount: z.number(),
      amount: z.number(),
      start_date: z.string(),
      currency: currencySchema
    })
  )
});

export const warehouseDefectSchema = z.object({
  id: z.number(),
  type: z.string(),
  product: productSchema,
  unit: unitSchema,
  quantity: z.number(),
  confirmation_users: z.array(
    z.object({
      id: z.number(),
      status: statusSchema,
      user: userSchema
    })
  ),
  project: projectSchema,
  amount: z.number().optional(),
  warehouse: z.object({
    id: z.number(),
    name: z.string()
  }),
  status: statusSchema,
  created_at: z.string(),
  defect_date: z.string(),
  custom_field_values:z.array(customFieldValueSchema)
});

export const companyPersonCardSchema = z.object({
  currency_id: z.number(),
  name: z.record(z.string(), z.string()),
  symbol: z.string(),
  icon: z.string().nullable(),
  amount: z.number()
});

export const companyPersonBalanceSchema = z.object({
  id: z.number(),
  description: z.string().nullable(),
  type: typeSchema,
  name: z.string(),
  start_date: z.string(),
  status: statusSchema,
  total_amount: z.number(),
  person: z.object({
    id: z.number(),
    name: z.string(),
    tin: z.string(),
    phone: z.string(),
    address: z.string().nullable()
  }),
  balances: z.array(
    z.object({
      id: z.number(),
      start_amount: z.number(),
      amount: z.number(),
      start_date: z.string(),
      currency: currencySchema
    })
  )
});

export const projectColumnSchema = z.object({
  spend_total_amount: z.number(),
  total_amount: z.number(),
  prediction_total_amount: z.number(),
  currency: currencySchema,
  sections: z.array(
    z.object({
      code: z.string().nullable(),
      expected_total_amount: z.number(),
      id: z.number(),
      name: z.string(),
      place: z.number(),
      plan: z.boolean(),
      prediction_total_amount: z.number(),
      process: z.number(),
      spend_total_amount: z.number(),
      status: z.string().nullable(),
      total_amount: z.string()
    })
  )
});

export const projectColumnProjectSchema = z.object({
  id: z.number(),
  name: z.string(),
  currency: currencySchema,
  task_products_count: z.number()
});

export const projectColumnSectionSchema = z.object({
  id: z.number(),
  name: z.string(),
  place: z.number(),
  plan: z.boolean(),
  status: statusSchema,
  code: z.string().nullable(),
  total_amount: z.number(),
  spend_total_amount: z.number(),
  expected_total_amount: z.number(),
  prediction_total_amount: z.number(),
  process: z.number()
});

export const projectColumnTaskSchema = z.object({
  id: z.number(),
  name: z.string(),
  unit: unitSchema,
  place: z.number(),
  plan: z.boolean(),
  amount: z.number(),
  quantity: z.number(),
  priority: typeSchema,
  status: statusSchema,
  is_title: z.boolean(),
  total_amount: z.number(),
  code: z.string().nullable(),
  spend_total_amount: z.number(),
  spend_total_quantity: z.number(),
  start_date: z.string().nullable(),
  expected_total_amount: z.number(),
  prediction_total_amount: z.number()
});

export const projectColumnProductSchema = z.object({
  id: z.number(),
  place: z.number(),
  plan: z.boolean(),
  priority: typeSchema,
  status: statusSchema,
  amount: z.number(),
  quantity: z.number(),
  ordered_total_quantity: z.number(),
  spend_total_quantity: z.number(),
  code: z.string().nullable(),
  total_amount: z.number(),
  spend_total_amount: z.number(),
  expected_total_amount: z.number(),
  prediction_total_amount: z.number(),
  product: productSchema,
  unit: unitSchema
});

export const estimateStatusEnumSchema = z.enum(["open", "process", "completed", "closed"]);

export const widgetPropsUpdateSchema = z.object({
  id: z.number(),
  props: z.string()
});

export const ganttSchema = z.object({
  data: z.array(
    z.object({
      id: z.number(),
      value: z.number(),
      date: z.number(),
      status: statusSchema,
      amount: z.number(),
      currency: currencySchema,
      quantity: z.number(),
      order_id: z.number()?.optional(),
      unit: unitSchema,
      company_person: z
        .object({
          id: z.number(),
          type: typeSchema,
          name: z.string(),
          description: z.string().nullable(),
          start_date: z.string(),
          status: statusSchema,
          person: z.object({
            id: z.number(),
            name: z.string(),
            phone: z.string()
          })
        })
        .optional()
    })
  ),
  product: productSchema?.optional(),
  unit: unitSchema?.optional()
});

export const ganttLineSchema = z.array(
  z.object({
    name: z.string(),
    uv: z.number().nullable(),
    pv: z.number().nullable()
  })
);

export const productAllSchema = productSchema.merge(
  z.object({
    price: z.number(),
    status: statusSchema,
    unit: unitSchema,
    total_quantity: z.number()
  })
);

// general and other widget types
export type BaseType = z.infer<typeof typeSchema>;
export type Widget = z.infer<typeof widgetSchema>;
export type ChartType = z.infer<typeof chartTypeSchema>;
export type WidgetView = z.infer<typeof widgetViewSchema>;
export type WidgetType = z.infer<typeof widgetTypeSchema>;
export type WidgetStatus = z.infer<typeof statusSchema>;
export type CompanyWidget = z.infer<typeof companyWidgetSchema>;
export type WidgetSettings = z.infer<typeof widgetSettingsSchema>;
export type WidgetKeys = z.infer<typeof widgetKeys>;
export type StaticWidget = z.infer<typeof staticWidgetSchema>;
export type WidgetSettingsForm = WidgetSettings;

// widget drawer data types
export type PaymentCardData = z.infer<typeof paymentCardDataSchema>;
export type CashStatusDetailed = z.infer<typeof cashStatusDetailedSchema>;
export type CashStatusDetailedTable = z.infer<typeof cashStatusDetailedTableSchema>;
export type OneCompanyWidget = z.infer<typeof oneCompanyWidgetSchema>;
export type ConfirmationPaymentView = z.infer<typeof confirmationPaymentSchema>;
export type ConfirmationWorkView = z.infer<typeof confirmationWorkSchema>;
export type ImpostWarehouseProducts = z.infer<typeof impostWarehouseProductsSchema>;
export type ConfirmationOrderView = z.infer<typeof confirmationOrderView>;
export type ConfirmationOfferView = z.infer<typeof confirmationOfferView>;
export type ProductOffer = z.infer<typeof productOfferSchema>;
export type RelatedOffers = z.infer<typeof relatedOffersSchema>;
export type WarehouseDefect = z.infer<typeof warehouseDefectSchema>;
export type CompanyPersonCard = z.infer<typeof companyPersonCardSchema>;
export type CompanyPersonBalance = z.infer<typeof companyPersonBalanceSchema>;
export type ProjectColumn = z.infer<typeof projectColumnSchema>;
export type ProjectColumnProject = z.infer<typeof projectColumnProjectSchema>;
export type ProjectColumnSection = z.infer<typeof projectColumnSectionSchema>;
export type ProjectColumnTask = z.infer<typeof projectColumnTaskSchema>;
export type ProjectColumnProduct = z.infer<typeof projectColumnProductSchema>;
export type EstimateStatus = z.infer<typeof estimateStatusEnumSchema>;
export type WidgetPropsUpdate = z.infer<typeof widgetPropsUpdateSchema>;
export type Gantt = z.infer<typeof ganttSchema>;
export type ProductAll = z.infer<typeof productAllSchema>;
export type TargetType = EventTarget & {
  scrollTop: number;
  offsetHeight: number;
  scrollHeight: number;
};
export type GanttLine = z.infer<typeof ganttLineSchema>;
