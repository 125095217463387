import React, { useState } from "react";
import { Collapse, DatePicker, Form, FormInstance, Input } from "antd";
import { Rule } from "antd/es/form";
import dayjs from "dayjs";
import DatepickerSuffixIcon from "features/app/assets/icons/DatepickerSuffixIcon";
import CustomFieldContents from "features/app/components/custom-field-contents/CustomFieldContents";
import CustomUpload from "features/app/components/custom-upload/CustomUpload";
import { useGetCustomFieldSelect } from "features/app/service/queries";
import { CustomFieldLocationEnum } from "features/app/utils/enums/customFieldLocationEnum";
import { FileListType } from "features/app/utils/models/fileListModel";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";

import { RootState } from "store";

import { cx, dayjsFormats } from "modules/common";

import { SelectSuffixIcon } from "components";

import styles from "./index.module.scss";

const { Item } = Form;
const { Panel } = Collapse;

type Props = {
  form: FormInstance;
};

const Additional: React.FC<Props> = ({ form }) => {
  const { t } = useTranslation();
  const { data: customFields } = useGetCustomFieldSelect([CustomFieldLocationEnum.WAREHOUSE_DEFECT]);
  const { isVisibleMoreInfo } = useAppSelector(state => state.paymentReducer.income_expense_modal);
  const { data } = useAppSelector((state: RootState) => state.defectModalReducer);
  const [fileList, setFileList] = useState<FileListType[]>([]);

  const dateRule: Rule[] = [
    {
      validator: (_, value) => {
        const diff = dayjs(value).diff(dayjs(), "day");

        if (diff > 0) return Promise.reject();

        return Promise.resolve();
      }
    }
  ];

  return (
    <Collapse
      ghost
      rootClassName={styles.additional}
      defaultActiveKey={isVisibleMoreInfo ? "additional" : undefined}
      expandIcon={({ isActive }) => <SelectSuffixIcon placement={isActive ? "top" : "bottom"} />}
    >
      <Panel
        key="additional"
        header={<div className={styles.more_info__btn}>{t("payment.Qo'shimcha ma'lumotlar")}</div>}
      >
        <div className={styles.more_info}>
          <Item name="defect_date" label={t("warehouse.Yaratilgan sana")} initialValue={dayjs()} rules={dateRule}>
            <DatePicker
              format={dayjsFormats.DATE}
              suffixIcon={<DatepickerSuffixIcon />}
              placeholder={t("payment.Sanani tanlang")}
            />
          </Item>
          <Item name="description" label={t("payment.Izoh")}>
            <Input.TextArea className={cx("textarea")} placeholder={t("payment.Izohini kiriting")} />
          </Item>
          <CustomUpload form={form} fileList={fileList} setFileList={setFileList} isArray name="file_ids" />
          {customFields?.map((item, index) =>
            CustomFieldContents[item.type]({
              form,
              index,
              disabled: false,
              customField: item,
              isView: !!data?.id,
              visible: isVisibleMoreInfo
            })
          )}
        </div>
      </Panel>
    </Collapse>
  );
};

export default Additional;
