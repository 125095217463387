import React from "react";
import ArrowDownIcon from "features/app/assets/icons/ArrowDownIcon";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

import { cx, numberRound, RU } from "modules/common";
import {
  Count as CountType,
  getConditionalColor,
  getConditionalStyle,
  getIcon,
  makeSvgRotate,
  WidgetKeys,
  widgetStaticData
} from "modules/dashboard";

type Props = {
  data: CountType;
  widgetKey: WidgetKeys;
};

const Count: React.FC<Props> = ({ widgetKey, data }) => {
  const { labels } = widgetStaticData[widgetKey];
  const { t } = useTranslation();

  return (
    <div className="flex h-full flex-col gap-6">
      <div className="flex h-full flex-col gap-2">
        <div className="flex items-end gap-1">
          <span
            className={cx("text-5xl font-medium text-error-500", { "text-gray-700": widgetKey !== "warehouse_defect" })}
          >
            {(data?.total || 0).toLocaleString(RU)}
          </span>
          <span className="text-3xl font-medium text-gray-500">{data?.currency?.symbol}</span>
        </div>
        <div className="flex items-center gap-1">
          <span
            className="flex items-center justify-center gap-1 rounded-2xl px-2 py-1"
            style={{
              backgroundColor: getConditionalStyle(data?.percent || 0),
              borderColor: getConditionalStyle(data?.percent || 0)
            }}
          >
            <span>
              {getIcon(
                (data?.percent as unknown as number) || 0,
                <ArrowDownIcon
                  width={14}
                  stroke={getConditionalColor(data?.percent || 0)}
                  rotate={makeSvgRotate(data?.percent || 0)}
                />
              )}
            </span>
            <span
              className="text-sm font-medium"
              style={{
                color: getConditionalColor(data?.percent || 0) || "black"
              }}
            >
              {numberRound(data?.percent || 0)}%
            </span>
          </span>
          <span className="text-sm font-normal text-gray-400">{t(`dashboard.${labels[0]}`)}</span>
        </div>
      </div>
      {data?.custom_field_options && data?.custom_field_options?.length > 0 && (
        <div className="flex max-h-[156px] min-h-[156px] flex-col gap-3 overflow-y-auto">
          {data?.custom_field_options?.map(item => (
            <div key={item?.id} className="flex items-center justify-between rounded-xl bg-gray-50 px-3 py-2">
              <div className="flex items-center gap-2">
                <div
                  className={cx("h-4 w-4 rounded-[0.25rem]")}
                  style={{
                    backgroundColor: item?.color || "gray"
                  }}
                />
                <span className="text-base font-medium text-gray-700">
                  {item?.name?.[i18next.language]?.toUpperCase()}
                </span>
                <span
                  className="flex items-center justify-center gap-1 rounded-2xl px-2 py-1"
                  style={{
                    backgroundColor: getConditionalStyle(item?.per_area_percent || 0),
                    borderColor: getConditionalStyle(item?.per_area_percent || 0)
                  }}
                >
                  <span>
                    {getIcon(
                      (item?.per_area_percent as unknown as number) || 0,
                      <ArrowDownIcon
                        width={14}
                        stroke={getConditionalColor(item?.per_area_percent || 0)}
                        rotate={makeSvgRotate(item?.per_area_percent || 0)}
                      />
                    )}
                  </span>
                  <span
                    className="text-sm font-medium"
                    style={{
                      color: getConditionalColor(item?.per_area_percent || 0) || "black"
                    }}
                  >
                    {numberRound(item?.per_area_percent || 0)}%
                  </span>
                </span>
              </div>
              <div className="flex items-center gap-1">
                <span className="text-[1.125rem] font-semibold leading-7 text-[var(--gray-700)]">
                  {(item?.per_area_prediction_price || 0)?.toLocaleString(RU)}
                </span>
                <span className="text-sm font-medium text-gray-400">{data?.currency?.symbol}</span>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Count;
