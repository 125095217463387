import { useQuery } from "@tanstack/react-query";

import { useQueryParams } from "../../../hooks/useQueryParams";
import { queryParamsKeys } from "../../app/utils/constants/queryParamsKeys";
import $api from "../../app/utils/helpers/axiosInstance";
import { PaginationType } from "../../app/utils/models/PaginationType";
import { SuccessRes } from "../../app/utils/models/responseType";
import { settingsEndPoints } from "../utils/constants/settingsEndPoints";
import { settingsQueryNames } from "../utils/constants/settingsQueryNames";
import { AdminModel } from "../utils/models/admin/adminModel";
import { CashViewModel } from "../utils/models/cash/cashViewModel";
import { DetailedCashFolderModel } from "../utils/models/cash/detailedCashFolderModel";
import { DetailedCashModel } from "../utils/models/cash/detailedCashModel";
import { CategoryModel } from "../utils/models/category/CategoryModel";
import { CurrenciesRes } from "../utils/models/currency/currenciesRes";
import { CustomFieldModel } from "../utils/models/custom-field/customFieldModel";
import { DetailedWarehouseModel } from "../utils/models/detailed-warehouse/detailedWarehouseModel";
import { ActivityTypeModel, FinancialCategoryModel, FinancialModel, VatModel } from "../utils/models/financialModel";
import { OnePaymentTypeModel } from "../utils/models/payment-type/onePaymentTypeModel";
import { PaymentTypeModel } from "../utils/models/payment-type/paymentTypeModel";
import { PaymentTypeSelectModel } from "../utils/models/payment-type/paymentTypeSelectModel";
import { ProductModel } from "../utils/models/product/productModel";
import { CategoryDevModel } from "../utils/models/product-dev/categoryDevModel";
import { ProductDevModel } from "../utils/models/product-dev/productDevModel";
import { SubCategoryDevModel } from "../utils/models/product-dev/subCategoryDevModel";
import { ProjectTreeModel } from "../utils/models/project/projectTreeModel";
import { OneRoleModel } from "../utils/models/roles/oneRoleModel";
import { PermissionModel } from "../utils/models/roles/permissionModel";
import { RoleModel } from "../utils/models/roles/roleModel";
import { SubCategoryModel } from "../utils/models/sub-category/subCategoryModel";
import { TemplateModel } from "../utils/models/template/templateModel";
import { OneWarehouseModel } from "../utils/models/warehouse/oneWarehouseModel";
import { WarehouseResModel } from "../utils/models/warehouse/warehouseResModel";
import { User } from "modules/common";

export function useGetDetailedCashFolder(id: string) {
  return useQuery<DetailedCashFolderModel>(
    ["detailed-cash-folder", id],
    async () => {
      const res = await $api.get(`cash-folder/view?id=${id}`);

      return res.data.data;
    },
    {
      enabled: !!id
    }
  );
}

export function useGetPaymentTypeSelect(argument?: { enabled?: boolean; cash_id?: number }) {
  let url = "payment-type/select";

  if (argument?.cash_id) {
    url += `?cash_id=${argument.cash_id}`;
  }

  return useQuery<PaymentTypeSelectModel[]>(
    ["payment-type-select", argument?.cash_id],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      enabled: argument?.enabled
    }
  );
}

export function useGetCash() {
  return useQuery<CashViewModel>(["cash"], async () => {
    const res = await $api.get("cash/view");

    return res.data.data;
  });
}

export function useGetOneCash(id?: number) {
  return useQuery<DetailedCashModel>(
    ["one-cash", id],
    async () => {
      const res = await $api.get(`cash/view?id=${id}`);

      return res.data.data;
    },
    {
      enabled: !!id
    }
  );
}

export function useGetOnePaymentType(id?: number) {
  return useQuery<OnePaymentTypeModel>(
    ["one-payment-type", id],
    async () => {
      const res = await $api.get(`${settingsEndPoints.PAYMENT_TYPES}?id=${id}`);

      return res.data.data;
    },
    {
      enabled: !!id
    }
  );
}

export function useGetPermissions(enabled?: boolean) {
  return useQuery<PermissionModel>(
    ["permissons", enabled],
    async () => {
      const res = await $api.get("role/permission");

      return res.data.data;
    },
    {
      enabled: enabled ?? true
    }
  );
}

export function useGetOneRole(id?: number) {
  return useQuery<OneRoleModel>(
    ["one-role", id],
    async () => {
      const res = await $api.get(`role/view?id=${id}`);

      return res.data.data;
    },
    {
      enabled: !!id
    }
  );
}

export function useGetEmployees() {
  let url = settingsEndPoints.ADMINS;
  const { reqQueryParam, searchParams } = useQueryParams();

  if (searchParams) {
    url += `?${reqQueryParam(queryParamsKeys.PAGE)}`;
  }

  return useQuery<SuccessRes<PaginationType<AdminModel[]>>>([settingsQueryNames.ADMIN_VIEW, searchParams], async () => {
    const res = await $api.get(url);

    return res.data;
  });
}

export function useGetAllRoles() {
  return useQuery<SuccessRes<RoleModel[]>>(
    [settingsQueryNames.ROLE_SELECT],
    async () => {
      const res = await $api.get(settingsEndPoints.ROLES_SELECT);

      return res.data;
    },
    {
      select(data) {
        const { data: childData, message } = { ...data };

        return {
          data: childData?.filter(item => item?.name !== "UYQUR_COMPANY_SUPER_ADMIN"),
          message
        };
      }
    }
  );
}

export function useGetRoles() {
  return useQuery<SuccessRes<PaginationType<RoleModel[]>>>(
    [settingsQueryNames.ROLE_VIEW],
    async () => {
      const res = await $api.get(settingsEndPoints.ROLES);

      return res.data;
    },
    {
      staleTime: Infinity
    }
  );
}

export function useGetFinancial(enabled?: boolean) {
  return useQuery<SuccessRes<FinancialModel>>(
    [settingsQueryNames.FINANCIAL_VIEW],
    async () => {
      const res = await $api.get(settingsEndPoints.FINANCIAL);

      return res.data;
    },
    {
      staleTime: Infinity,
      enabled: enabled ?? true
    }
  );
}

export function useGetFinancialCategory(type?: string) {
  return useQuery<SuccessRes<FinancialCategoryModel[]>>(
    [settingsQueryNames.FINANCIAL_CATEGORY, type],
    async () => {
      const res = await $api.get(`${settingsEndPoints.FINANCIAL_CATEGORY}?${queryParamsKeys.TYPE}=${type}`);

      return res.data;
    },
    {
      staleTime: Infinity,
      enabled: !!type
    }
  );
}

export function useGetActivityType(type?: string) {
  return useQuery<SuccessRes<ActivityTypeModel[]>>(
    [settingsQueryNames.ACTIVITY_TYPE, type],
    async () => {
      const res = await $api.get(`${settingsEndPoints.ACTIVITY_TYPE}?${queryParamsKeys.TYPE}=${type}`);

      return res.data;
    },
    {
      staleTime: Infinity,
      enabled: !!type
    }
  );
}

export function useGetCurrencies(enabled?: boolean) {
  return useQuery<CurrenciesRes>(
    [settingsQueryNames.CURRENCIES_VIEW],
    async () => {
      const res = await $api.get(settingsEndPoints.CURRENCIES);

      return res.data.data;
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: enabled ?? true
    }
  );
}

// export function useGetCurrenciesList() {
//   return useQuery<CurrencyModel[]>([settingsQueryNames.CURRENCIES_LIST], async () => {
//     const res = await $api.get(settingsEndPoints.CURRENCIES_LIST);
//
//     return res.data.data;
//   });
// }

export function useGetPaymentTypes() {
  return useQuery<PaymentTypeModel[]>([settingsQueryNames.PAYMENT_TYPE_VIEW], async () => {
    const res = await $api.get(settingsEndPoints.PAYMENT_TYPES);

    return res.data.data;
  });
}

export function useGetWarehouses() {
  return useQuery<WarehouseResModel>([settingsQueryNames.WAREHOUSES_VIEW], async () => {
    const res = await $api.get(settingsEndPoints.WAREHOUSES);

    return res.data.data;
  });
}

export function useGetProjectTree(enabled: boolean) {
  return useQuery<ProjectTreeModel>(
    [settingsQueryNames.PROJECT_TREE],
    async () => {
      const res = await $api.get(settingsEndPoints.PROJECT_TREE);

      return res.data.data;
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled
    }
  );
}

export function useGetOneWarehouse(id?: number) {
  return useQuery<OneWarehouseModel>(
    [settingsQueryNames.ONE_WAREHOUSE],
    async () => {
      const res = await $api.get(`${settingsEndPoints.WAREHOUSES}?id=${id}`);

      return res.data.data;
    },
    {
      enabled: !!id
    }
  );
}

export function useGetDetailedWarehouseFolder(id: string) {
  return useQuery<DetailedWarehouseModel>([settingsQueryNames.DETAILED_WAREHOUSE_FOLDER, id], async () => {
    const res = await $api.get(`${settingsEndPoints.WAREHOUSE_FOLDER}?id=${id}`);

    return res.data.data;
  });
}

export function useGetCategories(enabled?: boolean) {
  const { searchParams, reqQueryParam } = useQueryParams();
  let url = settingsEndPoints.CATEGORIES;

  if (searchParams) {
    url += `?${reqQueryParam(queryParamsKeys.SEARCH)}`;
  }

  return useQuery<CategoryModel[]>(
    [settingsQueryNames.CATEGORY_VIEW, searchParams],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      enabled
    }
  );
}

export function useGetSubCategories(category_id?: number) {
  const { reqQueryParam, severalSearchParams } = useQueryParams();
  let url = `${settingsEndPoints.SUB_CATEGORIES}?category_id=${category_id}`;
  const searchParams = severalSearchParams(queryParamsKeys.PAGE);

  if (searchParams) {
    url += `&${reqQueryParam(queryParamsKeys.SEARCH)}`;
  }

  return useQuery<SubCategoryModel[]>(
    [settingsQueryNames.SUB_CATEGORIES_VIEW, category_id, searchParams],
    async () => {
      const res = await $api.get(`${url}`);

      return res.data.data;
    },
    {
      enabled: !!category_id
    }
  );
}

export function useGetCategoryAllProducts(category_id?: number) {
  const { searchParams, reqQueryParam } = useQueryParams();
  let url = `${settingsEndPoints.CATEGORY_PRODUCTS}?category_id=${category_id}`;

  if (searchParams) {
    url += `&${reqQueryParam(queryParamsKeys.SEARCH, queryParamsKeys.PAGE)}`;
  }
  return useQuery<PaginationType<ProductModel[]>>(
    [settingsQueryNames.CATEGORY_PRODUCTS_ALL_VIEW, category_id, searchParams],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      enabled: !!category_id
    }
  );
}

export function useGetCategoryProducts(category_id?: number, page?: number, search?: string, tabKey?: string) {
  let url = `${settingsEndPoints.CATEGORY_PRODUCTS}?category_id=${category_id}`;

  if (page) {
    url += `&page=${page}`;
  }

  if (search) {
    url += `&search=${search}`;
  }

  return useQuery<PaginationType<ProductModel[]>>(
    [settingsQueryNames.CATEGORY_PRODUCTS_VIEW, category_id, page, search],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      enabled: !!category_id && tabKey === "2"
    }
  );
}

export function useGetSubCategoryProducts(sub_category_id?: number, tabKey?: string, searchValue?: string) {
  const { queries, generateSearchParam } = useQueryParams();
  const { search, page } = queries();
  const searchParams = generateSearchParam({
    page,
    search: search || searchValue
  });
  const url = `${settingsEndPoints.SUB_CATEGORY_PRODUCTS}?subcategory_id=${sub_category_id}&${searchParams}`;

  return useQuery<PaginationType<ProductModel[]>>(
    [settingsQueryNames.SUB_CATEGORY_PRODUCTS_VIEW, sub_category_id, searchParams],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      enabled: !!sub_category_id || tabKey === "3"
    }
  );
}

export function useGetSearchProducts(search?: string, tabKey?: string) {
  const url = `${settingsEndPoints.PRODUCTS_SELECT}?search=${search}&paginate=true`;

  return useQuery<PaginationType<ProductModel[]>>(
    [settingsQueryNames.SEARCH_PRODUCTS, search],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      enabled: !!search && tabKey === "1"
    }
  );
}

export function useGetCategoriesDev(enabled?: boolean, search?: string | undefined) {
  let url = settingsEndPoints.CATEGORIES_DEV;

  if (search) {
    url += `?search=${search}`;
  }

  return useQuery<CategoryDevModel[]>(
    [settingsQueryNames.CATEGORIES_DEV_VIEW, search],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      enabled: !!enabled
    }
  );
}

export function useGetSubCategoriesDev(id?: number) {
  return useQuery<SubCategoryDevModel[]>(
    [settingsQueryNames.SUB_CATEGORIES_DEV_VIEW, id],
    async () => {
      const res = await $api.get(`${settingsEndPoints.SUB_CATEGORIES_DEV}?category_id=${id}`);

      return res.data.data;
    },
    {
      enabled: !!id
    }
  );
}

export function useGetSubCategoryProductsDev(id?: number, search?: string | undefined) {
  let url = `${settingsEndPoints.PRODUCTS_DEV}?subcategory_id=${id}`;

  if (search) {
    url += `&search=${search}`;
  }
  return useQuery<ProductDevModel[]>(
    [settingsQueryNames.SUB_CATEGORY_PRODUCTS_DEV_VIEW, id, search],
    async () => {
      const res = await $api.get(`${url}`);

      return res.data.data;
    },
    {
      enabled: !!id
    }
  );
}

export function useGetCategoryProductsDev(id?: number, search?: string | undefined) {
  let url = `${settingsEndPoints.PRODUCTS_DEV}?category_id=${id}`;

  if (search) {
    url += `&search=${search}`;
  }

  return useQuery<ProductDevModel[]>(
    [settingsQueryNames.CATEGORY_PRODUCTS_DEV_VIEW, id, search],
    async () => {
      const res = await $api.get(`${url}`);

      return res.data.data;
    },
    {
      enabled: !!id
    }
  );
}

export function useGetCustomFieldsView() {
  const { reqQueryParam, searchParams } = useQueryParams();
  let url = settingsEndPoints.CUSTOM_FIELDS_VIEW;

  if (searchParams && searchParams?.length > 0) {
    url += `?${reqQueryParam(queryParamsKeys.PAGE)}`;
  }

  return useQuery<PaginationType<CustomFieldModel[]>>([settingsQueryNames.CUSTOM_FIELDS_VIEW, url], async () => {
    const res = await $api.get(url);

    return res.data.data;
  });
}

export function useGetCustomFieldView(id?: number) {
  return useQuery<CustomFieldModel>(
    [settingsQueryNames.CUSTOM_FIELD_VIEW, id],
    async () => {
      const res = await $api.get(`${settingsEndPoints.CUSTOM_FIELDS_VIEW}?id=${id}`);

      return res.data.data;
    },
    {
      enabled: !!id
    }
  );
}

export function useGetTemplatesView() {
  const { reqQueryParam, searchParams } = useQueryParams();
  let url = settingsEndPoints.TEMPLATE_VIEW;

  if (searchParams && searchParams?.length > 0) {
    url += `?${reqQueryParam(queryParamsKeys.PAGE)}`;
  }

  return useQuery<PaginationType<TemplateModel[]>>([settingsQueryNames.TEMPLATE_VIEW, url], async () => {
    const res = await $api.get(url);

    return res.data.data;
  });
}

export function useGetTemplateView(id?: number) {
  return useQuery<TemplateModel>(
    [settingsQueryNames.TEMPLATE_VIEW, id],
    async () => {
      const res = await $api.get(`${settingsEndPoints.TEMPLATE_VIEW}?id=${id}`);

      return res.data.data;
    },
    {
      enabled: !!id
    }
  );
}

export function useGetVatView() {
  return useQuery<VatModel[]>([settingsQueryNames.VAT_VIEW], async () => {
    const { data } = await $api.get("vat/view");

    return data?.data;
  });
}

export function useUserView(enabled: boolean) {
  return useQuery<User & { status: string; phone: string; login: string }>(
    [settingsQueryNames.CURRENT_USER_VIEW],
    async () => {
      const { data } = await $api.get("user/view");

      return data?.data;
    },
    {
      enabled
    }
  );
}
