import React from "react";
import { UseFormReturn } from "react-hook-form";
import { useGetCustomFieldSelect } from "features/app/service/queries";
import { CustomFieldLocationEnum } from "features/app/utils/enums/customFieldLocationEnum";
import { useTranslation } from "react-i18next";

import { WidgetSettingsForm } from "modules/dashboard/schema";

import { Select } from "components";

interface IProps {
  form: UseFormReturn<WidgetSettingsForm>;
}

const FloorCustomField: React.FC<IProps> = ({ form }) => {
  const { i18n, t } = useTranslation();
  const { data } = useGetCustomFieldSelect([CustomFieldLocationEnum.SECTION], true);

  const selectOptions = data?.map(item => ({
    label: item.name?.[i18n.language],
    value: item.id
  }));

  return (
    <Select
      options={selectOptions}
      name="group_by_custom_field_id"
      label={t("dashboard.Qavatlar")}
      control={form.control}
      allowClear
    />
  );
};

export default FloorCustomField;
