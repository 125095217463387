import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { transferActions } from "store/reducers/transferReducer";

import { notificationTypesTitleValue } from "../../../../utils/constants/notificationTypes";
import { NotificationModel } from "../../../../utils/models/notificationModel";

import styles from "../../notification.module.scss";

type Props = {
  record: NotificationModel;
  onNavigatePage: (url: string, record: NotificationModel) => void;
};

const WarehouseTransferRecieved = ({ record, onNavigatePage }: Props) => {
  const dispatch = useDispatch();
  const { setVisibleView } = transferActions;
  const { t } = useTranslation();

  const onOpenTransferView = () => {
    dispatch(
      setVisibleView({
        visible: true,
        data: record?.warehouse_transfer as never
      })
    );
  };

  return (
    <div className={styles.description} onClick={onOpenTransferView}>
      <h3>{notificationTypesTitleValue[record.type]}</h3>
      <p>
        {record?.warehouse_transfer?.recieved_warehouse?.name} {t("notification.sizning")}{" "}
        <span className={styles.name}>T -{record?.warehouse_transfer?.id}</span>{" "}
        {t("notification.raqamli o’tkazmani qabul qildi.")}
      </p>
    </div>
  );
};

export default WarehouseTransferRecieved;
