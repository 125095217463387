import { FormInstance } from "antd";

import { CustomFieldTypesEnum } from "../../utils/enums/customFieldTypesEnum";
import { CustomFieldSelectModel } from "../../utils/models/customFieldSelectModel";

import CustomFieldCheckbox from "./custom-field-checkbox/CustomFieldCheckbox";
import CustomFieldDate from "./custom-field-date/CustomFieldDate";
import CustomFieldFile from "./custom-field-file/CustomFieldFile";
import CustomFieldInput from "./custom-field-input/CustomFieldInput";
import CustomFieldLabel from "./custom-field-label/CustomFieldLabel";
import CustomFieldSelect from "./custom-field-select/CustomFieldSelect";

interface ContentModel {
  customField: CustomFieldSelectModel;
  index: number;
  disabled?: boolean;
  form?: FormInstance;
  visible?: boolean;
  isView?: boolean;
}

const CustomFieldContents = {
  [CustomFieldTypesEnum.DATE]: ({ customField, index, disabled }: ContentModel) => (
    <CustomFieldDate customField={customField} index={index} disabled={disabled} />
  ),
  [CustomFieldTypesEnum.SELECT]: ({ customField, index, disabled }: ContentModel) => (
    <CustomFieldSelect customField={customField} index={index} disabled={disabled} />
  ),
  [CustomFieldTypesEnum.TEXT]: ({ customField, index, disabled }: ContentModel) => (
    <CustomFieldInput customField={customField} index={index} disabled={disabled} />
  ),
  [CustomFieldTypesEnum.FILE]: ({ customField, index, disabled, form, visible, isView }: ContentModel) => (
    <CustomFieldFile
      customField={customField}
      index={index}
      disabled={disabled}
      form={form}
      isView={isView}
      visible={visible}
    />
  ),
  [CustomFieldTypesEnum.CHECKBOX]: ({ customField, index, disabled, form }: ContentModel) => (
    <CustomFieldCheckbox form={form} index={index} disabled={disabled} customField={customField} />
  ),
  [CustomFieldTypesEnum.LABEL]: ({ customField, index, disabled, form }: ContentModel) => (
    <CustomFieldLabel form={form} index={index} disabled={disabled} customField={customField} />
  )
};

export default CustomFieldContents;
