import React from "react";
import { useDispatch } from "react-redux";
import { Dropdown } from "antd";
import { ColumnsType } from "antd/es/table";
import { MenuProps, Table } from "antd/lib";
import { useTranslation } from "react-i18next";

import { builderActions } from "../../../../../store/reducers/builderReducer";
import DeleteIcon from "../../../../app/assets/icons/DeleteIcon";
import DotsVerticalIcon from "../../../../app/assets/icons/DotsVerticalIcon";
import EditIcon from "../../../../app/assets/icons/EditIcon";
import { LoadingIndicator } from "../../../../app/components/loading-indicator/LoadingIndicator";
import ModalConfirm from "../../../../app/components/modal-confirm/ModalConfirm";
import NotUserImage from "../../../../app/components/not-image/NotUserImage";
import Pagination from "../../../../app/components/pagination/Pagination";
import TableEmpty from "../../../../app/components/table-empty/TableEmpty";
import { useDeleteBuilder } from "../../../../app/service/mutation";
import { useGetBuilderView } from "../../../../app/service/queries";
import { colors } from "../../../../app/utils/constants/colors";
import { IBuildersView } from "../../../../app/utils/models/buildersViewModal";

import styles from "./builder.module.scss";

const BuilderTable: React.FC = () => {
  const { t } = useTranslation();
  const { data, isLoading } = useGetBuilderView();
  const deleteBuilder = useDeleteBuilder();

  const dispatch = useDispatch();
  const { setBuilderModal } = builderActions;

  const onOpenModal = (record: IBuildersView) => {
    dispatch(
      setBuilderModal({
        builderModal: {
          visible: true,
          data: record
        }
      })
    );
  };

  const onDelete = (id: number) => () => deleteBuilder.mutateAsync({ id });

  const dropdownItems = (record: IBuildersView): MenuProps["items"] => {
    const newItems = [];

    newItems.push({
      key: "edit",
      label: (
        <div className={styles.menu_item} onClick={() => onOpenModal(record)}>
          <EditIcon /> <span>{t("settings.Tahrirlash")}</span>
        </div>
      ),
      onClick: () => {}
    });
    newItems.push({
      key: "delete",
      onClick: () => {},
      label: (
        <ModalConfirm onOk={onDelete(record.id)} title="Quruvchini o'chirish">
          <div className={styles.menu_item}>
            <DeleteIcon />
            <span>{t("settings.O'chirish")}</span>
          </div>
        </ModalConfirm>
      )
    });
    return newItems;
  };

  const columns: ColumnsType<IBuildersView> = [
    {
      title: `${t("settings.Nomi")}`,
      dataIndex: "name",
      width: "40%"
    },
    {
      title: `${t("settings.Korxona rahbari")}`,
      render: (record: IBuildersView) => (
        <span className={styles.ceo}>
          <NotUserImage name={record?.owner} background={colors.GRAY_100} color={colors.GRAY_600} />
          {record?.owner}
        </span>
      ),
      width: "25%"
    },
    {
      title: `${t("settings.Telefon raqami")}`,
      dataIndex: "phone",
      width: "20%"
    },

    {
      title: `${t("settings.Manzil")}`,
      dataIndex: "address",
      width: "25%"
    },
    {
      title: "",
      render: (record: IBuildersView) => (
        <Dropdown trigger={["click"]} menu={{ items: dropdownItems(record) }}>
          <div className={styles.dots} onClick={e => e.stopPropagation()}>
            <DotsVerticalIcon />
          </div>
        </Dropdown>
      ),
      width: "5%"
    }
  ];

  return (
    <div className={styles.table_container}>
      <Table
        columns={columns}
        dataSource={data?.data}
        pagination={false}
        rowKey={row => row.id}
        className={styles.table}
        locale={{
          emptyText: <TableEmpty />
        }}
        loading={{
          spinning: isLoading || deleteBuilder.isLoading,
          indicator: LoadingIndicator
        }}
      />
      <Pagination
        paginationProps={{
          total: data?.total,
          current: data?.current_page
        }}
      />
    </div>
  );
};

export default BuilderTable;
