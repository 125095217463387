import React from "react";
import { Progress } from "antd";
import ClockCheckIcon from "features/projects/assets/icons/ClockCheckIcon";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

import { cx, RU } from "modules/common";
import { ProgressBarDataType } from "modules/dashboard";

import { AmountFixer, ClockCircleIcon } from "components";

import { WidgetCustomTooltip } from "../tooltip";

type Props = {
  data: ProgressBarDataType;
};

const ProgressBar: React.FC<Props> = ({ data }) => {
  const { i18n, t } = useTranslation();

  const customFieldProcessContent = (process: number) => (
    <div
      className={cx("flex h-7 w-7 items-center justify-center rounded-[7px]", {
        "bg-success-100": process === 100,
        "bg-primary-100": process > 0 && process < 100,
        "bg-gray-200": process === 0
      })}
    >
      {process === 0 || (process > 0 && process < 100) ? (
        <ClockCircleIcon width={18} color={process === 0 ? "var(--gray-500)" : "var(--primary-500)"} />
      ) : (
        <ClockCheckIcon width={18} height={18} />
      )}
    </div>
  );

  const customTooltipDynamic = (predicted_amount: number, fact_amount: number, plan_amount: number) => (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-1">
          <span className="text-sm font-normal text-gray-300">{t("dashboard.Bashorat summa")}</span>
          <div className="flex flex-col gap-1 border-b-0 border-l-4 border-r-0 border-t-0 border-solid border-success-500 pl-2">
            <span className="text-sm font-semibold text-white">{predicted_amount.toLocaleString(RU)}</span>
          </div>
        </div>
        <div className="flex flex-col gap-1">
          <span className="text-sm font-normal text-gray-300">{t("dashboard.Fakt summa")}</span>
          <div className="flex flex-col gap-1 border-b-0 border-l-4 border-r-0 border-t-0 border-solid border-primary-500 pl-2">
            <span className="text-sm font-semibold text-white">{fact_amount.toLocaleString(RU)}</span>
          </div>
        </div>
        <div className="flex flex-col gap-1">
          <span className="text-sm font-normal text-gray-300">{t("dashboard.Fakt summa")}</span>
          <div className="flex flex-col gap-1 border-b-0 border-l-4 border-r-0 border-t-0 border-solid border-white pl-2">
            <span className="text-sm font-semibold text-white">{plan_amount.toLocaleString(RU)}</span>
          </div>
        </div>
      </div>
    </div>
  );

  const styleOfAmounts = (first_amount: number, second_amount: number) => {
    if (first_amount <= second_amount) return "text-green-500";
    return "text-red-500";
  };

  return (
    <div className="flex flex-col gap-5">
      {data?.custom_field_options && data?.custom_field_options?.length > 0 && (
        <div className="flex max-h-[156px] min-h-[156px] flex-col gap-3 overflow-y-auto">
          {data?.custom_field_options?.map(el => (
            <div key={el?.id} className="flex h-11 items-center justify-between rounded-xl bg-gray-50 px-3 py-2">
              <div className="flex justify-center gap-2">
                {customFieldProcessContent(el?.process as number)}
                <div>
                  <span className="text-base font-bold text-gray-700">
                    {el?.name?.[i18next.language]?.toUpperCase()}
                  </span>
                  <span className="text-base font-medium text-gray-300"> | {el?.process}%</span>
                </div>
              </div>
              <div className="flex items-center gap-1">
                <WidgetCustomTooltip
                  isToolTip
                  content={customTooltipDynamic(
                    el?.prediction_amount as number,
                    el?.spend_amount as number,
                    el?.total_amount as number
                  )}
                >
                  <span className={cx("cursor-pointer text-[18px] font-semibold", `text-[${el?.color}]`)}>
                    <AmountFixer children={el?.spend_amount as number} />
                  </span>
                </WidgetCustomTooltip>
                <span className="text-sm font-normal text-gray-400">{data?.currency?.symbol}</span>
              </div>
            </div>
          ))}
        </div>
      )}
      <div className="flex flex-col gap-3 rounded-xl border border-solid border-gray-200 p-4">
        <div className="flex flex-col gap-1">
          <span className="text-xs font-medium text-gray-400">{data?.name}</span>
          <div className="flex flex-col justify-center gap-1">
            <span className={cx(styleOfAmounts(data?.prediction_amount, data?.total_amount), "text-xl font-bold")}>
              <AmountFixer className="m-0" children={data?.prediction_amount} />
            </span>
            <Progress
              percent={data?.process}
              className="[&_.ant-progress-text]:text-base [&_.ant-progress-text]:font-bold [&_.ant-progress-text]:text-gray-700"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
