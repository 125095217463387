import React from "react";
import { useDispatch } from "react-redux";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import ArrowNarrowIcon from "features/app/assets/icons/ArrowNarrowIcon";
import CustomAvatar from "features/app/components/custom-avatar/CustomAvatar";
import Pagination from "features/app/components/pagination/Pagination";
import TableEmpty from "features/app/components/table-empty/TableEmpty";
import { colors } from "features/app/utils/constants/colors";
import { PaginationType } from "features/app/utils/models/PaginationType";
import { PaymentModel } from "features/app/utils/models/payment/paymentModel";
import { PaymentTypeIcon } from "features/payment/utils/helpers/paymentTypeIcon";
import { useTranslation } from "react-i18next";

import { paymentReducerActions } from "store/reducers/paymentReducer";

import { cx, RU } from "modules/common";

import { LoadingIndicator } from "components";

import styles from "./others-table.module.scss";

type Props = {
  data: PaginationType<PaymentModel[]>;
  isLoading: boolean;
};

const OthersTable: React.FC<Props> = ({ isLoading, data }) => {
  const { t } = useTranslation();
  const { setIncomeOrExpenseViewVisible } = paymentReducerActions;
  const dispatch = useDispatch();
  const columns: ColumnsType<any> = [
    {
      title: `${t("dashboard.Turi")}`,
      render: (record: PaymentModel) => PaymentTypeIcon(record?.type, record?.status, t),
      fixed: "left",
      width: 80
    },
    {
      title: `${t("dashboard.Kassa")}`,
      render: (record: PaymentModel) => <div className="text-sm font-medium text-gray-600">{record?.cash?.name}</div>,
      width: 300
    },
    {
      title: `${t("dashboard.To'lov summasi")}`,
      render: (record: PaymentModel) => (
        <div className="text-sm font-medium text-gray-600">
          {record?.amount ? record?.amount?.toLocaleString(RU) : "-"} {record?.currency?.symbol}
        </div>
      ),
      width: 220
    },
    {
      title: `${t("dashboard.To'lov turi")}`,
      render: (record: PaymentModel) => (
        <div
          className={styles.payment_type}
          style={{
            color: record?.payment_type?.color,
            background: `${record?.payment_type?.color}20`
          }}
        >
          {record?.payment_type?.name || "-"}
        </div>
      ),
      width: 170
    },
    {
      title: `${t("dashboard.To'lov sanasi")}`,
      dataIndex: "date",
      width: 150
    },
    {
      title: `${t("dashboard.Xodim")}`,
      render: (record: PaymentModel) => <CustomAvatar name={record?.creator?.full_name} image={record?.creator?.image} />,
      width: 85,
      key: "creator.full_name",
      className: "payment-creator.full_name"
    },
    {
      title: "",
      render: (record: PaymentModel) => (
        <div className={styles.arrow}>
          <ArrowNarrowIcon rotate={180} stroke={colors.GRAY_500} />
        </div>
      ),
      width: 70
    }
  ];

  const onRow = (record: PaymentModel) => ({
    onClick: () => {
      dispatch(
        setIncomeOrExpenseViewVisible({
          visible: true,
          record,
          isNotDelete: true
        })
      );
    }
  });

  const footer = () => (
    <Pagination
      paginationProps={{
        total: data?.total,
        current: data?.current_page
      }}
    />
  );

  return (
    <Table
      dataSource={data?.data}
      columns={columns}
      pagination={false}
      rowClassName={styles.table_row}
      className={cx("footer_table", styles.table)}
      footer={data?.total > 10 ? footer : undefined}
      loading={{
        spinning: isLoading,
        indicator: LoadingIndicator
      }}
      locale={{
        emptyText: <TableEmpty />
      }}
      onRow={onRow}
    />
  );
};

export default OthersTable;
