import { useState } from "react";
import { Form, message, Modal, Select, Switch, Upload, UploadFile as UploadFileType } from "antd";
import { Rule } from "antd/es/form";
import { UploadChangeParam } from "antd/es/upload";
import { Spin } from "antd/lib";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "../../../../../hooks/redux";
import { settingsActions } from "../../../../../store/reducers/settingsReducer";
import DeleteIcon from "../../../../app/assets/icons/DeleteIcon";
import SelectSuffixIcon from "../../../../app/assets/icons/SelectSuffixIcon";
import UploadIcon from "../../../../app/assets/icons/UploadIcon";
import { LoadingIndicator } from "../../../../app/components/loading-indicator/LoadingIndicator";
import { useUploadFile } from "../../../../app/service/mutation";
import { formRules } from "../../../../app/utils/constants/formRules";
import { Status } from "../../../../app/utils/models/status";
import FileIcon from "../../../../projects/assets/icons/FileIcon";
import { useCreateTemplate, useUpdateTemplate } from "../../../service/mutations";
import { useGetTemplateView } from "../../../service/queries";
import { CustomFieldLocationValue } from "../../../utils/constants/customFieldLocationValue";
import { ReqDataTemplate } from "../../../utils/models/template/reqDataTemplate";
import { FileModel } from "../../../utils/models/template/templateModel";

import styles from "./templateModal.module.scss";

const { useForm, Item } = Form;
const { Option } = Select;
const { Dragger } = Upload;

const TemplateModal = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [form] = useForm();
  const [file, setFile] = useState<FileModel>();
  const { visible, id } = useAppSelector(state => state.settingsReducer.template);
  const { data } = useGetTemplateView(id);
  const { setTemplateModal } = settingsActions;
  const uploadFile = useUploadFile();
  const createTemplate = useCreateTemplate();
  const updateTemplate = useUpdateTemplate();

  const onCancel = () => {
    dispatch(setTemplateModal({ visible: false }));
    form.resetFields();
    setFile(undefined);
  };

  const fileRule: Rule[] = [
    () => ({
      validator(_, value: File) {
        if (!value) return Promise.reject();
        return Promise.resolve();
      },
      message: "",
      required: true
    })
  ];

  const onFileFromEvent = (e: UploadChangeParam<UploadFileType>) => {
    const file = e?.file?.originFileObj;

    if (file) return file;
    return undefined;
  };

  const onChange = (e: UploadChangeParam<UploadFileType>) => {
    if (e.file.size! >= 5120000) {
      message.error("File hajmi 5120 kb dan oshmasligi zarur");
    } else {
      const file = e?.file?.originFileObj;
      const formData = new FormData();

      formData.append("files[]", file as Blob);

      uploadFile.mutateAsync(formData).then(res => {
        form.setFieldValue("file_id", res[0]);
        // @ts-expect-error
        setFile(e?.file);
      });
    }
  };

  const onDeleteFile = () => {
    form.setFieldValue("file_id", null);
    setFile(undefined);
  };

  const onOk = () => form.submit();

  const onAfterOpenModal = (open: boolean) => {
    if (open && data) {
      form.setFieldsValue({
        id: data?.id,
        location: data?.location,
        status: data?.status === Status.ACTIVE,
        file_id: data?.file?.id
      });
      setFile(data?.file);
    }
  };

  const onFinish = (values: ReqDataTemplate) => {
    const reqData = { ...values, status: values?.status ? "active" : "passive" };

    if (id) {
      reqData.id = id;
      updateTemplate.mutateAsync(reqData).then(onCancel);
    } else {
      createTemplate.mutateAsync(reqData).then(onCancel);
    }
  };

  return (
    <Modal
      centered
      width={744}
      onOk={onOk}
      open={visible}
      title={!id ? t("products.Hujjat qo'shish") : t("products.Hujjatni tahrirlash")}
      onCancel={onCancel}
      okText={t("products.Saqlash")}
      cancelText={t("products.Yopish")}
      afterOpenChange={onAfterOpenModal}
      className={styles.template_modal}
      okButtonProps={{
        loading: createTemplate.isLoading || updateTemplate.isLoading
      }}
    >
      <Form form={form} layout="vertical" onFinish={onFinish} className={styles.form}>
        <Item name="location" label={t("products.Joylashuv")} rules={formRules()}>
          <Select placeholder={t("payment.Tanlang")} suffixIcon={<SelectSuffixIcon />}>
            {Object.entries(CustomFieldLocationValue).map(([key, value]) => (
              <Option
                key={key}
                value={key}
                props={{
                  name: value
                }}
              >
                {value}
              </Option>
            ))}
          </Select>
        </Item>
        <Item
          name="status"
          label={t("products.Joriy holati")}
          initialValue={true}
          valuePropName="checked"
          className={styles.status}
        >
          <Switch />
        </Item>
        <Item
          name="file_id"
          rules={fileRule}
          valuePropName="file_id"
          className="upload"
          getValueFromEvent={onFileFromEvent}
        >
          <Dragger
            name="images"
            maxCount={1}
            multiple={false}
            disabled={!!file}
            onChange={onChange}
            showUploadList={false}
            customRequest={() => null}
            className="upload__dragger"
            accept=".doc, .docx, .pdf"
          >
            <Spin spinning={uploadFile.isLoading} indicator={LoadingIndicator}>
              <div className="contents">
                <UploadIcon />
                <p>
                  <span>{t("products.Yuklash uchun bosing")}</span> {t("products.yoki sudrab olib tashlang")} DOC, DOCX
                  or PDF
                </p>
              </div>
            </Spin>
          </Dragger>
          {file && (
            <div className={styles.file}>
              <div className={styles.file__left}>
                <div className={styles.file__icon}>
                  <FileIcon />
                </div>
                <div className={styles.file__name}>
                  <p>{file?.original_name || file?.name}</p>
                  <p>{file?.size && Math.floor(file.size / 1024)} KB</p>
                </div>
              </div>
              <div className={styles.file__right} onClick={onDeleteFile}>
                <DeleteIcon />
              </div>
            </div>
          )}
        </Item>
      </Form>
    </Modal>
  );
};

export default TemplateModal;
