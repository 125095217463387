import { UserTableDataModel } from "../../../app/utils/models/user/userTableConfigModel";

export const impostTableDefaultData: UserTableDataModel = {
  column: [
    {
      id: "company_person.name",
      name: "company_person.name",
      title: "Kontragent",
      checked: true
    },
    {
      id: "agent.full_name",
      name: "agent.full_name",
      title: "Vositachi",
      checked: true
    },
    {
      id: "monitoring_counterparts",
      name: "monitoring_counterparts",
      title: "Kontragentlari",
      checked: true
    },
    {
      id: "ordered_date",
      name: "ordered_date",
      title: "Buyurtma sanasi",
      checked: false
    },
    {
      id: "resource_availability",
      name: "resource_availability",
      title: "Resurs mavjudligi",
      checked: true
    },
    {
      id: "delivery_date",
      name: "delivery_date",
      title: "Yetkazilish sanasi",
      checked: true
    },
    {
      id: "recieved_date",
      name: "recieved_date",
      title: "Yopilgan sanasi",
      checked: false
    },
    {
      id: "total_amount",
      name: "total_amount",
      title: "Umumiy narxi",
      checked: true
    },
    {
      id: "total_expense_amount",
      name: "total_expense_amount",
      title: "Xarajat summa",
      checked: false
    },
    {
      id: "status",
      name: "status",
      title: "Holati",
      checked: true
    },
    {
      id: "creator.full_name",
      name: "creator.full_name",
      title: "Xodim",
      checked: true
    },
    {
      id: "confirmation_payment",
      name: "confirmation_payment",
      title: "Tasdiqlash",
      checked: true
    },
    {
      id: "payment_date",
      name: "payment_date",
      title: "To'lov  sanasi",
      checked: true
    }
  ],
  size: 10
};

export const inventoryPaymentOrderTableDefaultData: UserTableDataModel = {
  column: [
    {
      id: "financial.name",
      name: "financial.name",
      title: "Manba",
      checked: true
    },
    {
      id: "date",
      name: "date",
      title: "Sana",
      checked: true
    },
    {
      id: "amount",
      name: "amount",
      title: "Summa",
      checked: true
    },

    {
      id: "payment_type.name",
      name: "payment_type.name",
      title: "To'lov turi",
      checked: true
    },
    {
      id: "creator.full_name",
      name: "creator.full_name",
      title: "Xodim",
      checked: true
    },
    {
      id: "status",
      name: "status",
      title: "Tasdiqlash",
      checked: true
    }
  ],
  size: 10
};

export const inventoryWorksTableDefaultData: UserTableDataModel = {
  column: [
    {
      id: "task.name",
      name: "task.name",
      title: "Ish nomi",
      checked: true
    },
    {
      id: "completed_date",
      name: "completed_date",
      title: "Ish tugallandi",
      checked: true
    },
    {
      id: "amount",
      name: "amount",
      title: "Umumiy summa",
      checked: true
    },
    {
      id: "company_person.name",
      name: "company_person.name",
      title: "Kontragent",
      checked: true
    },
    {
      id: "creator.full_name",
      name: "creator.full_name",
      title: "Xodim",
      checked: true
    },
    {
      id: "confirmation_payment",
      name: "confirmation_payment",
      title: "Tasdiqlash",
      checked: true
    },
    {
      id: "works-victims",
      name: "works-victims",
      title: "Guvohlar",
      checked: true
    }
  ],
  size: 10
};

export const impostPaymentsTableDefaultData: UserTableDataModel = {
  column: [
    {
      id: "financial.name",
      name: "financial.name",
      title: "Manba",
      checked: true
    },
    {
      id: "date",
      name: "date",
      title: "Sana",
      checked: true
    },
    {
      id: "amount",
      name: "amount",
      title: "Summa",
      checked: true
    },
    {
      id: "payment_type.name",
      name: "payment_type.name",
      title: "To'lov turi",
      checked: true
    },
    {
      id: "creator.full_name",
      name: "creator.full_name",
      title: "Xodim",
      checked: true
    },
    {
      id: "status",
      name: "status",
      title: "Tasdiqlash",
      checked: true
    }
  ],
  size: 10
};
