import React from "react";
import ArrowDownIcon from "features/app/assets/icons/ArrowDownIcon";
import { CheckIcon } from "features/app/assets/icons/CheckIcon";
import { colors } from "features/app/utils/constants/colors";
import PlayIcon from "features/inventory/assets/icons/PlayIcon";
import { numberRound } from "features/supply/utils/helpers/numberRound";

import { Currency, RU } from "modules/common";
import {
  getConditionalColor,
  getConditionalStyle,
  getIcon,
  makeSvgRotate,
  ProjectCountProjects
} from "modules/dashboard";
import { useTranslation } from "react-i18next";

interface IProps {
  item: ProjectCountProjects;
  currency: Currency;
}

const ProjectPerAreaPriceItem: React.FC<IProps> = ({ item, currency }) => {
  const { t } = useTranslation();
  const getIconAndDivColor = (amount: number) => {
    if (amount === 0) return colors.GRAY_100;
    if (amount > 0 && amount < 100) return colors.PRIMARY_400;
    return colors.SUCCESS_500;
  };

  const getTextColor = (amount: number) => {
    if (amount > 0 && amount < 100) return colors.PRIMARY_400;
    if (amount === 100) return colors.SUCCESS_500;
    return colors.BLACK;
  };

  const getProcessIcon = (amount: number) => {
    if (amount > 0 && amount < 100) return <PlayIcon stroke={colors.PRIMARY_400} />;
    if (amount > 0 && amount === 100) return <CheckIcon color={colors.SUCCESS_500} />;
    return null;
  };

  return (
    <div className="flex flex-col gap-3 rounded-xl border border-solid border-gray-200 bg-white p-4" key={item.id}>
      <div className="flex w-full items-center justify-between">
        <span className="text-xl font-semibold text-black">{item.name}</span>
        <div className="flex items-center gap-1">
          <span
            className="flex items-center justify-center gap-1 rounded-2xl px-2 py-1"
            style={{
              backgroundColor: getIconAndDivColor(item.process!),
              borderColor: getIconAndDivColor(item.process!)
            }}
          >
            <span>{getProcessIcon(item.process!)}</span>
            <span
              style={{
                color: getTextColor(item.process!)
              }}
              className="text-sm font-medium"
            >
              {numberRound(Math.abs(item.process!)) || 0}%
            </span>
          </span>
        </div>
      </div>
      <div className="flex w-full items-center justify-between">
        <span className="flex-1 text-sm font-normal text-gray-600">{t("dashboard.Reja narxi")}</span>
        <span className="flex items-center justify-center gap-1 text-sm font-medium text-black">
          {item?.per_area_price?.toLocaleString(RU)}{" "}
          <span className="flex items-center justify-center text-sm font-medium text-gray-400">
            {currency?.symbol}/ <span className="flex items-center justify-center">{t("dashboard.m²")}</span>
          </span>
        </span>
      </div>
      <div className="flex w-full items-center justify-between">
        <span className="flex-1 text-sm font-normal text-gray-600">{t("dashboard.Bashorat narxi")}</span>
        <span className="flex items-center justify-center gap-1 text-sm font-medium text-black">
          {item?.per_area_prediction_price?.toLocaleString(RU)}{" "}
          <span className="flex items-center justify-center text-sm font-medium text-gray-400">
            {currency?.symbol}/ <span className="flex items-center justify-center">{t("dashboard.m²")}</span>
          </span>
        </span>
      </div>
      <div className="flex w-full items-center justify-between">
        <span className="flex-1 text-sm font-normal text-gray-600">{t("dashboard.Umumiy maydon")}</span>
        <span className="flex items-center justify-center gap-1 text-sm font-medium text-black">
          {item?.area?.toLocaleString(RU)} <span className="flex items-center justify-center">{t("dashboard.m²")}</span>
        </span>
      </div>
      <div className="flex w-full items-center justify-between">
        <span>{t("dashboard.Narx o'zgarishi")}</span>
        <div className="flex items-center gap-1">
          <span
            className="flex items-center justify-center gap-1 rounded-2xl px-2 py-1"
            style={{
              backgroundColor: getConditionalStyle(item.per_area_percent || 0),
              borderColor: getConditionalStyle(item.per_area_percent || 0)
            }}
          >
            <span>
              {getIcon(
                item?.per_area_percent || 0,
                <ArrowDownIcon
                  stroke={getConditionalColor(item.per_area_percent!)}
                  width={12}
                  rotate={makeSvgRotate(item.per_area_percent!)}
                />
              )}
            </span>
            <span
              style={{
                color: getConditionalColor(item.per_area_percent || 0) || "black"
              }}
              className="text-sm font-medium"
            >
              {numberRound(item.per_area_percent!) || 0}%
            </span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default ProjectPerAreaPriceItem;
