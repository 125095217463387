import React, { useCallback, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import ModalConfirm from "features/app/components/modal-confirm/ModalConfirm";
import PaymentCalendar from "features/payment/components/bottom/calendar/PaymentCalendar";
import IncomeExpensePrintContent from "features/payment/components/bottom/tables/income-expense/IncomeExpensePrintContent";
import { useDeletePayment } from "features/payment/service/mutation";

import { paymentReducerActions } from "../../../../../../store/reducers/paymentReducer";
import { queryParamsKeys } from "../../../../../app/utils/constants/queryParamsKeys";
import { PaymentTabKeys } from "../../../../../payment/utils/constants/paymentTabKeys";

import ExpectedPaymentTable from "./expected-payment/ExpectedPaymentTable";
import ExpectedPaymentTemplateTable from "./expected-template-payment/ExpectedTemplatePaymentTable";
import IncomeExpenseTable from "./income-or-expense/IncomeExpenseTable";
import InventoryTable from "./inventory/InventoryTable";
import OrderTable from "./order/OrderTable";
import WorkPaymentTable from "./work-payment/WorkPaymentTable";

type Props = {
  tabKeys: { key: string; type?: string };
};

const CounterpartsTables: React.FC<Props> = ({ tabKeys }) => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { setConversionInfoModal, setTransferHistoryModal } = paymentReducerActions;
  const tabKey = searchParams.get(queryParamsKeys.STATISTICS_TYPE);
  const paymentType = searchParams.get(queryParamsKeys.PAYMENT_TYPE);

  const deletePayment = useDeletePayment();
  const printComponentRef = useRef<HTMLDivElement | null>(null);

  const [paymentDeleteModal, setPaymentDeleteModal] = useState({
    visible: false,
    id: 0
  });

  const setPaymentDeleteModalOpen = (visible: boolean) => {
    setPaymentDeleteModal(prev => ({
      ...prev,
      id: visible ? prev?.id : 0,
      visible
    }));
  };

  const reactToPrintContent = useCallback(() => printComponentRef.current, [printComponentRef]);

  const onDeletePayment = async () => {
    dispatch(setConversionInfoModal(undefined));
    dispatch(setTransferHistoryModal(undefined));
  };

  const items = {
    [PaymentTabKeys.INCOME_EXPENSE]: (
      <>
        <IncomeExpenseTable
          printComponentRef={printComponentRef}
          defaultTabKey={tabKeys.key}
          reactToPrintContent={reactToPrintContent}
          setPaymentDeleteModal={setPaymentDeleteModal}
        />
        <ModalConfirm
          isDescription
          mutation={deletePayment}
          onAfterClose={onDeletePayment}
          visible={paymentDeleteModal.visible}
          setVisible={setPaymentDeleteModalOpen}
          payload={{ id: paymentDeleteModal.id }}
        />
      </>
    ),
    [PaymentTabKeys.PAYMENT]:
      paymentType === "template" ? (
        <ExpectedPaymentTemplateTable defaultTabKey={tabKeys.key} />
      ) : (
        <ExpectedPaymentTable tabKeys={tabKeys} />
      ),
    [PaymentTabKeys.INVENTORY]: <InventoryTable defaultTabKey={tabKeys.key} />,
    [PaymentTabKeys.ORDER]: <OrderTable defaultTabKey={tabKeys.key} />,
    [PaymentTabKeys.WORK]: <WorkPaymentTable defaultTabKey={tabKeys.key} />,
    [PaymentTabKeys.CALENDAR]: <PaymentCalendar isCounterPart />
  };

  return (
    <div className="overflow-hidden rounded-xl bg-white">{items[(tabKey as keyof typeof items) ?? tabKeys.key]}</div>
  );
};

export default CounterpartsTables;
