import React, {
  // useEffect,
  useState
} from "react";
import { useDispatch } from "react-redux";
import { Form, Input, Modal, Spin } from "antd";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../../hooks/redux";
import { builderActions } from "../../../../store/reducers/builderReducer";
import AddressIcon from "../../../projects/assets/icons/AddressIcon";
import SelectSuffixIcon from "../../assets/icons/SelectSuffixIcon";
// import { addressReducerActions } from "../../../../store/reducers/addressReducer";
import { useCreateBuilder, useUpdateBuilder } from "../../service/mutation";
import { useGetBuilderViewById } from "../../service/queries";
import { IBuildersReqBody } from "../../utils/models/buildersModalModel";
import AddressModal from "../address-modal/AddressModal";
import ConditionalRender from "../conditional-render/ConditionalRender";
import { LoadingIndicator } from "../loading-indicator/LoadingIndicator";

import BankAccountNumberItem from "./bank-account-number-item/BankAccountNumberItem";
import CorporateItem from "./corporate-item/CorporateItem";
import MfoOker from "./mfo-oker/MfoOker";
import PhoneINNItem from "./phone-inn-item/PhoneINNItem";

import styles from "./builderModal.module.scss";

const { useForm, Item } = Form;

const BuilderModal: React.FC = () => {
  const dispatch = useDispatch();
  const [addressModalVisible, setAddressModalVisible] = useState<boolean>(false);
  const [isAddition, setIsAddition] = useState<boolean>(false);
  const { visible, data, name } = useAppSelector(state => state.builderReducer.builderModal);
  const { t } = useTranslation();
  // const { setDistrictId, setRegionId, setLatLongAddress } = addressReducerActions;
  // const { address, district_id, center } = useAppSelector(
  //   (state) => state.addressReducer.addressModal
  // );
  const { setBuilderModal } = builderActions;
  const [form] = useForm<IBuildersReqBody>();
  const createBuilder = useCreateBuilder();
  const updateBuilder = useUpdateBuilder();
  const { data: builderViews, isLoading } = useGetBuilderViewById(data ? data?.id : undefined);

  const onCancel = () => {
    dispatch(
      setBuilderModal({
        builderModal: {
          visible: false,
          data: undefined
        },
        name: undefined
      })
    );
    form.resetFields();
  };

  const onFinish = (values: IBuildersReqBody) => {
    if (data?.id) {
      updateBuilder
        .mutateAsync({
          ...values,
          id: data?.id
        })
        .then(() => onCancel());
    } else {
      createBuilder
        .mutateAsync({
          ...values
        })
        .then(() => onCancel());
    }
  };

  const onAfterOpen = (open: boolean) => {
    if (open) {
      if (name) {
        form.setFieldValue("name", name);
      }
      if (data && data?.id && builderViews) {
        form.setFieldsValue({
          name: builderViews?.name,
          inn: builderViews?.inn,
          mfo: builderViews?.mfo,
          bank_name: builderViews?.bank_name,
          account_number: builderViews?.account_number,
          address: builderViews?.address,
          phone: builderViews?.phone,
          owner: builderViews?.owner,
          oked: builderViews?.oked,
          district_id: builderViews?.district?.id,
          region_id: builderViews?.region?.id,
          latitude: builderViews?.latitude,
          longitude: builderViews?.longitude
        });
        // dispatch(setDistrictId(builderViews?.district?.id));
        // dispatch(setRegionId(builderViews?.region?.id));
        // dispatch(
        //   setLatLongAddress({
        //     lat: builderViews?.latitude,
        //     lng: builderViews?.longitude,
        //   })
        // );
      }
    }
  };

  const onOk = () => {
    form.submit();
  };

  const onOpenAddressAddModal = () => {
    setAddressModalVisible(true);
  };
  const onChangeAddition = () => {
    setIsAddition(!isAddition);
  };

  return (
    <Modal
      centered
      open={visible}
      title={!data?.id ? t("builder.Quruvchi qo'shish") : t("builder.Quruvchini tahrirlash")}
      okText={t("payment.Saqlash")}
      cancelText={t("payment.Yopish")}
      wrapClassName={styles.builder_modal}
      onCancel={onCancel}
      afterOpenChange={onAfterOpen}
      okButtonProps={{
        loading: createBuilder.isLoading || updateBuilder.isLoading
      }}
      onOk={onOk}
    >
      <Spin spinning={data ? isLoading : false} indicator={LoadingIndicator}>
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <div className={styles.form_content}>
            <CorporateItem className={styles.item_container} inputClassName={styles.input} />
            <PhoneINNItem className={styles.item_container} form={form} inputClassName={styles.input} />
            <div className={styles.addition__btn} onClick={onChangeAddition}>
              <span>{t("payment.Qo'shimcha ma'lumotlar")}</span>
              <SelectSuffixIcon placement={isAddition ? "bottom" : "right"} />
            </div>

            <ConditionalRender if={isAddition}>
              <MfoOker className={styles.item_container} inputClassName={styles.input} />

              <BankAccountNumberItem className={styles.item_container} inputClassName={styles.input} />

              <AddressModal
                visible={addressModalVisible}
                setVisibleModal={setAddressModalVisible}
                formInstance={form as never}
              >
                <div className={styles.item_container} onClick={onOpenAddressAddModal}>
                  <Item className="d_n" name="district_id" />
                  <Item className="d_n" name="region_id" />
                  <Item className="d_n" name="latitude" />
                  <Item className="d_n" name="longitude" />
                  <Item name="address" label={t("builder.Manzil")} className={styles.input}>
                    <Input suffix={<AddressIcon />} placeholder={t("builder.Kiriting..")} />
                  </Item>
                </div>
              </AddressModal>
            </ConditionalRender>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};

export default BuilderModal;
