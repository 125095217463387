import React, { useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { Button, Modal } from "antd";
import DeleteIcon from "features/app/assets/icons/DeleteIcon";
import { useTranslation } from "react-i18next";

import { User } from "modules/common";
import { useWidget, WidgetSettings } from "modules/dashboard";
import { SettingsForm } from "modules/dashboard/forms/settings";

import styles from "./settings.module.scss";

const Settings: React.FC = () => {
  const { t } = useTranslation();
  const {
    id,
    key,
    settingsVisible,
    methods: { setSettings }
  } = useWidget();

  const [users, setUsers] = useState<User[]>([]);

  const onCancel = () => {
    setSettings({ settingsVisible: false, id, key, settingsProps: undefined });
  };

  const onResetForm = (form: UseFormReturn<WidgetSettings>) => () => {
    const values = { ...form.getValues() };
    const keys = Object.keys(values);
    const resetObj: Record<string, any> = {};

    keys.forEach(key => {
      if (typeof values[key as keyof typeof values] === "string") {
        resetObj[key] = null;
      }

      if (typeof values[key as keyof typeof values] === "number") {
        resetObj[key] = null;
      }

      if (Array.isArray(values[key as keyof typeof values])) {
        resetObj[key] = [];
      }
    });

    setUsers([]);
    form.reset(resetObj);
  };

  return (
    <Modal
      centered
      footer={false}
      onCancel={onCancel}
      open={settingsVisible}
      title={t("dashboard.Vidjet sozlamasi")}
      rootClassName={styles.modal_wrapper}
      zIndex={1002}
    >
      <SettingsForm key={key + id} users={users} setUsers={setUsers}>
        {form => (
          <div className="flex items-center justify-end gap-3">
            <Button onClick={onResetForm(form)}>
              <DeleteIcon />
              {t("global.Tozalash")}
            </Button>
            <Button type="primary" htmlType="submit" loading={form.formState.isSubmitting}>
              {t("global.Saqlash")}
            </Button>
          </div>
        )}
      </SettingsForm>
    </Modal>
  );
};

export default Settings;
