import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { UnitModel } from "features/app/utils/constants/unitModel";
import { ProductModel, SingleProductIncomeExpenseHistoryViewModel } from "features/app/utils/models/notificationModel";
import { ProductInfoMarketPrice } from "features/supply/utils/models/orderModel";
import { WarehouseCategoryViewModel } from "features/warehouse/utils/models/warehouse-category-view-model";
import { uid } from "uid";

import useGetTableSize from "../../../hooks/useGetTableSize";
import { useQueryParams } from "../../../hooks/useQueryParams";
import { useRoutePermissions } from "../../../hooks/useRoutePermissions";
import { TABLE } from "../../app/utils/constants/localStorageKeys";
import { queryParamsKeys } from "../../app/utils/constants/queryParamsKeys";
import { tableConfigKeys } from "../../app/utils/constants/tableConfigKeys";
import $api from "../../app/utils/helpers/axiosInstance";
import { PaginationType } from "../../app/utils/models/PaginationType";
import { routeSubmodules } from "../utils/constants/routeSubmodules";
import { warehouseEndPoints } from "../utils/constants/warehouseEndPoints";
import { warehouseQueryNames } from "../utils/constants/warehouseQueryNames";
import { findFirstCommonElement } from "../utils/helpers/firstCommonElement";
import { AdditionalViewModel } from "../utils/models/additionalViewModel";
import { ExcessViewModel } from "../utils/models/excessViewModel";
import { LaboratoryViewById, LaboratoryViewData } from "../utils/models/laboratoryViewData";
import { OneOrderModel } from "../utils/models/oneOrderModel";
import { OneTransferModel } from "../utils/models/oneTransferViewModel";
import { OrderHistoryByIdModel, OrderHistoryModel } from "../utils/models/orderHistoryModel";
import { ReservationProjectModel } from "../utils/models/reservationProjectModel";
import { ReservationTaskProductModel } from "../utils/models/reservationProjectTaskModel";
import { ReservationSectionModel } from "../utils/models/reservationSectionModel";
import { TransferViewModel } from "../utils/models/transferViewModel";
import { WarehouseDefectModel } from "../utils/models/warehouseDefectModel";
import { WarehouseOrderModel } from "../utils/models/warehouseOrderModel";
import { WarehouseProductModel } from "../utils/models/WarehouseProductModel";
import { WarehouseReservation } from "../utils/models/warehouseReservation";
import { IProducts, IWareHouses } from "../utils/models/wareHouses";

export function useGetWarehouseSelect(mine?: boolean, enabled?: boolean, projectId?: number) {
  let url = warehouseEndPoints.WAREHOUSE_SELECT;
  const params = new URLSearchParams();

  if (projectId) {
    params.append("project_id", String(projectId));
  } else {
    params.delete("project_id");
  }

  if (mine) {
    params.append("mine", String(mine));
  }

  if (params.toString()) {
    url += `?${params.toString()}`;
  }

  return useQuery<{ id: number; name: string }[]>(
    [warehouseQueryNames.WAREHOUSE_SELECT, mine, projectId],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      // staleTime: Infinity,
      refetchOnMount: "always",
      enabled
    }
  );
}

export function useGetWarehouseProducts(category_id?: string, sub_category_id?: string) {
  const { reqQueryParam, searchParams, queries } = useQueryParams();
  let url = warehouseEndPoints.WAREHOUSE_PRODUCTS;
  const { tab, availability } = queries();

  if (category_id && !sub_category_id && !searchParams) {
    url += `?category_id=${category_id}&only_category_product=true`;
  }

  if (category_id && !sub_category_id && searchParams?.length > 0) {
    if (availability && availability !== "all") {
      url += `?category_id=${category_id}&only_category_product=true&${reqQueryParam(
        queryParamsKeys.PAGE,
        queryParamsKeys.SEARCH,
        queryParamsKeys.WAREHOUSE_IDS_ARR,
        queryParamsKeys.PROJECT_IDS_ARR,
        queryParamsKeys.AVAILABILITY,
        queryParamsKeys.SIZE,
        queryParamsKeys.CUSTOM_FIELDS_ARR
      )}`;
    } else {
      url += `?category_id=${category_id}&only_category_product=true&${reqQueryParam(
        queryParamsKeys.PAGE,
        queryParamsKeys.SEARCH,
        queryParamsKeys.WAREHOUSE_IDS_ARR,
        queryParamsKeys.PROJECT_IDS_ARR,
        queryParamsKeys.SIZE,
        queryParamsKeys.CUSTOM_FIELDS_ARR
      )}`;
    }
  }

  if (category_id && sub_category_id && !searchParams) {
    url += `?subcategory_id=${sub_category_id}`;
  }

  if (category_id && sub_category_id && searchParams && searchParams?.length > 0) {
    if (availability && availability !== "all") {
      url += `?subcategory_id=${sub_category_id}&${reqQueryParam(
        queryParamsKeys.PAGE,
        queryParamsKeys.SEARCH,
        queryParamsKeys.WAREHOUSE_IDS_ARR,
        queryParamsKeys.PROJECT_IDS_ARR,
        queryParamsKeys.AVAILABILITY,
        queryParamsKeys.SIZE,
        queryParamsKeys.CUSTOM_FIELDS_ARR
      )}`;
    } else {
      url += `?subcategory_id=${sub_category_id}&${reqQueryParam(
        queryParamsKeys.PAGE,
        queryParamsKeys.SEARCH,
        queryParamsKeys.WAREHOUSE_IDS_ARR,
        queryParamsKeys.PROJECT_IDS_ARR,
        queryParamsKeys.SIZE,
        queryParamsKeys.CUSTOM_FIELDS_ARR
      )}`;
    }
  }

  if (searchParams && searchParams?.length > 0 && !category_id && !sub_category_id) {
    if (availability && availability !== "all") {
      url += `?${reqQueryParam(
        queryParamsKeys.PAGE,
        queryParamsKeys.SEARCH,
        queryParamsKeys.WAREHOUSE_IDS_ARR,
        queryParamsKeys.PROJECT_IDS_ARR,
        queryParamsKeys.AVAILABILITY,
        queryParamsKeys.SIZE,
        queryParamsKeys.CUSTOM_FIELDS_ARR
      )}`;
    } else {
      url += `?${reqQueryParam(
        queryParamsKeys.PAGE,
        queryParamsKeys.SEARCH,
        queryParamsKeys.WAREHOUSE_IDS_ARR,
        queryParamsKeys.PROJECT_IDS_ARR,
        queryParamsKeys.SIZE,
        queryParamsKeys.CUSTOM_FIELDS_ARR
      )}`;
    }
  }

  return useQuery<PaginationType<WarehouseProductModel[]>>(
    [warehouseQueryNames.WAREHOUSE_PRODUCTS, searchParams, category_id, sub_category_id],
    async () => {
      const res = await $api.get(url);

      return res.data?.data;
    },
    {
      enabled: !tab || tab === "warehouse"
    }
  );
}
export function useGetWarehouseProductsInTransfers(
  warehouse_id: number | undefined,
  searchText?: string | undefined,
  page?: number | undefined
) {
  const { queries, generateSearchParam } = useQueryParams();
  const { tab, size } = queries();
  let url = useGetTableSize({
    endpoint: warehouseEndPoints.WAREHOUSE_PRODUCTS,
    tableConfigKey: tableConfigKeys.TRANSFER_MAKING_TABLE
  });

  const valuesObj = {
    warehouse_id,
    search: searchText,
    page
  };

  if (generateSearchParam(valuesObj)) {
    url += `&${generateSearchParam(valuesObj)}&availability=true`;
  }

  return useQuery<PaginationType<WarehouseProductModel[]>>(
    [warehouseQueryNames.WAREHOUSE_PRODUCTS, warehouse_id, searchText, page, size],
    async () => {
      const res = await $api.get(url);

      return {
        ...res.data?.data,
        data: res?.data?.data?.data?.map((item: WarehouseProductModel) => ({
          ...item,
          uniqueId: uid()
        }))
      };
    },
    {
      refetchOnWindowFocus: true,
      enabled: (!tab || tab === "transfer" || tab === "defect") && !!warehouse_id
    }
  );
}

export function useGetWarehouseDefect() {
  const { reqQueryParam, searchParams, queries } = useQueryParams();
  let url = warehouseEndPoints.WAREHOUSE_DEFECT;
  const tabKeys = ["Omborxona", "Partiyalar", "O'tkazmalar", "Buyurtmalar", "Yaroqsiz mahsulotlar"];
  const { submodules } = useRoutePermissions("Omborxona", routeSubmodules);
  const firstKey = findFirstCommonElement(tabKeys, submodules);

  if (searchParams && searchParams?.length > 0) {
    url += `?${reqQueryParam(
      "page",
      "search",
      queryParamsKeys.WAREHOUSE_IDS_ARR,
      queryParamsKeys.PROJECT_IDS_ARR,
      queryParamsKeys.CONFIRMATION_USER_IDS_ARR,
      queryParamsKeys.STATUSES_ARR,
      queryParamsKeys.TYPES_ARR,
      queryParamsKeys.MIN_DATE,
      queryParamsKeys.MAX_DATE,
      queryParamsKeys.SIZE,
      queryParamsKeys.CUSTOM_FIELDS_ARR
    )}`;
  }
  return useQuery<PaginationType<WarehouseDefectModel[]>>(
    [warehouseQueryNames.WAREHOUSE_DEFECT, searchParams],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      enabled: (() => {
        if (firstKey === "Yaroqsiz mahsulotlar")
          return queries()[queryParamsKeys.TAB] === "defect" || firstKey === "Yaroqsiz mahsulotlar";
        return queries()[queryParamsKeys.TAB] === "defect";
      })()
    }
  );
}

export function useGetTransferView() {
  const { reqQueryParam, searchParams, queries } = useQueryParams();
  let url = warehouseEndPoints.TRANSFER_VIEW;
  const tabKeys = ["Omborxona", "Partiyalar", "O'tkazmalar", "Buyurtmalar", "Yaroqsiz mahsulotlar"];
  const { submodules } = useRoutePermissions("Omborxona", routeSubmodules);
  const firstKey = findFirstCommonElement(tabKeys, submodules);

  if (searchParams && searchParams?.length > 0) {
    url += `?${reqQueryParam(
      "page",
      "search",
      queryParamsKeys.SENDER_WAREHOUSE_IDS_ARR,
      queryParamsKeys.RECIEVED_WAREHOUSE_IDS_ARR,
      queryParamsKeys.PROJECT_IDS_ARR,
      queryParamsKeys.MIN_DATE,
      queryParamsKeys.MAX_DATE,
      queryParamsKeys.STATUSES_ARR,
      queryParamsKeys.SIZE,
      queryParamsKeys.CUSTOM_FIELDS_ARR
    )}`;
  }

  return useQuery<PaginationType<TransferViewModel[]>>(
    [warehouseQueryNames.TRANSFER_VIEW, searchParams],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      enabled: (() => {
        if (firstKey === "O'tkazmalar")
          return queries()[queryParamsKeys.TAB] === "transfer" || firstKey === "O'tkazmalar";
        return queries()[queryParamsKeys.TAB] === "transfer";
      })()
    }
  );
}

export function useGetOneTransfer(id?: number, enabled?: boolean) {
  return useQuery<OneTransferModel>(
    [warehouseQueryNames.ONE_TRANSFER, id],
    async () => {
      const res = await $api.get(`${warehouseEndPoints.TRANSFER_VIEW}?id=${id}`);

      return res.data.data;
    },
    {
      enabled: !!id
    }
  );
}

export function useGetWarehouseProductSelect(enabled?: boolean, warehouse_id?: number) {
  let url = warehouseEndPoints.WAREHOUSE_PRODUCT_SELECT;

  if (warehouse_id) {
    url += `?warehouse_id=${warehouse_id}`;
  }
  return useQuery<WarehouseProductModel[]>(
    [warehouseQueryNames.WAREHOUSE_PRODUCT_SELECT, warehouse_id],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      staleTime: Infinity,
      enabled: enabled ?? true
    }
  );
}

export function useGetWarehouseProductsSelect(enabled?: boolean, warehouse_id?: number, search?: string) {
  return useInfiniteQuery<PaginationType<WarehouseProductModel[]>>(
    [warehouseQueryNames.WAREHOUSE_PRODUCT_SELECT, warehouse_id, search],
    async ({ pageParam = 1 }) => {
      let url = `${warehouseEndPoints.WAREHOUSE_PRODUCT_SELECT}?paginate=true&page=${pageParam ?? 1}`;

      if (warehouse_id && !search) {
        url += `&warehouse_id=${warehouse_id}`;
      }
      if (warehouse_id && search) {
        url += `&warehouse_id=${warehouse_id}&search=${search}`;
      }
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      staleTime: Infinity,
      enabled: enabled ?? true
    }
  );
}

export function useGetExcessView(product_id?: number, transfer_id?: number) {
  return useQuery<ExcessViewModel[]>(
    [warehouseQueryNames.EXCESS_VIEW, transfer_id, product_id],
    async () => {
      const res = await $api.get(
        `${warehouseEndPoints.EXCESS_VIEW}?product_id=${product_id}&transfer_id=${transfer_id}`
      );

      return res.data.data;
    },
    {
      enabled: !!product_id || !!transfer_id
    }
  );
}

export function useGetOrders() {
  const tabKeys = ["Omborxona", "Partiyalar", "O'tkazmalar", "Buyurtmalar", "Yaroqsiz mahsulotlar"];
  const { submodules } = useRoutePermissions("Omborxona", routeSubmodules);
  const firstKey = findFirstCommonElement(tabKeys, submodules);

  const { reqQueryParam, severalSearchParams, queries } = useQueryParams();
  const searchParam = severalSearchParams(queryParamsKeys.TAB);

  let url = useGetTableSize({
    endpoint: warehouseEndPoints.ORDERS_VIEW,
    tableConfigKey: tableConfigKeys.WAREHOUSE_ORDER_CONFIG
  });

  if (searchParam && searchParam?.length > 0) {
    url += `&${reqQueryParam(
      queryParamsKeys.PAGE,
      queryParamsKeys.SEARCH,
      queryParamsKeys.STATUSES_ARR,
      queryParamsKeys.AGENT_IDS_ARR,
      queryParamsKeys.COMPANY_PERSON_IDS_ARR,
      queryParamsKeys.CONFIRMATION_PAYMENTS_ARR,
      queryParamsKeys.PAYMENT_STATUSES_ARR,
      queryParamsKeys.MIN_DELIVERY_DATE,
      queryParamsKeys.MAX_DELIVERY_DATE,
      queryParamsKeys.MIN_ORDERED_DATE,
      queryParamsKeys.MAX_ORDERED_DATE,
      queryParamsKeys.CUSTOM_FIELDS_ARR,
      queryParamsKeys.SORT_DELIVERY_DATE,
      queryParamsKeys.SORT_ORDERED_DATE,
      queryParamsKeys.SORT_PAYMENT_DATE,
      queryParamsKeys.MIN_PAYMENT_DATE,
      queryParamsKeys.MAX_PAYMENT_DATE,
      queryParamsKeys.MIN_ORDERED_DATE,
      queryParamsKeys.MAX_ORDERED_DATE,
      queryParamsKeys.PAYMENT_TYPE_IDS_ARR
    )}`;
  }

  return useQuery<PaginationType<WarehouseOrderModel[]>>(
    [warehouseQueryNames.WAREHOUSE_ORDERS, url],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      enabled: (() => {
        if (firstKey === "Partiyalar") return queries()[queryParamsKeys.TAB] === "orders" || firstKey === "Partiyalar";
        return queries()[queryParamsKeys.TAB] === "orders";
      })()
    }
  );
}

export function useGetOneOrder(orderId?: number, enabled?: boolean) {
  const { queries, generateSearchParam } = useQueryParams();
  const { product_search } = queries();

  let url = `${warehouseEndPoints.ORDERS_VIEW}?order_id=${orderId}`;

  if (product_search) {
    url += `&${generateSearchParam({
      search: product_search
    })}`;
  }

  return useQuery<OneOrderModel[]>(
    [warehouseQueryNames.ONE_WAREHOUSE_ORDER, orderId, url],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      enabled: queries()[queryParamsKeys.TAB] === "orders" && Boolean(orderId) && enabled
    }
  );
}

export function useGetWarehouseReservation() {
  const tabKeys = ["Omborxona", "Partiyalar", "O'tkazmalar", "Buyurtmalar", "Yaroqsiz mahsulotlar"];
  const { submodules } = useRoutePermissions("Omborxona", routeSubmodules);
  const firstKey = findFirstCommonElement(tabKeys, submodules);
  const { reqQueryParam, severalSearchParams, queries } = useQueryParams();
  const searchParam = severalSearchParams(queryParamsKeys.TAB);
  const { size } = queries();

  const defaultSize =
    size || JSON.parse(localStorage.getItem(TABLE) || "{}")?.[tableConfigKeys.WAREHOUSE_PRODUCT_CONFIG];

  let url = `${warehouseEndPoints.WAREHOUSE_RESERVATION}${`?${queryParamsKeys.SIZE}=${defaultSize || 10}`}`;

  if (searchParam && searchParam?.length > 0) {
    url += `&${reqQueryParam(
      queryParamsKeys.PAGE,
      queryParamsKeys.SEARCH,
      queryParamsKeys.WAREHOUSE_IDS_ARR,
      queryParamsKeys.PROJECT_IDS_ARR,
      queryParamsKeys.MIN_DELIVERY_DATE,
      queryParamsKeys.MAX_DELIVERY_DATE,
      queryParamsKeys.CREATORS_IDS_ARR,
      queryParamsKeys.STATUSES_ARR,
      queryParamsKeys.SORT_DELIVERY_DATE,
      queryParamsKeys.SORT_QUANTITY,
      queryParamsKeys.CUSTOM_FIELDS_ARR
    )}`;
  }

  return useQuery<PaginationType<WarehouseReservation[]>>(
    [warehouseQueryNames.WAREHOUSE_RESERVATION, searchParam],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      enabled: (() => {
        if (firstKey === "Buyurtmalar")
          return queries()[queryParamsKeys.TAB] === "warehouse-products" || firstKey === "Buyurtmalar";
        return queries()[queryParamsKeys.TAB] === "warehouse-products";
      })()
    }
  );
}

export function useGetWarehouseReservationProjects(enabled: boolean) {
  return useQuery<ReservationProjectModel[]>(
    [warehouseQueryNames.WAREHOUSE_RESERVATION_PROJECT],
    async () => {
      const res = await $api.get(warehouseEndPoints.WAREHOUSE_RESERVATION_PROJECT);

      return res.data.data;
    },
    {
      enabled
    }
  );
}

export function useGetWarehouseReservationSections(enabled?: boolean, project_id?: number) {
  return useQuery<ReservationSectionModel[]>(
    [warehouseQueryNames.WAREHOUSE_RESERVATION_SECTION, project_id],
    async () => {
      const res = await $api.get(`${warehouseEndPoints.WAREHOUSE_RESERVATION_PROJECT}?id=${project_id}`);

      return res.data.data;
    },
    {
      enabled: !!project_id && enabled
    }
  );
}

export function useGetWarehouseReservationProducts(task_id?: number) {
  return useQuery<ReservationTaskProductModel[]>(
    [warehouseQueryNames.WAREHOUSE_RESERVATION_SECTION, task_id],
    async () => {
      const res = await $api.get(`${warehouseEndPoints.WAREHOUSE_RESERVATION_PRODUCT}?task_id=${task_id}`);

      return res.data.data;
    },
    {
      enabled: !!task_id
    }
  );
}

export function useGetWarehouseMineSelect(enabled: boolean) {
  return useQuery<{ id: number; name: string }[]>(
    [warehouseQueryNames.WAREHOUSE_SELECT],
    async () => {
      const res = await $api.get(`${warehouseEndPoints.WAREHOUSE_SELECT}?mine=true`);

      return res.data.data;
    },
    {
      staleTime: Infinity,
      enabled
    }
  );
}

export function useGetAdditionalView(id: number | undefined) {
  return useQuery<AdditionalViewModel>(
    [warehouseQueryNames.ADDITIONAL_VIEW, id],
    async () => {
      const res = await $api.get(`${warehouseEndPoints.ADDITIONAL_VIEW}?id=${id}`);

      return res.data.data;
    },
    {
      enabled: !!id
    }
  );
}

export function useGetHistoryOrder(id: number | undefined) {
  const url = `${warehouseEndPoints.HISTORY_ORDER}?warehouse_product_id=${id}`;

  return useQuery<OrderHistoryModel>(
    [warehouseQueryNames.HISTORY_ORDER, id],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      enabled: !!id
    }
  );
}

export function useGetHistoryOrderById(id: number | undefined) {
  const url = `${warehouseEndPoints.HISTORY_ORDER}?order_id=${id}`;

  return useQuery<OrderHistoryByIdModel[]>(
    [warehouseQueryNames.HISTORY_ORDER, id],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      enabled: !!id
    }
  );
}

export function useGetWareHousesByProductId(product_id: number | undefined, unit_id: number | undefined) {
  const { severalSearchParams } = useQueryParams();
  const searchParams = severalSearchParams(queryParamsKeys.TAB, queryParamsKeys.PAGE, queryParamsKeys.SIZE);
  let url = `${warehouseEndPoints.WAREHOUSE_BY_PRODUCT_ID}?product_id=${product_id}&unit_id=${unit_id}`;

  if (searchParams && searchParams?.length > 0) {
    url += `&${searchParams}`;
  }

  return useQuery<IWareHouses[]>(
    [warehouseQueryNames.WAREHOUSE_BY_PRODUCT_ID, product_id, unit_id],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      enabled: !!product_id && !!unit_id
    }
  );
}

export function useGetWareHouseProductsByWareHouseId(
  warehouseId: number | undefined,
  productId: number | undefined,
  unit_id: number | undefined
) {
  const { severalSearchParams } = useQueryParams();
  let url = `${warehouseEndPoints.WAREHOUSE_PRODUCT_BY_WAREHOUSE_ID}?warehouse_id=${warehouseId}&product_id=${productId}&unit_id=${unit_id}`;
  const searchParams = severalSearchParams(
    queryParamsKeys.PAGE,
    queryParamsKeys.TAB,
    queryParamsKeys.SEARCH,
    queryParamsKeys.AVAILABILITY
  );

  if (searchParams && searchParams?.length > 0) {
    url += `&${searchParams}`;
  }
  return useQuery<IProducts[]>(
    [warehouseQueryNames.WAREHOUSE_PRODUCT_BY_WAREHOUSE_ID, warehouseId, productId, unit_id, searchParams],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      enabled: !!warehouseId && !!productId && !!unit_id
    }
  );
}

export function useGetLaboratory() {
  const { reqQueryParam, severalSearchParams, queries } = useQueryParams();
  const searchParam = severalSearchParams(queryParamsKeys.TAB);
  const { tab } = queries();
  let url = useGetTableSize({
    endpoint: warehouseEndPoints.WAREHOUSE_LABORATORY_VIEW,
    tableConfigKey: tableConfigKeys.LABORATORY
  });

  if (searchParam && searchParam?.length > 0) {
    url += `&${reqQueryParam(
      queryParamsKeys.PAGE,
      queryParamsKeys.SEARCH,
      queryParamsKeys.WAREHOUSE_IDS_ARR,
      queryParamsKeys.PROJECT_IDS_ARR
    )}`;
  }
  return useQuery<PaginationType<LaboratoryViewData[]>>(
    [warehouseQueryNames.WAREHOUSE_LABORATORY_VIEW, searchParam],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      enabled: tab === "laboratory"
    }
  );
}

export function useGetLaboratoryById(id: number | null) {
  return useQuery<LaboratoryViewById>(
    [warehouseQueryNames.WAREHOUSE_LABORATORY_VIEW_BY_ID, id],
    async () => {
      const res = await $api.get(`${warehouseEndPoints.WAREHOUSE_LABORATORY_VIEW}?id=${id}`);

      return res.data.data;
    },
    {
      enabled: !!id
    }
  );
}

export function useGetSingleWarehouseProduct(product_id: number, unit_id: number) {
  const { severalSearchParams } = useQueryParams();
  let url = `${warehouseEndPoints.WAREHOUSE_SINGLE_PRODUCT_INFO}?product_id=${product_id}&unit_id=${unit_id}`;
  const searchParams = severalSearchParams(
    queryParamsKeys.PAGE,
    queryParamsKeys.TAB,
    queryParamsKeys.SEARCH,
    queryParamsKeys.AVAILABILITY
  );

  if (searchParams && searchParams?.length > 0) {
    url += `&${searchParams}`;
  }
  return useQuery<ProductModel & { market_price: ProductInfoMarketPrice }>(
    [warehouseQueryNames.WAREHOUSE_SINGLE_PRODUCT, product_id, unit_id, searchParams],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!product_id && !!unit_id
    }
  );
}
export function useGetSingleWarehouseProductViewStats(product_id: number, unit_id: number, enabled: boolean) {
  let url = `${warehouseEndPoints.WAREHOUSE_SINGLE_PRODUCT_HISTORY_STATS}?product_id=${product_id}&unit_id=${unit_id}`;
  const { severalSearchParams } = useQueryParams();
  const searchParams = severalSearchParams(
    queryParamsKeys.PAGE,
    queryParamsKeys.TAB,
    queryParamsKeys.SEARCH,
    queryParamsKeys.AVAILABILITY
  );

  if (searchParams && searchParams?.length > 0) {
    url += `&${searchParams}`;
  }
  return useQuery<{ total_quantity: number; total_income_quantity: number; total_expense_quantity: number }>(
    [warehouseQueryNames.WAREHOUSE_SINGLE_PRODUCT_STATS, product_id, unit_id, searchParams],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: enabled && !!product_id && !!unit_id
    }
  );
}

export function useGetSingleProductHistoryView(product_id: number, unit_id: number, enabled: boolean) {
  let url = `${warehouseEndPoints.WAREHOUSE_SINGLE_PRODUCT_INCOME_EXPENSE_HISTORY_VIEW}?product_id=${product_id}&unit_id=${unit_id}`;
  const { severalSearchParams } = useQueryParams();
  const searchParams = severalSearchParams(
    queryParamsKeys.PAGE,
    queryParamsKeys.TAB,
    queryParamsKeys.SEARCH,
    queryParamsKeys.AVAILABILITY
  );

  if (searchParams && searchParams?.length > 0) {
    url += `&${searchParams}`;
  }

  return useQuery<{
    histories: SingleProductIncomeExpenseHistoryViewModel[];
    statistics: { total_quantity: number; total_income_quantity: number; total_expense_quantity: number };
  }>(
    [warehouseQueryNames.WAREHOUSE_SINGLE_PRODUCT_INCOME_EXPENSE_HISTORY_VIEW, product_id, unit_id, searchParams],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: enabled && !!product_id && !!unit_id
    }
  );
}
export function useGetWarehouseCategoryView(enabled: boolean) {
  return useQuery<WarehouseCategoryViewModel[]>(
    [warehouseQueryNames.WAREHOUSE_CATEGORY_VIEW],
    async () => {
      const res = await $api.get(`${warehouseEndPoints.WAREHOUSE_CATEGORY_VIEW}`);

      return res.data.data;
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled
    }
  );
}

export function useGetWarehouseSubCategoryView(enabled: boolean, category_id: number) {
  return useQuery<WarehouseCategoryViewModel[]>(
    [warehouseQueryNames.WAREHOUSE_SUB_CATEGORY_VIEW],
    async () => {
      const res = await $api.get(`${warehouseEndPoints.WAREHOUSE_SUB_CATEGORY_VIEW}?category_id=${category_id}`);

      return res.data.data;
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: enabled && !!category_id
    }
  );
}
export function useGetWarehouseProductHistory(group_by?: string) {
  const { severalSearchParams } = useQueryParams();
  let url = useGetTableSize({
    endpoint: warehouseEndPoints.WAREHOUSE_PRODUCT_HISTORY,
    tableConfigKey: !group_by
      ? tableConfigKeys.WAREHOUSE_INCOME_EXPENSE_HISTORY
      : tableConfigKeys.WAREHOUSE_INCOME_EXPENSE_HISTORY_GROUP_BY
  });
  const searchParams = severalSearchParams(queryParamsKeys.TAB, queryParamsKeys.SIZE, queryParamsKeys.PAGE);

  if (searchParams && searchParams?.length > 0) {
    url += `&${searchParams}`;
  }
  return useQuery<
    (SingleProductIncomeExpenseHistoryViewModel & {
      product: ProductModel;
      unit: UnitModel;
    })[]
  >(
    [warehouseQueryNames.WAREHOUSE_PRODUCT_HISTORY, searchParams],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      retry: false,
      refetchOnWindowFocus: false
    }
  );
}
