import React from "react";
import { useDispatch } from "react-redux";
import { Popover, Spin } from "antd";
import { ColumnsType } from "antd/es/table";
import ArrowNarrowIcon from "features/app/assets/icons/ArrowNarrowIcon";
import { DateStatus } from "features/app/components/date-status/DateStatus";
import { LoadingIndicator } from "features/app/components/loading-indicator/LoadingIndicator";
import NotUserImage from "features/app/components/not-image/NotUserImage";
import OrderSortBy from "features/app/components/order-sort-by/OrderSortBy";
import { StatusEnums } from "features/app/components/statuses/statusEnums";
import { useGetCustomFieldSelect, useGetTableConfigs } from "features/app/service/queries";
import { queryParamsKeys } from "features/app/utils/constants/queryParamsKeys";
import { tableConfigKeys } from "features/app/utils/constants/tableConfigKeys";
import { CustomFieldLocationEnum } from "features/app/utils/enums/customFieldLocationEnum";
import { filterColumns } from "features/app/utils/helpers/filterColumns";
import { sliceText } from "features/app/utils/helpers/sliceText";
import PencilIcon from "features/payment/assets/icons/PencilIcon";
import WalletIcon from "features/payment/assets/icons/WalletIcon";
import PhoneCallIcon from "features/supply/assets/icons/PhoneCallIcon";
import { useOfferProductRecieved, useOfferProductRejected } from "features/supply/service/mutations";
import { warehouseOfferTableDefaultData } from "features/supply/utils/constants/warehouseOfferTableDefaultData";
import { OfferModel, ProductOfferModel } from "features/supply/utils/models/OfferModel";
import XCircleIcon from "features/warehouse/assets/icons/XCircleIcon";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";

import { supplyOfferActions } from "store/reducers/supplyOfferReducer";

import { RU } from "modules/common";

import styles from "../offers.module.scss";

const OfferColumns = ({ isImpost, isView }: { isImpost?: boolean; isView?: boolean }) => {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const acceptedOffer = useOfferProductRecieved();
  const rejectedOffer = useOfferProductRejected();
  const { setSelectOffers } = supplyOfferActions;

  const { data: selectOffers } = useAppSelector(state => state?.supplyOfferReducer?.selectOffers);

  const { data: tableConfigData } = useGetTableConfigs(
    tableConfigKeys.WAREHOUSE_PRODUCT_OFFER_CONFIG,
    warehouseOfferTableDefaultData
  );

  const { data: customFields } = useGetCustomFieldSelect([CustomFieldLocationEnum.WAREHOUSE_PRODUCT_OFFER]);

  const statusBackgroundColors = {
    [StatusEnums.RECIEVED]: "#12B76A",
    [StatusEnums.REJECTED]: "#F04438"
  };

  const notUserBg = (products: ProductOfferModel[]) => {
    const sliceProducts = products?.slice(4, products?.length);

    const isRecievedProduct = sliceProducts?.some(item => item.status === StatusEnums.RECIEVED);

    if (isRecievedProduct) return "#12B76A";
    return "";
  };

  // const changeSelectOffersData = (status: StatusEnums, id: number) => () => {
  //   if (!isEmptyArr(selectOffers)) {
  //     const newSelectOffers: OfferModel[] = [];

  //     selectOffers.forEach((offer: any) => {
  //       let newOfferValue = offer;

  //       const indexWarehouseProduct = offer?.warehouse_product_offers?.findIndex((product: any) => product.id === id);

  //       if (indexWarehouseProduct! >= 0) {
  //         newOfferValue = {
  //           ...newOfferValue,
  //           warehouse_product_offers: newOfferValue.warehouse_product_offers?.map((item: any, index: number) => {
  //             if (index === indexWarehouseProduct) {
  //               return {
  //                 ...item,
  //                 status
  //               };
  //             }
  //             return item;
  //           })
  //         };
  //       }

  //       newSelectOffers.push(newOfferValue);
  //     });

  //     dispatch(setSelectOffers(newSelectOffers));
  //   }
  // };

  const productsItems = (products: ProductOfferModel[]) => (
    <Spin spinning={acceptedOffer.isLoading || rejectedOffer.isLoading} indicator={LoadingIndicator}>
      <div className={styles.products__content} style={{ width: isView ? "12.5rem" : "" }}>
        {products?.map(item => (
          <div className={styles.products__label} key={item?.id}>
            <div className={styles.products__top}>
              <div className={styles.products__label__left}>
                <NotUserImage
                  key={item?.id}
                  name={item?.name ? item?.name : item?.company_person?.name}
                  background={statusBackgroundColors[item.status as keyof typeof statusBackgroundColors]}
                />
                <div className={styles.products__name}>
                  <h5>{item?.name ? item?.name : item?.company_person?.name}</h5>
                  <div className="flex items-center gap-1">
                    <p>
                      {item?.amount?.toLocaleString(RU)} {item?.currency?.symbol}
                    </p>
                    {item?.vat && (
                      <span className="flex w-5 items-center justify-center rounded-lg border border-solid border-primary-500 bg-primary-500 p-0.5 text-[0.5rem] font-medium text-white">
                        {t("dashboard.NDS")}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {(item?.phone || item?.description) && (
              <div className={styles.products__bottom}>
                {item?.phone && (
                  <div className={styles.products__bottom__item}>
                    <PhoneCallIcon />
                    <span>{item?.phone}</span>
                  </div>
                )}
                {item?.description && (
                  <div className={styles.products__bottom__item}>
                    <PencilIcon />
                    <span>{item?.description}</span>
                  </div>
                )}
                {item.rejected_description && (
                  <div className={styles.products__bottom__item}>
                    <XCircleIcon />
                    <span>{item?.rejected_description}</span>
                  </div>
                )}
              </div>
            )}
            {item?.payment_type && (
              <div className="flex items-center gap-2">
                <WalletIcon />
                {item?.payment_type?.name}
              </div>
            )}
          </div>
        ))}
      </div>
    </Spin>
  );

  const offerUsers = (products: ProductOfferModel[]) => (
    <Popover placement="top" trigger={["hover"]} content={productsItems(products)}>
      <div className={styles.products}>
        {products?.slice(0, 4)?.map(item => (
          <div className={styles.products__item} key={item.id}>
            <NotUserImage
              key={item?.id}
              name={item?.name ? item?.name : item?.company_person?.name}
              background={statusBackgroundColors[item.status as keyof typeof statusBackgroundColors]}
            />
          </div>
        ))}
        {products?.length > 4 ? (
          <div className={styles.products__item}>
            <NotUserImage background={notUserBg(products)} title={`+${products?.length - 4}`} />
          </div>
        ) : null}
      </div>
    </Popover>
  );

  const columns: ColumnsType<OfferModel> = [
    {
      title: `${t("dashboard.Resurs turi va nomi")}`,
      render: (record: OfferModel) => (
        <div className="resource">
          <div
            style={{
              borderColor: record?.product?.resource?.color,
              color: record?.product?.resource?.color
            }}
          >
            {record?.product?.resource?.symbol[i18n.language]}
          </div>
          {record?.product?.name[i18n.language] && record?.product?.name[i18n.language]?.length > 40 ? (
            <Popover title={record?.product?.name[i18n.language]} overlayClassName={styles.popover}>
              <span className={styles.bold}>{sliceText(record?.product?.name[i18n.language] || "", 40)}</span>
            </Popover>
          ) : (
            <span className={styles.bold}>{record?.product?.name[i18n.language]}</span>
          )}
        </div>
      ),
      width: "50%",
      fixed: "left"
    },

    {
      title: <OrderSortBy queryParamKey={queryParamsKeys.SORT_UNIT} title={t("dashboard.Birligi")} />,
      render: (record: OfferModel) => record?.unit?.symbol[i18n.language],
      key: "unit"
    },
    {
      title: <OrderSortBy queryParamKey={queryParamsKeys.SORT_QUANTITY} title={t("dashboard.Soni")} />,
      dataIndex: "quantity",
      key: "quantity",
      width: 100
    },
    {
      title: <OrderSortBy queryParamKey={queryParamsKeys.SORT_DELIVERY_DATE} title={t("dashboard.Sanasi")} />,
      key: "delivery_date",
      render: (record: OfferModel) => <DateStatus status={StatusEnums.OPEN} delivery_date={record.delivery_date!} />,
      width: 120
    },
    {
      title: `${t("dashboard.Takliflar")}`,
      render: (_, record) => offerUsers(record?.warehouse_product_offers || []),
      key: "offers",
      width: 160,
      align: "center"
    },
    {
      title: "",
      render: () => (
        <div className={styles.arrow}>
          <ArrowNarrowIcon rotate={180} />
        </div>
      ),
      align: "center"
    }
  ];

  const renderColumns = () => [
    ...filterColumns({
      columns,
      customFields,
      tableConfig: tableConfigData
    })
  ];

  return renderColumns();
};

export default OfferColumns;
