import React from "react";

import { CompanyWidget } from "modules/dashboard";

import MonthlyCalendar from "./monthly";
import YearlyCalendar from "./yearly";

interface Props {
  item: CompanyWidget;
}

const Calendar: React.FC<Props> = ({ item }) => {
  const content = () => {
    if (item.data?.length <= 31)
      return <MonthlyCalendar widgetKey={item.key} data={item.data} id={item.id} name={item.name} />;
    return <YearlyCalendar widgetKey={item.key} data={item.data} id={item.id} name={item.name} />;
  };

  return <div className="h-full w-full">{content()}</div>;
};

export default Calendar;
