import React, { useMemo } from "react";
import { Spin } from "antd";
import { Layout as LayoutType, Responsive, WidthProvider } from "react-grid-layout";

import { isEmptyArr } from "modules/common";
import {
  CompanyWidget,
  defaultWidgetSizes,
  getChangeWidgets,
  useCompanyWidgets,
  useUpdateWidgetProps
} from "modules/dashboard";

import { LoadingIndicator } from "components";

import WidgetWrapper from "./wrapper";

import "react-grid-layout/css/styles.css";
import styles from "./widgets.module.scss";

const Layout = WidthProvider(Responsive);

const Widgets: React.FC = () => {
  const updateWidgetProps = useUpdateWidgetProps();
  const { companyWidgets, isLoading } = useCompanyWidgets({
    enabled: true
  });

  // const generateCalendarWidgetWidth = (chartType: ChartType, widget: Widget) => {
  //   if (chartType === "calendar" && widget?.filter?.special_date?.includes("month")) {
  //     return { w: 14, h: 30 };
  //   }
  //   return defaultWidgetSizes[chartType];
  // };

  const layouts = useMemo(
    (): LayoutType[] =>
      // const oldLayout = JSON.parse(localStorage.getItem(LAYOUT) || "[]") as LayoutType[];

      companyWidgets?.map((item: CompanyWidget) =>
        JSON.parse(
          JSON.stringify({
            ...(JSON.parse(item?.props as unknown as string) as unknown as {
              x: number;
              y: number;
              i: string;
              w: number;
              h: number;
              minH: number;
              maxH: number;
              maxW: number;
              minW: number;
            }),
            minH:
              item?.data?.custom_field_options && item?.data?.custom_field_options?.length > 0
                ? 36
                : defaultWidgetSizes[item.chart_type]?.h,
            h:
              item?.data?.custom_field_options && item?.data?.custom_field_options?.length > 0
                ? 36
                : defaultWidgetSizes[item.chart_type]?.h
          }) ||
            JSON.stringify({
              x: 0,
              y: 0,
              i: item?.id,
              ...(defaultWidgetSizes[item.chart_type] || {
                w: 14,
                h: 20,
                minW: 14,
                minH: 20,
                maxW: 28,
                maxH: 36
              }),
              minH:
                item?.data?.custom_field_options && item?.data?.custom_field_options?.length > 0
                  ? 36
                  : defaultWidgetSizes[item.chart_type]?.h,
              h:
                item?.data?.custom_field_options && item?.data?.custom_field_options?.length > 0
                  ? 36
                  : defaultWidgetSizes[item.chart_type]?.h
            })
        )
      ) || [],
    [companyWidgets]
  );

  const onChangeLayout = (currentLayout: LayoutType[]) => {
    const currentChangeItems = getChangeWidgets(currentLayout);

    if (!isEmptyArr(currentChangeItems)) {
      updateWidgetProps.mutate({
        company_widgets: currentChangeItems
      });
    }
    // localStorage.setItem(LAYOUT, JSON.stringify(currentLayout));
  };

  return (
    <Spin spinning={isLoading} indicator={LoadingIndicator}>
      <div className={styles.widgets}>
        <Layout
          cols={{
            lg: 48,
            md: 48,
            sm: 48,
            xs: 48,
            xxs: 48
          }}
          isDraggable
          isResizable
          rowHeight={1}
          verticalCompact={true}
          preventCollision={false}
          useCSSTransforms
          measureBeforeMount={false}
          onLayoutChange={onChangeLayout}
          draggableHandle=".drag_icon_container"
        >
          {layouts?.map((item, index) => (
            <div key={item.i} data-grid={item} className={styles.grid_item}>
              <WidgetWrapper item={companyWidgets[index]} />
            </div>
          ))}
        </Layout>
      </div>
    </Spin>
  );
};

export default Widgets;
