import { useTranslation } from "react-i18next";
import { BreadcrumbsRoute } from "use-react-router-breadcrumbs";

import { projectsPaths } from "../../../../../routes/projects/projectsPaths";
import { rootPaths } from "../../../../../routes/root/rootPaths";
import { settingsPaths } from "../../../../../routes/settings/settingsPaths";

import { dynamicCrumb } from "./dynamicCrumb";

export const crumbRoutes = (): BreadcrumbsRoute[] => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTranslation();

  return [
    {
      path: rootPaths.PROJECTS,
      breadcrumb: `${t("MainLayoutLinks.Loyiha")}`
    },
    {
      path: rootPaths.PROJECTS + projectsPaths.DETAILED_FOLDER,
      // @ts-ignore
      breadcrumb: ({ match }) => dynamicCrumb(match, "folderId")
    },
    {
      path: rootPaths.PAYMENT,
      breadcrumb: `${t("MainLayoutLinks.Kassa")}`
    },
    {
      path: rootPaths.COUNTERPARTS,
      breadcrumb: `${t("MainLayoutLinks.Kontragent")}`
    },
    {
      path: rootPaths.DETAILED_COUNTERPART,
      breadcrumb: `${t("MainLayoutLinks.Kontragent")}`,
      props: {
        path: rootPaths.COUNTERPARTS
      }
    },
    {
      path: rootPaths.DETAILED_FOLDER_COUNTERPARTS,
      // @ts-ignore
      breadcrumb: ({ match }) => dynamicCrumb(match, "id")
    },
    {
      path: rootPaths.DETAILED_COUNTERPARTS,
      // @ts-ignore
      breadcrumb: ({ match }) => dynamicCrumb(match, "second_id")
    },
    {
      path: rootPaths.FOLDER_DETAIL_COUNTERPARTS,
      // @ts-ignore
      breadcrumb: ({ match }) => dynamicCrumb(match, "id", "second_id")
    },
    {
      path: rootPaths.WAREHOUSE,
      breadcrumb: `${t("MainLayoutLinks.Omborxona")}`
    },
    {
      path: rootPaths.SUPPLY,
      breadcrumb: `${t("MainLayoutLinks.Ta'minot")}`
    },
    {
      path: rootPaths.INVENTORY,
      breadcrumb: `${t("MainLayoutLinks.Inventar")}`
    },
    {
      path: rootPaths.IMPOST,
      breadcrumb: `${t("MainLayoutLinks.Monitoring")}`
    },
    {
      path: rootPaths.DASHBOARD,
      breadcrumb: `${t("MainLayoutLinks.Dashboard")}`
    },
    {
      path: rootPaths.WORKS,
      breadcrumb: t("MainLayoutLinks.Ishlar")
    },
    {
      path: rootPaths.DETAILED_INVENTORY,
      // @ts-ignore
      breadcrumb: ({ match }) => dynamicCrumb(match, "id")
    },
    {
      path: rootPaths.SETTINGS,
      breadcrumb: `${t("MainLayoutLinks.Sozlamalar")}`,
      props: {
        path: rootPaths.SETTINGS + settingsPaths.PRODUCTS
      },
      children: [
        {
          path: rootPaths.SETTINGS + settingsPaths.PRODUCTS,
          breadcrumb: `${t("Monitoring.Mahsulotlar")}`
        },
        {
          path: rootPaths.SETTINGS + settingsPaths.ADMINS,
          breadcrumb: `${t("Xodimlar.Xodimlar")}`
        },
        {
          path: rootPaths.SETTINGS + settingsPaths.ADMINS_ROLES,
          breadcrumb: `${t("Xodimlar.Lavozimlar")}`
        },
        {
          path: rootPaths.SETTINGS + settingsPaths.INTERFACE,
          breadcrumb: `${t("settings.interface.Interfeys")}`
        },
        {
          path: rootPaths.SETTINGS + settingsPaths.CASH,
          breadcrumb: `${t("Kassa.Kassa")}`
        },
        {
          path: rootPaths.SETTINGS + settingsPaths.WAREHOUSE,
          breadcrumb: `${t("settings.interface.Omborxona")}`
        },
        {
          path: rootPaths.SETTINGS + settingsPaths.FINANCIAL,
          breadcrumb: `${t("Xarajat.Xarajat va daromad")}`
        },
        {
          path: rootPaths.SETTINGS + settingsPaths.INTEGRATION,
          breadcrumb: `${t("Monitoring.Integratsiya")}`
        },
        {
          path: rootPaths.SETTINGS + settingsPaths.FINANCE,
          breadcrumb: `${t("settings.interface.Moliya")}`
        },
        {
          path: rootPaths.SETTINGS + settingsPaths.BACK_UP,
          breadcrumb: `${t("Monitoring.Back-up")}`
        },
        {
          path: rootPaths.SETTINGS + settingsPaths.CUSTOM_FIELD,
          breadcrumb: `${t("settings.interface.O'zgaruvchilar")}`
        },
        {
          path: rootPaths.SETTINGS + settingsPaths.TEMPLATE,
          breadcrumb: `${t("settings.interface.Hujjatlar")}`
        },
        {
          path: rootPaths.SETTINGS + settingsPaths.BUILDERS,
          breadcrumb: t("settings.Quruvchi")
        },
        {
          path: rootPaths.SETTINGS + settingsPaths.ARCHIVE,
          breadcrumb: `${t("settings.interface.Arxiv")}`
        },
        {
          path: rootPaths.SETTINGS + settingsPaths.MESSAGE,
          breadcrumb: `${t("settings.interface.SMS tizimi")}`
        },
        {
          path: rootPaths.SETTINGS + settingsPaths.SUBCATEGORY,
          // @ts-ignore
          breadcrumb: ({ match }) => dynamicCrumb(match, "id")
        },
        {
          path: rootPaths.SETTINGS + settingsPaths.DETAILED_SUBCATEGORY,
          // @ts-ignore
          breadcrumb: ({ match }) => dynamicCrumb(match, "second_id")
        },
        {
          path: rootPaths.SETTINGS + settingsPaths.DETAILED_CASH,
          // @ts-ignore
          breadcrumb: ({ match }) => dynamicCrumb(match, "id")
        },
        {
          path: rootPaths.SETTINGS + settingsPaths.DETAILED_WAREHOUSE,
          // @ts-ignore
          breadcrumb: ({ match }) => dynamicCrumb(match, "id")
        }
      ]
    }
  ];
};
