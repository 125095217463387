import React from "react";
import { Form, Input } from "antd";
import { useTranslation } from "react-i18next";

const { Item } = Form;

export interface Props {
  className: string;
  inputClassName: string;
}

const BankAccountNumberItem: React.FC<Props> = ({ className, inputClassName }) => {
  const { t } = useTranslation();

  return (
    <div className={className}>
      <Item name="bank_name" label={t("builder.Bank nomi")} className={inputClassName}>
        <Input placeholder={t("builder.Kiriting..")} />
      </Item>
      <Item name="account_number" label={t("builder.Hisob raqami")} className={inputClassName}>
        <Input placeholder={t("builder.Kiriting..")} />
      </Item>
    </div>
  );
};

export default BankAccountNumberItem;
