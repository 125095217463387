import React, { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Form, Input, Modal, Select, Spin, Switch } from "antd";
import { Rule } from "antd/es/form";
import { useTranslation } from "react-i18next";
import ReactInputMask from "react-input-mask";

import { useAppSelector } from "../../../../../../hooks/redux";
import { settingsActions } from "../../../../../../store/reducers/settingsReducer";
import SelectSuffixIcon from "../../../../../app/assets/icons/SelectSuffixIcon";
import ConditionalRender from "../../../../../app/components/conditional-render/ConditionalRender";
import SelectNotContent from "../../../../../app/components/select-not-content/SelectNotContent";
import UploadFile from "../../../../../app/components/upload-file/UploadFile";
import { formRules } from "../../../../../app/utils/constants/formRules";
import { PHONE_MASK } from "../../../../../app/utils/constants/phoneMask";
import { lengthValidator } from "../../../../../app/utils/helpers/lengthValidator";
import { replacePhone } from "../../../../../app/utils/helpers/replacePhone";
import { selectFilterOption } from "../../../../../app/utils/helpers/selectFilterOption";
import { Status } from "../../../../../app/utils/models/status";
import { useCreateAdmin, useUpdateAdmin, useUpdateUser } from "../../../../service/mutations";
import { useGetAllRoles, useUserView } from "../../../../service/queries";
import { UYQUR_COMPANY_SUPER_ADMIN } from "../../../../utils/constants/roleKey";
import { AdminFormModel } from "../../../../utils/models/admin/adminFormModel";
import SettingsRolesModal from "../../roles/modal/SettingsRolesModal";

import styles from "./settingsAdminsModal.module.scss";

const { Item } = Form;
const { Option } = Select;
const { Password } = Input;

const SettingsAdminsModal: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setAdminModal } = settingsActions;
  const { data } = useGetAllRoles();
  const [form] = Form.useForm<AdminFormModel>();
  const [image, setImage] = useState<Blob | undefined | string>();

  const {
    visible,
    isEdited,
    afterFunc,
    initialName,
    data: oneAdmin,
    full_name,
    isCreate
  } = useAppSelector(state => state.settingsReducer.admin);

  const { data: currentUser, isFetching: isLoading } = useUserView(visible && !oneAdmin && !isCreate);

  // after open modal
  const onAfterOpen = () => {
    if (visible) {
      if (oneAdmin) {
        form.setFieldsValue({
          ...oneAdmin,
          role_id: oneAdmin?.role?.name === UYQUR_COMPANY_SUPER_ADMIN ? 0 : oneAdmin.role.id,
          status: oneAdmin.status === Status.ACTIVE,
          password: ""
        });
      }
      if (currentUser && currentUser?.id) {
        form.setFieldsValue({
          ...currentUser,
          status: currentUser.status === Status.ACTIVE,
          password: "",
          full_name: currentUser?.full_name
        } as never);
      }
      if (full_name) {
        form.setFieldValue(["full_name"], full_name);
      } else {
        form.setFieldValue(["full_name"], oneAdmin?.full_name ? oneAdmin?.full_name : initialName);
      }

      setImage(oneAdmin?.image || (currentUser?.image as never));
    } else {
      form.resetFields();
      setImage(undefined);
    }
  };

  // cancel modal
  const onCancel = () => {
    dispatch(
      setAdminModal({
        visible: false,
        data: undefined,
        full_name: ""
      })
    );
  };

  const createAdmin = useCreateAdmin(onCancel);
  const updateAdmin = useUpdateAdmin(onCancel);
  const updateCurrentUser = useUpdateUser(onCancel);

  // on finish form
  const onFinish = (values: AdminFormModel) => {
    const formData = new FormData();

    Object.keys(values).forEach(key => {
      const value = values[key as keyof typeof values];

      if (value || typeof value === "boolean") {
        if (key === "phone") {
          formData.append(key, replacePhone(value as string));
        } else if (key === "status") {
          formData.append(key, value ? Status.ACTIVE : Status.PASSIVE);
        } else {
          formData.append(key, value as string | Blob);
        }
      }
    });

    if (typeof values.image === "string") {
      formData.delete("image");
    }

    if (values.image === null) {
      formData.append("image", "");
    }

    if (oneAdmin?.id) {
      formData.append("id", String(oneAdmin.id));
      updateAdmin.mutate(formData);
    } else if (!currentUser?.id) {
      createAdmin.mutateAsync(formData).then(data => {
        afterFunc && afterFunc(data.data.id);
      });
    } else {
      updateCurrentUser.mutate({ data: formData });
    }
  };

  const loginRule: Rule[] = [
    ...formRules()!,
    {
      validator: (_, value: string) => (value?.length < 5 ? Promise.reject() : Promise.resolve())
    }
  ];

  const passwordRule: Rule[] = [
    {
      validator: (_, value: string) => {
        const lengthValid = value?.length < 5;

        if (oneAdmin?.id) {
          if (value?.length > 0) return lengthValid ? Promise.reject() : Promise.resolve();
          return Promise.resolve();
        }
        return !value || lengthValid ? Promise.reject() : Promise.resolve();
      }
    }
  ];

  const onPastePhone = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const value = replacePhone(event.clipboardData.getData("Text"));

    form.setFieldValue("phone", value);
    event.preventDefault();
  };

  // searching through roles
  const [roleSearchText, setRoleSearchText] = useState<string>();
  const [openCreateRoleModal, setOpenCreateRoleModal] = useState<{
    visible: boolean;
    id?: number;
    isView?: boolean;
    name?: string;
  }>({
    visible: false
  });

  const onSearchRole = (value: string) => {
    setRoleSearchText(value);
  };
  const onOpenCreateRoleModal = () => {
    setOpenCreateRoleModal({
      visible: true,
      isView: false,
      name: roleSearchText
    });
  };

  const generateRoleData = useMemo(() => {
    if (roleSearchText) {
      return data?.data.filter(item => item.name.toLowerCase().includes(roleSearchText.toLowerCase()));
    }
    return data?.data;
  }, [roleSearchText, data]);

  const roleSelectOptions = useMemo(() => {
    const role = oneAdmin?.role;

    if (role && role?.name === UYQUR_COMPANY_SUPER_ADMIN) {
      return (
        <Select value={0} disabled suffixIcon={null}>
          <Option value={0}>{UYQUR_COMPANY_SUPER_ADMIN}</Option>;
        </Select>
      );
    }
    return (
      <Select
        disabled={!isEdited}
        autoClearSearchValue={false}
        suffixIcon={<SelectSuffixIcon />}
        placeholder={t("Xodimlar.Lavozim")}
        onSearch={onSearchRole}
        showSearch
        filterOption={selectFilterOption}
        searchValue={roleSearchText}
        notFoundContent={
          <SelectNotContent
            title="Lavozim"
            description="Lavozimlar mavjud emas,iltimos yangi qo'shing!"
            action={onOpenCreateRoleModal}
          />
        }
      >
        {generateRoleData?.map((item, index) => (
          <Option
            value={item.id}
            key={index}
            props={{
              name: item.name
            }}
          >
            {item.name}
          </Option>
        ))}
      </Select>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generateRoleData, oneAdmin, isEdited, t, roleSearchText]);

  return (
    <Modal
      centered
      open={visible}
      onCancel={onCancel}
      onOk={() => form.submit()}
      afterOpenChange={onAfterOpen}
      className={styles.admin_modal}
      okButtonProps={{
        loading: createAdmin.isLoading || updateAdmin.isLoading || updateCurrentUser.isLoading,
        className: isEdited ? "" : "d_n"
      }}
      title={t("Xodimlar.Xodim")}
      cancelText={t("products.Yopish")}
      okText={t("products.Saqlash")}
    >
      <Spin spinning={currentUser && currentUser?.id ? isLoading : false}>
        <Form form={form} layout="vertical" onFinish={onFinish} autoComplete="nope">
          <ConditionalRender if={oneAdmin?.image || isEdited || currentUser?.id}>
            <UploadFile form={form} image={image} setImage={setImage} disabled={!isEdited} />
          </ConditionalRender>
          <Item label={t("Xodimlar.To'liq ism")} name="full_name" rules={formRules()}>
            <Input placeholder={t("Xodimlar.Ismni kiriting")} disabled={!isEdited} />
          </Item>
          {!currentUser ||
            (!currentUser?.id && (
              <Item name="role_id" label={t("Xodimlar.Lavozim")} rules={formRules()}>
                {roleSelectOptions}
              </Item>
            ))}
          <Item name="phone" label={t("Xodimlar.Telefon")} rules={[lengthValidator(12), ...formRules()!]}>
            <ReactInputMask
              maskChar=""
              mask={PHONE_MASK}
              autoComplete="nope"
              disabled={!isEdited}
              onPaste={onPastePhone}
              placeholder="+998 99 999 99 99"
              className="ant-input mask css-dev-only-do-not-override-tacjbp"
            />
          </Item>
          <Item name="login" label={t("Xodimlar.Login")} rules={loginRule}>
            <Input disabled={!isEdited} placeholder={t("Xodimlar.Loginni kiriting")} />
          </Item>
          <Item name="password" label={t("Xodimlar.Parol")} rules={passwordRule}>
            <Password disabled={!isEdited} autoComplete="new-password" placeholder={t("Xodimlar.Parol")} />
          </Item>
          <Item name="status" initialValue={true} valuePropName="checked" label={t("Xodimlar.Joriy holati")}>
            <Switch disabled={!isEdited} />
          </Item>
        </Form>
      </Spin>
      {openCreateRoleModal.visible && (
        <SettingsRolesModal data={openCreateRoleModal} setData={setOpenCreateRoleModal} />
      )}
    </Modal>
  );
};

export default SettingsAdminsModal;
