import { useMutation, useQueryClient } from "@tanstack/react-query";
import { message } from "antd";
import { IUnitReqModel } from "features/projects/utils/models/unitReqModel";
import { OfferFormProductModel } from "features/supply/utils/models/OfferFormModel";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

import { useQueryParams } from "../../../hooks/useQueryParams";
import { queryKeys } from "../../app/utils/constants/queryKeys";
import { queryParamsKeys } from "../../app/utils/constants/queryParamsKeys";
import { responseMessages } from "../../app/utils/constants/responseMessages";
import $api from "../../app/utils/helpers/axiosInstance";
import { errorHandler } from "../../app/utils/helpers/errorHandler";
import { ErrorRes, SuccessRes } from "../../app/utils/models/responseType";
import { warehouseQueryNames } from "../../warehouse/utils/constants/warehouseQueryNames";
import { settingsEndPoints } from "../utils/constants/settingsEndPoints";
import { settingsQueryNames } from "../utils/constants/settingsQueryNames";
import { CashBodyModel } from "../utils/models/cash/cashBodyModel";
import { CurrencyFormType } from "../utils/models/currency/currencyFormModel";
import { ReqDataCustomField } from "../utils/models/custom-field/reqDataCustomField";
import { FinancialCreateModel, FinancialUpdateModel, VatModel } from "../utils/models/financialModel";
import { PaymentTypeBodyModel } from "../utils/models/payment-type/paymentTypeBodyMdel";
import { ProductBodyModel } from "../utils/models/product/productBodyModel";
import { RolesBodyModel } from "../utils/models/roles/rolesBodyModel";
import { ReqDataTemplate } from "../utils/models/template/reqDataTemplate";
import { WarehouseFolderModel } from "../utils/models/warehouse/warehouseFolderModel";
import { WarehouseFormModel } from "../utils/models/warehouse/warehouseFormModel";

export function useDeleteCash() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, { id?: number }>(
    async req => {
      const res = await $api.delete(`cash/delete?id=${req.id}`);

      return res.data;
    },
    {
      onSuccess: async res => {
        message.success(res.message[i18n.language]);
        await qc.invalidateQueries({
          queryKey: ["cash"],
          refetchType: "active"
        });
        await qc.invalidateQueries({
          queryKey: ["detailed-cash-folder"],
          refetchType: "active"
        });
      },
      onError: errorHandler
    }
  );
}

export function useCreateCash() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, CashBodyModel>(
    async req => {
      const res = await $api.post("cash/create", req);

      return res.data;
    },
    {
      onSuccess: async res => {
        message.success(res.message[i18n.language]);
        await qc.invalidateQueries({
          queryKey: ["cash"],
          refetchType: "active"
        });
        await qc.invalidateQueries({
          queryKey: ["detailed-cash-folder"],
          refetchType: "active"
        });
        await qc.invalidateQueries(["cash-tree"]);
      },
      onError: errorHandler
    }
  );
}

export function useUpdateCash() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, CashBodyModel & { id: number }>(
    async req => {
      const res = await $api.put("cash/update", req);

      return res.data;
    },
    {
      onSuccess: async res => {
        message.success(res.message[i18n.language]);
        await qc.invalidateQueries({
          queryKey: ["cash"],
          refetchType: "active"
        });
        await qc.invalidateQueries({
          queryKey: ["detailed-cash-folder"],
          refetchType: "active"
        });
      },
      onError: errorHandler
    }
  );
}

// CREATE cash folder
export function useCreateCashFolder() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, { name: string }>(
    async req => {
      const res = await $api.post("cash-folder/create", req);

      return res.data;
    },
    {
      onSuccess: async res => {
        message.success(res.message[i18n.language]);
        await qc.invalidateQueries(["cash"]);
      },
      onError: errorHandler
    }
  );
}

// UPDATE cash folder
export function useUpdateCashFolder() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, { id: number; name?: string; color?: string }>(
    async req => {
      const res = await $api.put("cash-folder/update", req);

      return res.data;
    },
    {
      onSuccess: async res => {
        message.success(res.message[i18n.language]);
        await qc.invalidateQueries(["cash"]);
      },
      onError: errorHandler
    }
  );
}

// DELETE cash folder
export function useDeleteCashFolder() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, { id?: number }>(
    async req => {
      const res = await $api.delete(`cash-folder/delete?id=${req.id}`);

      return res.data;
    },
    {
      onSuccess: async res => {
        message.success(res.message[i18n.language]);
        await qc.invalidateQueries(["cash"]);
      },
      onError: errorHandler
    }
  );
}

// CREATE role
export function useCreateRole() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, RolesBodyModel>(
    async req => {
      const res = await $api.post(settingsEndPoints.ROLE_CREATE, req);

      return res.data;
    },
    {
      onSuccess: async res => {
        message.success(res.message[i18n.language]);
        await qc.invalidateQueries([settingsQueryNames.ROLE_VIEW]);
        await qc.invalidateQueries([settingsQueryNames.ROLE_SELECT]);
      }
    }
  );
}

// UPDATE role
export function useUpdateRole() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, RolesBodyModel & { id: number }>(
    async req => {
      const res = await $api.put(settingsEndPoints.ROLE_UPDATE, req);

      return res.data;
    },
    {
      onSuccess: async res => {
        message.success(res.message[i18n.language]);
        await qc.invalidateQueries([settingsQueryNames.ROLE_VIEW]);
        await qc.invalidateQueries([settingsQueryNames.ROLE_SELECT]);
      }
    }
  );
}

// DELETE admin
export function useDeleteAdmin() {
  const qc = useQueryClient();

  return useMutation<SuccessRes, ErrorRes, number>(
    async id => {
      const res = await $api.delete(`${settingsEndPoints.ADMIN_DELETE}?${queryParamsKeys.ID}=${id}`);

      return res.data;
    },
    {
      onSuccess: async () => {
        message.success(responseMessages.DELETED);
        await qc.invalidateQueries([settingsQueryNames.ADMIN_VIEW]);
      },
      onError: errorHandler
    }
  );
}

// CREATE admin
export function useCreateAdmin(onClose: () => void) {
  const qc = useQueryClient();

  return useMutation<SuccessRes<{ id: number }>, ErrorRes, FormData>(
    async data => {
      const res = await $api.post(settingsEndPoints.ADMIN_CREATE, data);

      return res.data;
    },
    {
      onSuccess: async () => {
        message.success(responseMessages.CREATED);
        await qc.invalidateQueries([settingsQueryNames.ADMIN_VIEW]);
        await qc.invalidateQueries(["user-select"]);
        onClose();
      },
      onError: errorHandler
    }
  );
}

// UPDATE admin
export function useUpdateAdmin(onClose: () => void) {
  const qc = useQueryClient();

  return useMutation<SuccessRes, ErrorRes, FormData>(
    async data => {
      const res = await $api.post(settingsEndPoints.ADMIN_UPDATE, data);

      return res.data;
    },
    {
      onSuccess: async () => {
        message.success(responseMessages.UPDATED);
        onClose();
        await qc.invalidateQueries([settingsQueryNames.ADMIN_VIEW]);
      },
      onError: errorHandler
    }
  );
}

// DELETE role
export function useDeleteRole() {
  const qc = useQueryClient();

  return useMutation<SuccessRes, ErrorRes, number>(
    async id => {
      const res = await $api.delete(`${settingsEndPoints.ROLE_DELETE}?${queryParamsKeys.ID}=${id}`);

      return res.data;
    },
    {
      onSuccess: async () => {
        message.success(responseMessages.DELETED);
        await qc.invalidateQueries([settingsQueryNames.ROLE_VIEW]);
      },
      onError: errorHandler
    }
  );
}

// CREATE financial
export function useCreateFinancial(onClose: () => void) {
  const qc = useQueryClient();

  return useMutation<SuccessRes, ErrorRes, FinancialCreateModel>(
    async data => {
      const res = await $api.post(settingsEndPoints.FINANCIAL_CREATE, data);

      return res.data;
    },
    {
      onSuccess: async () => {
        message.success(responseMessages.CREATED);
        onClose();
        await qc.invalidateQueries([settingsQueryNames.FINANCIAL_VIEW]);
      },
      onError: errorHandler
    }
  );
}

// UPDATE financial
export function useUpdateFinancial(onClose: () => void) {
  const qc = useQueryClient();

  return useMutation<SuccessRes, ErrorRes, FinancialUpdateModel>(
    async data => {
      const res = await $api.put(settingsEndPoints.FINANCIAL_UPDATE, data);

      return res.data;
    },
    {
      onSuccess: async () => {
        message.success(responseMessages.UPDATED);
        onClose();
        await qc.invalidateQueries([settingsQueryNames.FINANCIAL_VIEW]);
      },
      onError: errorHandler
    }
  );
}

// DELETE financial
export function useDeleteFinancial() {
  const qc = useQueryClient();

  return useMutation<SuccessRes, ErrorRes, number>(
    async id => {
      const res = await $api.delete(`${settingsEndPoints.FINANCIAL_DELETE}?${queryParamsKeys.ID}=${id}`);

      return res.data;
    },
    {
      onSuccess: async () => {
        message.success(responseMessages.DELETED);
        await qc.invalidateQueries([settingsQueryNames.FINANCIAL_VIEW]);
      },
      onError: errorHandler
    }
  );
}

export function useCreateCurrency() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, CurrencyFormType>(
    async data => {
      const res = await $api.post(settingsEndPoints.CURRENCIES_CREATE, data);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async data => {
        message.success(data.message[i18n.language]);
        await qc.invalidateQueries([settingsQueryNames.CURRENCIES_VIEW]);
      }
    }
  );
}

export function useUpdateCurrency() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, CurrencyFormType>(
    async data => {
      const res = await $api.put(settingsEndPoints.CURRENCIES_UPDATE, data);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async data => {
        message.success(data.message[i18n.language]);
        await qc.invalidateQueries([settingsQueryNames.CURRENCIES_VIEW]);
      }
    }
  );
}

export function useChangeBasicCurrency() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, number>(
    async id => {
      const res = await $api.post(`${settingsEndPoints.CURRENCIES_CHANGE_BASIC}?currency_id=${id}`);

      return res.data;
    },
    {
      onSuccess: async data => {
        message.success(data.message[i18n.language]);
        await qc.invalidateQueries([settingsQueryNames.CURRENCIES_VIEW]);
      },
      onError: errorHandler
    }
  );
}

export function useAutoRefreshCurrency() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes>(
    async () => {
      const res = await $api.post(settingsEndPoints.CURRENCIES_AUTO_REFRESH);

      return res.data;
    },
    {
      onSuccess: async data => {
        message.success(data.message[i18n.language]);
        await qc.invalidateQueries([settingsQueryNames.CURRENCIES_VIEW]);
      },
      onError: errorHandler
    }
  );
}

export function useDeleteCurrency() {
  const qc = useQueryClient();

  return useMutation<SuccessRes, ErrorRes, number>(
    async id => {
      const res = await $api.delete(`${settingsEndPoints.CURRENCIES_DELETE}?currency_id=${id}`);

      return res.data;
    },
    {
      onSuccess: async () => {
        message.success(responseMessages.DELETED);
        await qc.invalidateQueries([settingsQueryNames.CURRENCIES_VIEW]);
      },
      onError: errorHandler
    }
  );
}

export function useCreatePaymentType() {
  const { i18n } = useTranslation();
  const qc = useQueryClient();

  return useMutation<SuccessRes, ErrorRes, PaymentTypeBodyModel>(
    async data => {
      const res = await $api.post(settingsEndPoints.PAYMENT_TYPES_CREATE, data);

      return res.data;
    },
    {
      onSuccess: async data => {
        message.success(data.message[i18n.language]);
        await qc.invalidateQueries([settingsQueryNames.PAYMENT_TYPE_VIEW]);
      },
      onError: errorHandler
    }
  );
}

export function useUpdatePaymentType() {
  const { i18n } = useTranslation();
  const qc = useQueryClient();

  return useMutation<SuccessRes, ErrorRes, PaymentTypeBodyModel>(
    async data => {
      const res = await $api.put(settingsEndPoints.PAYMENT_TYPES_UPDATE, data);

      return res.data;
    },
    {
      onSuccess: async data => {
        message.success(data.message[i18n.language]);
        await qc.invalidateQueries([settingsQueryNames.PAYMENT_TYPE_VIEW]);
      },
      onError: errorHandler
    }
  );
}

export function useDeletePaymentType() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, number>(
    async id => {
      const res = await $api.delete(`${settingsEndPoints.PAYMENT_TYPES_DELETE}?id=${id}`);

      return res.data;
    },
    {
      onSuccess: async data => {
        message.success(data.message[i18n.language]);
        await qc.invalidateQueries([settingsQueryNames.PAYMENT_TYPE_VIEW]);
      },
      onError: errorHandler
    }
  );
}

export function useCreateWarehouseFolder() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, { name: string }>(
    async data => {
      const res = await $api.post(settingsEndPoints.WAREHOUSE_FOLDER_CREATE, data);

      return res.data;
    },
    {
      onSuccess: async data => {
        message.success(data.message[i18n.language]);
        await qc.invalidateQueries([settingsQueryNames.WAREHOUSES_VIEW]);
      },
      onError: errorHandler
    }
  );
}

export function useUpdateWarehouseFolder() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, Partial<WarehouseFolderModel>>(
    async data => {
      const res = await $api.put(settingsEndPoints.WAREHOUSE_FOLDERS_UPDATE, data);

      return res.data;
    },
    {
      onSuccess: async data => {
        message.success(data.message[i18n.language]);
        await qc.invalidateQueries([settingsQueryNames.WAREHOUSES_VIEW]);
      },
      onError: errorHandler
    }
  );
}

export function useDeleteWarehouseFolder() {
  const { i18n } = useTranslation();
  const qc = useQueryClient();

  return useMutation<SuccessRes, ErrorRes, number>(
    async id => {
      const res = await $api.delete(`${settingsEndPoints.WAREHOUSE_FOLDER_DELETE}?id=${id}`);

      return res.data;
    },
    {
      onSuccess: async data => {
        message.success(data.message[i18n.language]);
        await qc.invalidateQueries([settingsQueryNames.WAREHOUSES_VIEW]);
      },
      onError: errorHandler
    }
  );
}

export function useDeleteWarehouse() {
  const { i18n } = useTranslation();
  const qc = useQueryClient();

  return useMutation<SuccessRes, ErrorRes, number>(
    async id => {
      const res = await $api.delete(`${settingsEndPoints.WAREHOUSE_DELETE}?id=${id}`);

      return res.data;
    },
    {
      onSuccess: async data => {
        message.success(data.message[i18n.language]);
        await qc.invalidateQueries([settingsQueryNames.WAREHOUSES_VIEW]);
      },
      onError: errorHandler
    }
  );
}

export function useCreateWarehouse() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, WarehouseFormModel>(
    async data => {
      const res = await $api.post(settingsEndPoints.WAREHOUSE_CREATE, data);

      return res.data;
    },
    {
      onSuccess: async data => {
        message.success(data.message[i18n.language]);
        await qc.invalidateQueries([settingsQueryNames.WAREHOUSES_VIEW]);
        await qc.invalidateQueries({
          queryKey: [warehouseQueryNames.WAREHOUSE_SELECT],
          refetchType: "active"
        });
        await qc.invalidateQueries(["warehouse-tree"]);
      },
      onError: errorHandler
    }
  );
}

export function useUpdateWarehouse() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, WarehouseFormModel>(
    async data => {
      const res = await $api.put(settingsEndPoints.WAREHOUSE_UPDATE, data);

      return res.data;
    },
    {
      onSuccess: async data => {
        message.success(data.message[i18n.language]);
        await qc.invalidateQueries([settingsQueryNames.WAREHOUSES_VIEW]);
      },
      onError: errorHandler
    }
  );
}

export function useCreateCategory() {
  const { i18n } = useTranslation();
  const qc = useQueryClient();

  return useMutation<SuccessRes, ErrorRes, { name: { [key: string]: string } }>(
    async data => {
      const res = await $api.post(settingsEndPoints.CATEGORY_CREATE, data);

      return res.data;
    },
    {
      onSuccess: async data => {
        message.success(data.message[i18n.language]);
        await qc.invalidateQueries([settingsQueryNames.CATEGORY_VIEW]);
      },
      onError: errorHandler
    }
  );
}

export function useUpdateCategory() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, { id: number; name?: { [key: string]: string }; color?: string }>(
    async data => {
      const res = await $api.put(settingsEndPoints.CATEGORY_UPDATE, data);

      return res.data;
    },
    {
      onSuccess: async data => {
        message.success(data.message[i18n.language]);
        await qc.invalidateQueries([settingsQueryNames.CATEGORY_VIEW]);
      },
      onError: errorHandler
    }
  );
}

export function useDeleteCategory() {
  const { i18n } = useTranslation();
  const qc = useQueryClient();

  return useMutation<SuccessRes, ErrorRes, number>(
    async id => {
      const res = await $api.delete(`${settingsEndPoints.CATEGORY_DELETE}?id=${id}`);

      return res.data;
    },
    {
      onSuccess: async data => {
        message.success(data.message[i18n.language]);
        await qc.invalidateQueries([settingsQueryNames.WAREHOUSES_VIEW]);
      },
      onError: errorHandler
    }
  );
}

export function useCreateSubCategory() {
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, { name: { [key: string]: string }; category_id: string }>(
    async data => {
      const res = await $api.post(settingsEndPoints.SUB_CATEGORY_CREATE, data);

      return res.data;
    },
    {
      onSuccess: async data => {
        message.success(data.message[i18n.language]);
      },
      onError: errorHandler
    }
  );
}

export function useUpdateSubCategory() {
  const { i18n } = useTranslation();
  const qc = useQueryClient();

  return useMutation<
    SuccessRes,
    ErrorRes,
    {
      id: number;
      name?: { [key: string]: string };
      color?: string;
      category_id: string;
    }
  >(
    async data => {
      const res = await $api.put(settingsEndPoints.SUB_CATEGORY_UPDATE, data);

      return res.data;
    },
    {
      onSuccess: async data => {
        message.success(data.message[i18n.language]);
        await qc.invalidateQueries([settingsQueryNames.SUB_CATEGORIES_VIEW]);
      },
      onError: errorHandler
    }
  );
}

export function useDeleteSubCategory() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, { id: number; category_id: string }>(
    async data => {
      const res = await $api.delete(`${settingsEndPoints.SUB_CATEGORY_DELETE}?id=${data.id}`);

      return res.data;
    },
    {
      onSuccess: async (data, variables) => {
        message.success(data.message[i18n.language]);
        await qc.invalidateQueries([settingsQueryNames.SUB_CATEGORIES_VIEW, variables.category_id]);
      },
      onError: errorHandler
    }
  );
}

export function useDeleteProduct() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, { id: number; category_id?: string; sub_category_id?: string }>(
    async data => {
      const res = await $api.delete(`${settingsEndPoints.PRODUCT_DELETE}?id=${data.id}`);

      return res.data;
    },
    {
      onSuccess: async data => {
        message.success(data.message[i18n.language]);

        await qc.invalidateQueries([settingsQueryNames.SUB_CATEGORY_PRODUCTS_VIEW]);
        await qc.invalidateQueries([settingsQueryNames.CATEGORY_PRODUCTS_VIEW]);
        await qc.invalidateQueries([settingsQueryNames.CATEGORY_PRODUCTS_ALL_VIEW]);
      },
      onError: errorHandler
    }
  );
}

export function useCreateProduct() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<
    SuccessRes<{
      id: number;
      unit: { id: number };
    }>,
    ErrorRes,
    ProductBodyModel
  >(
    async data => {
      const res = await $api.post(settingsEndPoints.PRODUCT_CREATE, data);

      return res.data;
    },
    {
      onSuccess: async data => {
        message.success(data.message[i18n.language]);

        await qc.invalidateQueries([settingsQueryNames.SUB_CATEGORY_PRODUCTS_VIEW]);
        await qc.invalidateQueries([settingsQueryNames.CATEGORY_PRODUCTS_VIEW]);
        await qc.invalidateQueries([settingsQueryNames.CATEGORY_PRODUCTS_ALL_VIEW]);
      },
      onError: errorHandler
    }
  );
}

export function useUpdateProduct() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();
  const { searchParams } = useQueryParams();

  return useMutation<SuccessRes, ErrorRes, ProductBodyModel>(
    async data => {
      const res = await $api.put(settingsEndPoints.PRODUCT_UPDATE, data);

      return res.data;
    },
    {
      onSuccess: async (data, variables) => {
        message.success(data.message[i18n.language]);
        await qc.invalidateQueries([settingsQueryNames.SUB_CATEGORIES_VIEW]);
        await qc.invalidateQueries([settingsQueryNames.SUB_CATEGORY_PRODUCTS_VIEW]);
        await qc.invalidateQueries([settingsQueryNames.CATEGORY_PRODUCTS_VIEW]);
        await qc.invalidateQueries([settingsQueryNames.CATEGORY_PRODUCTS_ALL_VIEW]);
      },
      onError: errorHandler
    }
  );
}

export function useImportProducts() {
  const { i18n } = useTranslation();
  const qc = useQueryClient();

  return useMutation<SuccessRes, ErrorRes, { id?: number }>(
    async data => {
      const res = await $api.post(settingsEndPoints.PRODUCT_IMPORT_DEV, data);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async data => {
        message.success(data.message[i18n.language]);
        await qc.invalidateQueries([settingsQueryNames.CATEGORY_VIEW]);
      }
    }
  );
}

export function useCreateCustomField() {
  const { i18n } = useTranslation();
  const qc = useQueryClient();

  return useMutation<SuccessRes, ErrorRes, ReqDataCustomField>(
    async data => {
      const res = await $api.post(settingsEndPoints.CUSTOM_FIELD_CREATE, data);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async data => {
        message.success(data.message[i18n.language]);
        await qc.invalidateQueries([settingsQueryNames.CUSTOM_FIELDS_VIEW]);
        await qc.invalidateQueries([queryKeys.CUSTOM_FIELD_SELECT]);
      }
    }
  );
}

export function useUpdateCustomField() {
  const { i18n } = useTranslation();
  const qc = useQueryClient();

  return useMutation<SuccessRes, ErrorRes, ReqDataCustomField>(
    async data => {
      const res = await $api.put(settingsEndPoints.CUSTOM_FIELD_UPDATE, data);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async data => {
        message.success(data.message[i18n.language]);
        await qc.invalidateQueries([settingsQueryNames.CUSTOM_FIELDS_VIEW]);
        await qc.invalidateQueries([queryKeys.CUSTOM_FIELD_SELECT]);
      }
    }
  );
}

export function useDeleteCustomField() {
  const { i18n } = useTranslation();
  const qc = useQueryClient();

  return useMutation<SuccessRes, ErrorRes, number>(
    async id => {
      const res = await $api.delete(`${settingsEndPoints.CUSTOM_FIELD_DELETE}?id=${id}`);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async data => {
        message.success(data.message[i18n.language]);
        await qc.invalidateQueries([settingsQueryNames.CUSTOM_FIELDS_VIEW]);
        await qc.invalidateQueries([queryKeys.CUSTOM_FIELD_SELECT]);
      }
    }
  );
}

export function useCreateTemplate() {
  const { i18n } = useTranslation();
  const qc = useQueryClient();

  return useMutation<SuccessRes, ErrorRes, ReqDataTemplate>(
    async data => {
      const res = await $api.post(settingsEndPoints.TEMPLATE_CREATE, data);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async data => {
        message.success(data.message[i18n.language]);
        await qc.invalidateQueries([settingsQueryNames.TEMPLATES_VIEW]);
      }
    }
  );
}

export function useUpdateTemplate() {
  const { i18n } = useTranslation();
  const qc = useQueryClient();

  return useMutation<SuccessRes, ErrorRes, ReqDataTemplate>(
    async data => {
      const res = await $api.put(settingsEndPoints.TEMPLATE_UPDATE, data);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async data => {
        message.success(data.message[i18n.language]);
        await qc.invalidateQueries([settingsQueryNames.TEMPLATES_VIEW]);
      }
    }
  );
}

export function useDeleteTemplate() {
  const { i18n } = useTranslation();
  const qc = useQueryClient();

  return useMutation<SuccessRes, ErrorRes, number>(
    async id => {
      const res = await $api.delete(`${settingsEndPoints.TEMPLATE_DELETE}?id=${id}`);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async data => {
        message.success(data.message[i18n.language]);
        await qc.invalidateQueries([settingsQueryNames.TEMPLATES_VIEW]);
      }
    }
  );
}

export function useCreateCompanyUnitCreate() {
  const { i18n } = useTranslation();
  const qc = useQueryClient();

  return useMutation<SuccessRes, ErrorRes, IUnitReqModel>(
    async data => {
      const res = await $api.post(settingsEndPoints.COMPANY_UNIT_CREATE, data);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async data => {
        await qc.invalidateQueries([queryKeys.UNIT_TREE]);
        message.success(data.message[i18n.language]);
      }
    }
  );
}

export function useProductOfferCreate(category_id?: number) {
  const { i18n } = useTranslation();
  const qc = useQueryClient();

  return useMutation<
    SuccessRes,
    ErrorRes,
    {
      offers: (Omit<OfferFormProductModel, "id" | "uniqueId" | "ordinalNumber"> & {
        product_id: number;
        unit_id: number;
      })[];
    }
  >(
    async data => {
      const res = await $api.post(settingsEndPoints.PRODUCT_OFFER_CREATE, data);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async data => {
        await qc.invalidateQueries([settingsQueryNames.CATEGORY_PRODUCTS_ALL_VIEW, category_id]);
        message.success(data.message[i18n.language]);
      }
    }
  );
}

export function useImportCatalogProducts(isNotSubCategory: boolean) {
  const { i18n } = useTranslation();
  const qc = useQueryClient();

  return useMutation<
    SuccessRes,
    ErrorRes,
    {
      category_id: number;
      subcategory_id?: number;
      products: Array<{
        unit_id: number;
        name: {
          uz: string;
          ru: string;
          en: string;
        };
        code?: number;
        resource_id: number;
      }>;
    }
  >(
    async data => {
      const res = await $api.post(settingsEndPoints.CATALOG_PRODUCT_CREATE, data);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async data => {
        if (isNotSubCategory) {
          await qc.invalidateQueries([settingsQueryNames.SUB_CATEGORY_PRODUCTS_VIEW]);
        } else {
          await qc.invalidateQueries([settingsQueryNames.CATEGORY_PRODUCTS_ALL_VIEW]);
        }
        message.success(data.message[i18n.language]);
      }
    }
  );
}

export function useCreateVat() {
  const qc = useQueryClient();

  return useMutation<SuccessRes, ErrorRes, Omit<VatModel, "id">>(
    async payload => {
      const res = await $api.post("vat/create", payload);

      return res.data;
    },
    {
      onSuccess: async res => {
        message.success(res.message?.[i18n.language]);
        await qc.invalidateQueries([settingsQueryNames.VAT_VIEW]);
      }
    }
  );
}

export function useDeleteVat() {
  const qc = useQueryClient();

  return useMutation<SuccessRes, ErrorRes, Pick<VatModel, "id">>(
    async ({ id }) => {
      const res = await $api.delete(`vat/delete?id=${id}`);

      return res.data;
    },
    {
      onSuccess: async res => {
        message.success(res.message?.[i18n.language]);
        await qc.invalidateQueries([settingsQueryNames.VAT_VIEW]);
      }
    }
  );
}

export function useUpdateUser(afterFunc?: () => void) {
  const qc = useQueryClient();

  return useMutation<SuccessRes, ErrorRes, { data: FormData }>(
    async ({ data }) => {
      const res = await $api.post(`user/update`, data);

      return res.data;
    },
    {
      onSuccess: async res => {
        afterFunc && afterFunc();
        message.success(res.message?.[i18n.language]);
        await qc.invalidateQueries([settingsQueryNames.CURRENT_USER_VIEW]);
        await qc.invalidateQueries([settingsQueryNames.ADMIN_VIEW]);
      }
    }
  );
}
